<div class="no-content" *ngIf="notifications.length==0">
  {{noNotificationFoundLabel}}
</div>

<div class="scroll-container">
  <div class="ui-g notification-card ui-g-nopad" *ngFor="let notification of notifications">
    <div class="ui-g-12 notification-time ">
      <div class="ui-g-6 date ui-g-nopad">
        {{notification.addedAt| dateformat}}
      </div>
      <div class="ui-g-6 ago ui-g-nopad">
        {{notification.addedAt| ago }}
      </div>

      <div class="ui-g-12 message">
        {{notification.msg}}
      </div>
    </div>
  </div>

  <div class="ui-g-12" [hidden]="showMoreOff || notifications.length === 0">
    <a class="load-more-btn" (click)="onLoadMore()">
      <i aria-hidden="true" class="fa fa-plus-circle"></i>
      <span class="pl-5" translate>label_show_more</span>
    </a>

  </div>

</div>