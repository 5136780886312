<!-- <p>twilio-video works!</p> -->
<div class="signin-box-container ui-g">
    <div class="signin-box-inner-container" style="background: #3a3a3a;color: #FFF;">
      <div class="signin-box ">
        <div class="sign-comatiner" style="width: 90%;">
            <!-- <p>twilio-video works!</p> -->
            <!-- <main>
              <div id="login">
                <h1>Join the chat</h1>
                <form id="login-form">
                  <div class="field">
                    <label for="identity">Your name</label>
                    <input type="text" name="identity" id="identity" />
                  </div>
                  <div class="field">
                    <button>Join video chat</button>
                  </div>
                </form>
              </div>
              <div id="chat" hidden>
                <p>Joining <span id="room"></span> as <span id="username"></span></p>
                <div id="participants"></div>
              </div>
            </main> -->
            <!-- <h1>Flask & Twilio Video Conference</h1> -->
        
        <!-- <p id="count">Disconnected.</p> -->
        <div id="root">
            <div id="container" class="container">
                <div style="color: #000;" id="local" class="participant"><div></div>
                  <div class="" style="background: '#ddd';color: '#000';padding: '2px';">Me</div></div>
                <!-- more participants will be added dynamically here -->
            </div>
            <div id="chat">
                <div id="chat-scroll">
                    <div id="chat-content">
                        <!-- chat content will be added dynamically here -->
                    </div>
                </div>
                <input id="chat-input" type="text">
            </div>
        </div>
        <div class="footer-div">
          <form>
            <!-- <label style="color: #FFF;" for="username">Name: </label>
            <input type="text" name=”username” id="username"> -->
            <!-- <button id="join_leave" (click)="connectButtonHandler($event);" class="{{connected ? 'leave-btn' : ''}}"
              style="padding: 7px;font-size: 14px;font-weight: bold;border-radius: 6px;">Join call</button> -->
            <!-- <button id="share_screen" disabled>Share screen</button>
            <button id="toggle_chat" disabled>Toggle chat</button> -->
            <!-- &nbsp;<button id="mute-unmute-audio-button" (click)="muteUnmuteAudio();"
              style="padding: 7px;font-size: 14px;font-weight: bold;border-radius: 6px;">Mute Audio</button>
            &nbsp;<button id="mute-unmute-video-button" (click)="muteUnmuteVideo();"
              style="padding: 7px;font-size: 14px;font-weight: bold;border-radius: 6px;">Off Video</button> -->
          
          <span class="footer-btn" *ngIf="connected" (click)="connectButtonHandler($event);">
            <img src="/assets/images/temp/end-call.png"> 
          </span>
          <span class="footer-btn" (click)="muteUnmuteAudio();">
            <img *ngIf="isAudioMuted" src="/assets/images/temp/microphone.png"> 
            <img *ngIf="!isAudioMuted" src="/assets/images/temp/microphone_mute.png"> 
          </span>
          <span class="footer-btn" (click)="muteUnmuteVideo();">
            <img *ngIf="isVideoMuted" src="/assets/images/temp/video.png"> 
            <img *ngIf="!isVideoMuted" src="/assets/images/temp/video off.png"> 
          </span>
          <span class="footer-btn">
            <a [routerLink]="['/appointments/book-appointment']" target="_blank"><button pButton type="button" [label]="'label_book_appointment' | translate" style="width: 170px;"></button></a>
          </span>
          </form>
        </div>
        </div>
    </div>
  </div>
</div>