
import { TaxiBookingModel } from './../../../bookings/models/taxi-booking-model';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AppSettings } from './../../../shared/app.settings';
import { ErrorBindingService } from '../../../shared/services/error-binding.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-book-rental',
  templateUrl: './book-rental.component.html'
})

export class BookRentalComponent implements OnInit {
  @Input() type;
  labelSave;
  labelCancel;
  label_pickup;
  label_drop;
  label_search;
  minDate: any;
  dateFormat;
  passengerSearchList: any[];
  passengerSearchTextResult: any[];
  passengerSelectedItem: any;
  passengerSearchText = '';
  cmmOptions = [];
  serviceTypeId = '';
  vehicleTypeId = [];
  packageOptions = [];
  serviceTypeOptions = [];
  carTypeOptions: any[] = [];
  hotelList = [];
  pickupBit = false;
  isCorporatePassenger = false;
  selectedPassengerCorporate;
  srcLat = '';
  srcLng = '';
  destiLat = '';
  destiLng = '';
  latitude = '';
  longitude = '';
  passengerId = '';
  dropLocation = '';
  carTypeValue = '';
  pickupLocation = '';
  urlPassengers = '/secure/passengers/search/list';

  rentalApiError = [{
    'general': false
  }];

  taxiBookingApiError = [{
    general: false,
  }];

  packageApiError = [{
    driverId: false,
    serviceTypeId: false,
    partnerId: false,
    partnerType: false,
    phoneNum: false,
    passengerName: false,
    vehicleTypeId: false,
    vehicleId: false,
    packageId: false,
    bookingStatus: false,
    sourceLattitude: false,
    sourceLongitude: false,
    destinationLatitude: false,
    destinationLongitude: false,
    pickupLocationName: false,
    dropLocationName: false,
    pickupDateTime: false,
    passangerCount: false,
    vip: false,
    vehicleAutomatic: false,
    withFuel: false,
    note: false,
    general: false,
    roomNum: false,
    quotationAmount: false,
    hotelNames: false
  }];

  bookingRentalForm = new FormGroup({
    pickupLocation: new FormControl(),
    dropLocation: new FormControl(),
    pickupDate: new FormControl(),
    package: new FormControl(),
    carType: new FormControl(),
    passengerName: new FormControl(),
    phoneCode: new FormControl(),
    mobileNo: new FormControl(),
    note: new FormControl(),
    VIPPassenger: new FormControl(),
    serviceType: new FormControl(),
    email: new FormControl(),
  });

  constructor(
    private formBuilder: FormBuilder,
    private errorBindingService: ErrorBindingService,
    private commonBindingDataService: CommonBindingDataService,
    private uiService: UiService,
  ) { }

  ngOnInit() {
    this.dateFormat = this.commonBindingDataService.getDateFormat();
    this.labelAssign();
    this.serviceType();
    this.validation();
    if (this.type === 'hotel-bookings') {
      this.bookingRentalForm.addControl('roomNum',
        new FormControl('', [Validators.required,
        Validators.maxLength(9)])
      );
      this.bookingRentalForm.addControl('quotationAmount',
        new FormControl('', [Validators.required, Validators.pattern(AppSettings.AMOUNT_PATTERN)])
      );
      this.bookingRentalForm.addControl('hotelNames',
        new FormControl('', [Validators.required])
      );
      this.getHotels();
    }
    this.getVehicleTypeAndBind();
  }

  getHotels() {
    this.hotelList = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '0' }];
    this.commonBindingDataService.getHotels().subscribe(results => {
      results.forEach(element => {
        this.hotelList.push({ label: element.hotelName, value: element.hotelId });
      });
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.packageApiError);
    });
  }

  serviceType() {
    const serviceType = this.commonBindingDataService.getServiceTypeByCode(AppSettings.SERVICE_CODE_RENTAL);
    this.serviceTypeOptions.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '' });
    for (const type of serviceType) {
      this.serviceTypeOptions.push({
        label: type.serviceType,
        value: type.id
      });
    }
  }

  labelAssign() {
    this.minDate = this.commonBindingDataService.getBookLaterMinTime(
      AppSettings.SERVICE_CODE_RENTAL,
      AppSettings.RENTAL_BOOK_MIN_TIME);
    this.label_search = this.commonBindingDataService.getLabel('label_search');
    this.label_pickup = this.commonBindingDataService.getLabel('label_pickup_location');
    this.label_drop = this.commonBindingDataService.getLabel('label_drop_location');
    this.labelSave = this.commonBindingDataService.getLabel('label_save');
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
  }

  validation() {
    this.bookingRentalForm = this.formBuilder.group({
      pickupLocation: ['', [Validators.required]],
      dropLocation: ['', [Validators.required]],
      pickupDate: ['', [Validators.required]],
      package: [{ value: '', disabled: true }, [Validators.required]],
      carType: ['', [Validators.required]],
      passengerName: ['', [
        Validators.required,
        Validators.pattern(AppSettings.PERSON_NAME_PATTERN),
        Validators.minLength(3)
      ]],
      phoneCode: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern(AppSettings.PHONE_CODE)
      ]],
      mobileNo: ['', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(20)
      ]],
      note: [''],
      VIPPassenger: [],
      serviceType: [],
      email: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]]
    });
  }

  getVehicleTypeAndBind() {
    this.carTypeOptions = [];
    this.carTypeOptions.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '' });
    this.commonBindingDataService.getVehicleType().subscribe(results => {
      const vehicleTypeData = results.vehicleTypeList;
      vehicleTypeData.forEach(element => {
        this.carTypeOptions.push({ label: element.vehicleType, value: element.id });
      });
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.packageApiError);
    });
  }

  changeServiceType(event) {
    if (event.value !== '') {
      this.serviceTypeId = event.value;
    }
    this.checkForPackages();
  }

  changePackage(event) {
    this.vehicleTypeId = [];
    this.carTypeValue = event.value;
    this.vehicleTypeId.push(event.value);
    this.checkForPackages();
  }

  allPackage() {
    if (this.checkCarTypeNotNull()) {
      this.packageOptions = [];
      this.commonBindingDataService.packageBind(this.serviceTypeId, this.passengerId, this.carTypeValue).subscribe(results => {
        this.bookingRentalForm.controls.package.enable();
        this.packageOptions.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '' });
        for (let i = 0; i < results.length; i++) {
          this.packageOptions.push({ label: this.formatPackageName(results[i].distance, results[i].minutes), value: results[i].id });
        }
        this.bookingRentalForm.controls.package.setValue('');
      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.packageApiError);
      });
    }
  }

  formatPackageName(distance, time) {
    return (distance / 1000) + ' km - ' + this.convertMinsToHrs(time);
  }

  convertMinsToHrs(time) {
    return (Math.floor(time / 60)) + ' hrs ' + (time % 60) + ' mins';
  }

  checkCarTypeNotNull() {
    return (this.carTypeValue !== undefined && this.carTypeValue !== null && this.carTypeValue !== '');
  }

  checkForPackages() {
    if (this.bookingRentalForm.controls.serviceType.value &&
      this.bookingRentalForm.controls.carType.value &&
      this.passengerId) {
      this.allPackage();
    } else {
      if (!this.bookingRentalForm.controls.package.disabled) {
        this.bookingRentalForm.controls.package.disable();
      }
    }
  }

  addbooking() {
    let mobileNum = '';
    if (this.isMobileNumberAssigned()) {
      mobileNum = this.bookingRentalForm.controls.mobileNo.value.phoneNum;
    } else {
      mobileNum = this.bookingRentalForm.controls.mobileNo.value;
    }

    const data = new TaxiBookingModel({
      paymentMode: null,
      tenantId: AppSettings.TENANT,
      bookingStatus: null,
      bookLater: true,
      passengerId: this.passengerId,
      destinationLatitude: this.destiLat,
      destinationLongitude: this.destiLng,
      dropLocationName: this.dropLocation,
      note: this.bookingRentalForm.controls.note.value,
      packageId: this.bookingRentalForm.controls.package.value,
      partnerId: null,
      partnerType: '',
      passengerName: this.bookingRentalForm.controls.passengerName.value,
      numCountryCode: this.bookingRentalForm.controls.phoneCode.value,
      phoneNum: mobileNum,
      pickupDateTime: new Date(this.bookingRentalForm.controls.pickupDate.value).getTime(),
      pickupLocationName: this.pickupLocation,
      serviceTypeId: this.serviceTypeId,
      sourceLatitude: this.srcLat,
      sourceLongitude: this.srcLng,
      vehicleAutomatic: false,
      vehicleId: null,
      vehicleTypeId: this.bookingRentalForm.controls.carType.value,
      vip: this.bookingRentalForm.controls.VIPPassenger.value,
      withFuel: false,
      bookingDropPoints: [],
      email: this.bookingRentalForm.controls.email.value,
      corporateBooking: this.isCorporatePassenger,
      quoteAmount: this.bookingRentalForm.controls.quotationAmount.value,
      roomNum: this.bookingRentalForm.controls.roomNum.value,
    });

    if (this.minDate > new Date(this.bookingRentalForm.controls.pickupDate.value).getTime()) {
      const error = {
        general: [
          {
            message: this.commonBindingDataService.getLabel('error_invalid_date')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.packageApiError);
    } else {
      const hotelId = this.bookingRentalForm.controls.hotelNames.value;
      this.commonBindingDataService.addHotelBooking(JSON.stringify(data), this.serviceTypeId, hotelId).subscribe(results => {
        this.successSave(results);
      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.packageApiError);
      });
    }
  }

  successSave(results) {
    this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
    this.bookingRentalForm.reset();
  }

  cancel() {
    this.bookingRentalForm.reset();
  }

  isPickUpLocationMissing() {
    if ((this.srcLat && this.srcLng) === '') {
      return true;
    } else {
      return false;
    }
  }

  onPickuplocationMissing() {
    const error = {
      pickupLocationName: [
        {
          message: this.commonBindingDataService.getLabel('pickup_location_validation_msg')
        }
      ]
    };
    this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.packageApiError);
  }

  setPickup(place) {
    this.srcLat = place.lat;
    this.srcLng = place.lng;
    this.latitude = place.lat;
    this.longitude = place.lng;
    this.pickupLocation = place.address;
  }

  setDropUp(place) {
    this.destiLat = place.lat;
    this.destiLng = place.lng;
    this.dropLocation = place.address;
  }

  passengerSearch(event) {
    const searchText = event.query;
    this.commonBindingDataService.searchPassengers(this.getPassengersSearchUrl(searchText))
      .subscribe(data => {
        if (data != null) {
          this.passengerSearchList = data;
          const c = this;
          this.passengerSearchTextResult = [];
          this.passengerSearchList.forEach(e => {
            e.phoneNum = parseInt(e.phoneNum, 10);
            c.passengerSearchTextResult.push({
              phoneNum: parseInt(e.phoneNum, 10),
              email: e.email,
              numCountryCode: e.numCountryCode,
              name: e.firstName + ' ' + this.checkNull(e.lastName),
              corporateId: e.corporateId,
              corporateName: e.corporateName,
              id: e.id
            });
          });
        }
      });
  }

  checkNull(obj) {
    if (obj !== null) {
      return obj;
    } else {
      return '';
    }
  }

  getPassengersSearchUrl(searchText) {
    let url;
    if (this.urlPassengers !== null && this.urlPassengers !== '') {
      url = `${this.urlPassengers}?searchText=${searchText}`;
    }
    return url;
  }

  onSelectMobileNumber(event) {
    this.bookingRentalForm.controls.passengerName.setValue(event.name);
    this.bookingRentalForm.controls.email.setValue(event.email);
    this.bookingRentalForm.controls.phoneCode.setValue(event.numCountryCode);
    this.selectedPassengerCorporate = event.corporateName;
    if (event.corporateId !== null) {
      this.isCorporatePassenger = true;
      this.passengerId = event.id;
    } else {
      this.isCorporatePassenger = false;
      this.passengerId = event.id;
    }
    this.checkForPackages();
  }

  isMobileNumberAssigned() {
    if (typeof (this.bookingRentalForm.controls.mobileNo.value) === 'object') {
      return true;
    } else {
      return false;
    }
  }

}
