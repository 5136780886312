<div class="form-preview-container" *ngIf="!submitted && formCategories">

  <div class="ui-g-12 ui-md-12 ui-lg-12 preview-heading-card" style="background-color: #FFF; padding: 20px">
    <div class="ui-g-5 ui-g-nopad header-logo text-left">
      <img class="company-logo-background" src='/assets/images/temp/rainbowcare_logo.png' width="50%">
    </div>
    <div class="ui-g-7 ui-g-nopad pt-20 header-address text-right">
      <span *ngIf="clientInfo?.tenantLocationName" class="client-address">{{ clientInfo?.tenantLocationName }}</span><br>
      <span *ngIf="clientInfo?.phoneNumber">Phone: {{ clientInfo?.phoneNumber }}</span><br>
      <span *ngIf="clientInfo?.faxNumber">Fax: {{ clientInfo?.faxNumber }}</span><br>
      <span *ngIf="clientInfo?.email">{{ clientInfo?.email }}</span>
    </div>
  </div>
  <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad" *ngFor="let sectionObj of formCategories;let sectionIndex = index">
    <p-panel hidden="true">
      <p-header class="panel-header">
        <p>{{sectionObj.categoryName}}</p>
      </p-header>

      <!-- multiple choice format with other option -->
      <!-- <div class="ui-g">
                  <div class="form-field ui-g-12 ui-md-12 ui-lg-12 pl-0">
                      <div class="form-label">
                          <label class="question-txt">Q1. What is your gender?</label>
                      </div>
                  </div>
                  <div class="form-field ui-g-12 ui-md-6 ui-lg-6 pl-0">       
                      <div class="form-control-c">
                          <div class="p-grid">
                              <div class="p-col-12 radio-label"><p-radioButton name="gender" label="MALE"></p-radioButton></div>
                              <div class="p-col-12 radio-label"><p-radioButton name="gender" label="FEMALE"></p-radioButton></div>
                              <div class="p-col-12 radio-label"><p-radioButton name="gender" label="TRANSGENDER"></p-radioButton></div>
                              <div class="p-col-12 radio-label"><p-radioButton class="padding-0" name="gender"></p-radioButton>
                                  <label class="margin-top-0"><input  type="text" class="forminput"  pInputText /></label>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>     -->
      <!-- multiple choice format with other option -->
    </p-panel>

    <div class="preview-heading-card ui-g-nopad text-center">
      <!-- <span class="section-title">{{sectionObj.categoryName}}</span> -->
      <div class="ui-g-12 ui-sm-12 ui-g-nopad">
        <span class="ui-g-12 section-header">
          {{sectionObj.categoryName}}
        </span>
      </div>
    </div>
    <div class="" *ngFor="let questionObj of sectionObj.formCategorySubcategories;let questionIndex = index">
      <div class="ui-g preview-heading-card"
        *ngFor="let subQuestionObj of questionObj.subCategoryQuestions;let subQuestionIndex = index">
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12 pl-0" *ngIf="subQuestionObj.questionType !== '1009'">
            <div class="form-label">
              <label class="question-txt">{{ subQuestionIndex + 1}}. {{ subQuestionObj.question }}</label>
            </div>
          </div>

        <!-- multiple choice format -->
        <div [ngClass]="subQuestionObj.questionType == '1001' ? 'form-field ui-g-12 ui-md-12 ui-lg-12 pl-0 pb-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1001'">
            <div class="p-grid">
              <div class="p-col-12 radio-label margin-label"
                *ngFor="let QuestionOptions of subQuestionObj.subCategoryQuestionOptions;let QuestionOptionsIndex = index">

                <div class="ui-g-12 ui-g-nopad" *ngIf="!QuestionOptions.notesOption">
                  <p-radioButton class="padding-0" name="mcq{{subQuestionObj.id}}" [value]="true"
                    [label]="QuestionOptions.otherOptionAdded !== true ? QuestionOptions.option : ''"
                    [(ngModel)]="QuestionOptions.selectedOption" [ngModelOptions]="{standalone: true}" binary="true"
                    (onClick)="radioChange(QuestionOptions,subQuestionObj)" [disabled]="viewFormFlag">
                  </p-radioButton>
                  <div class="form-field ui-g-12 ui-md-12 ui-lg-12 pl-0 otherinputAlign"
                    *ngIf="QuestionOptions.otherOptionAdded == true">
                    <input autocomplete="off" type="text" class="otherinput ml-6" [(ngModel)]="QuestionOptions.option"
                      [disabled]="viewFormFlag" pInputText />
                  </div>
                </div>
                <div class="ui-g-12 ui-g-nopad" *ngIf="QuestionOptions.notesOption">
                  <div class="form-field ui-g-12 ui-md-12 ui-lg-12 pl-0 otherinputAlign" *ngIf="QuestionOptions.notesOption == true">
                      <input autocomplete="off" type="text" class="otherinput" [(ngModel)]="QuestionOptions.option" placeholder="{{viewFormFlag ? '' : QuestionOptions.option}}"
                        [disabled]="viewFormFlag" [ngClass]="{'disabled-bg': viewFormFlag == true}" pInputText  />
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- multiple choice format -->

        <!-- multiple choice grid format -->
        <div [ngClass]="subQuestionObj.questionType == '1002' ? 'form-field ui-g-12 ui-md-12 ui-lg-12 pl-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1002'">
            <table class="grid-table">
              <tr>
                <th></th>
                <th
                  *ngFor="let columnQuestionOptions of subQuestionObj.subCategoryQuestionOptions;let columnQuestionOptionsIndex = index">
                  {{ columnQuestionOptions.option }}</th>

              </tr>

              <tr
                *ngFor="let rowQuestionOptions of subQuestionObj.associateQuestions;let rowQuestionOptionsIndex = index">
                <td class="row-name">{{ rowQuestionOptions.question }}</td>
                <td
                    *ngFor="let columnRadioQuestionOptions of rowQuestionOptions.associateQuestionOption;let columnRadioQuestionOptionsIndex = index">
                    <p-radioButton name="category{{ sectionIndex + 1}}{{ subQuestionIndex + 1}}{{rowQuestionOptionsIndex}}"
                    [value]="true" [(ngModel)]="columnRadioQuestionOptions.selectedOption" (onClick)="radioChangeMCG(columnRadioQuestionOptions,subQuestionObj,rowQuestionOptions)" [ngModelOptions]="{standalone: true}" binary="true" [disabled]="viewFormFlag">
                    </p-radioButton>
                </td>
              </tr>

            </table>

          </div>

        </div>
        <!-- multiple choice grid format -->

        <!-- short answer format -->
        <div [ngClass]="subQuestionObj.questionType == '1003' ? 'form-field ui-g-12 ui-md-4 ui-lg-4 pl-0 pb-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1003'">
            <div class="form-label" style="text-transform: none;">
              <!-- <label for="username" translate>{{subQuestionObj.question}}</label> -->
              <!-- <label class="asterisk">*</label> -->
            </div>
            <div class="form-control-c"
              *ngFor="let QuestionOptions of subQuestionObj?.subCategoryQuestionOptions;let QuestionOptionsIndex = index">
              <input autocomplete="off" type="text" class="forminput" [(ngModel)]="QuestionOptions.option"
                [ngModelOptions]="{standalone: true}" binary="true" pInputText [disabled]="viewFormFlag"
                placeholder="Enter {{subQuestionObj.question}}" />
            </div>
          </div>
        </div>
        <!-- short answer format -->

        <!-- paragraph format -->
        <div [ngClass]="subQuestionObj.questionType == '1004' ? 'form-field ui-g-12 ui-md-6 ui-lg-6 pl-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1004'">
            <div class="form-label" style="text-transform: none;">
              <!-- <label for="username" translate>{{subQuestionObj.question}}</label> -->
              <!-- <label class="asterisk">*</label> -->
            </div>
            <div class="form-control-c"
              *ngFor="let QuestionOptions of subQuestionObj?.subCategoryQuestionOptions;let QuestionOptionsIndex = index">
                <textarea class="no-resize forminput" [ngClass]="{'disabled-bg': viewFormFlag == true}"
                    [(ngModel)]="QuestionOptions.option" [ngModelOptions]="{standalone: true}" binary="true"
                    autocomplete="off" [rows]="5" [cols]="25" pInputTextarea
                    [disabled]="viewFormFlag" placeholder="Enter {{subQuestionObj.question}}">
                </textarea>
            </div>
          </div>
        </div>
        <!-- paragraph format -->

        <!-- Linear Scale Format -->
        <div [ngClass]="subQuestionObj.questionType == '1005' ? 'form-field ui-g-12 ui-md-8 ui-lg-8 pl-0' : '' ">
          <div *ngIf="subQuestionObj.questionType == '1005'">
            <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
              <div class="form-field ui-g-12 ui-md-1 ui-lg-1 linear-center">
                <p>{{ subQuestionObj.subCategoryQuestionOptions[0].option }}</p>
                <!-- <p>{{ subQuestionObj.subCategoryQuestionOptions[0].title }}</p> -->
              </div>
              <div class="form-control-c ui-g-12 ui-md-10 ui-lg-10 mt-8">
                <p class="linear-center"><b>Value: {{subQuestionObj.linearScaleAnswer}}</b></p>
                <input autocomplete="off" type="range" value="0" [(ngModel)]="subQuestionObj.linearScaleAnswer"
                  [min]="subQuestionObj.subCategoryQuestionOptions[0].option"
                  [max]="subQuestionObj.subCategoryQuestionOptions[1].option"
                  id="Range{{subQuestionIndex}}" [disabled]="viewFormFlag">
              </div>
              <div class="form-field ui-g-12 ui-md-1 ui-lg-1 linear-center">
                <p>{{ subQuestionObj.subCategoryQuestionOptions[1].option }}</p>
                <!-- <p>{{ subQuestionObj.subCategoryQuestionOptions[1].title }}</p> -->
              </div>
            </div>
            <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
              <div class="form-field ui-g-6 ui-md-6 ui-lg-6 ui-g-nopad">
                <p>{{ subQuestionObj.subCategoryQuestionOptions[0].title }}</p>
              </div>
              <!-- <div class="form-field ui-g-12 ui-md-2 ui-lg-2">
              </div> -->
              <div class="form-field ui-g-6 ui-md-6 ui-lg-6 ui-g-nopad txt-align-right">
                <p>{{ subQuestionObj.subCategoryQuestionOptions[1].title }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Linear Scale Format -->

        <!-- Date format -->
        <div [ngClass]="subQuestionObj.questionType == '1006' ? 'form-field ui-g-12 ui-md-4 ui-lg-4 pl-0 pb-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1006'">
            <div class="form-control-c"
              *ngFor="let QuestionOptions of subQuestionObj?.subCategoryQuestionOptions;let QuestionOptionsIndex = index">
              <p-calendar [showIcon]="true" [(ngModel)]="QuestionOptions.option" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="cDateRange"
                [placeholder]="'label_select_date' | translate" [defaultDate]="today" [inline]="false"
                [style]="{'width':'100%'}" class="cus-date" [disabled]="true">
              </p-calendar>
            </div>
          </div>
        </div>
        <!-- Date format -->

        <!-- Switcher Format -->
        <div [ngClass]="subQuestionObj.questionType == '1007' ? 'form-field ui-g-12 ui-md-6 ui-lg-6 pl-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1007'">
            <span>{{ subQuestionObj.subCategoryQuestionOptions[0].option }}</span>
            <p-inputSwitch class="switch-space"
              [(ngModel)]="subQuestionObj.subCategoryQuestionOptions[1].selectedOption"
              [ngModelOptions]="{standalone: true}" binary="true" [disabled]="viewFormFlag">
            </p-inputSwitch>
            <span>{{ subQuestionObj.subCategoryQuestionOptions[1].option }}</span>
          </div>
        </div>
        <!-- Switcher Format -->

        <!-- Checkbox format -->
        <div [ngClass]="subQuestionObj.questionType == '1008' ? 'form-field ui-g-12 ui-md-12 ui-lg-12 pl-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1008'">
            <div class="p-grid">
              <div class="p-col-12 radio-label"
                *ngFor="let checkboxQuestionOptions of subQuestionObj.subCategoryQuestionOptions;let checkboxQuestionOptionsIndex = index">
                <div class="ui-g-12 ui-g-nopad" *ngIf="!checkboxQuestionOptions.notesOption">
                  <p-checkbox class="padding-0" [(ngModel)]="checkboxQuestionOptions.selectedOption"
                    [ngModelOptions]="{standalone: true}" binary="true"
                    name="checkboxgroup{{subQuestionIndex}}" [label]="checkboxQuestionOptions.option"
                    [disabled]="viewFormFlag"></p-checkbox>
                </div>
                <div class="ui-g-12 ui-g-nopad" *ngIf="checkboxQuestionOptions.notesOption">
                  <div class="form-field ui-g-12 ui-md-12 ui-lg-12 pl-0 otherinputAlign" *ngIf="checkboxQuestionOptions.notesOption == true">
                      <input autocomplete="off" type="text" class="otherinput" [(ngModel)]="checkboxQuestionOptions.option" placeholder="{{viewFormFlag ? '' : checkboxQuestionOptions.option}}"
                        [disabled]="viewFormFlag" [ngClass]="{'disabled-bg': viewFormFlag == true}" pInputText  />
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Checkbox format -->

        <!-- HTML format -->
        <div [ngClass]="subQuestionObj.questionType == '1009' ? 'form-field ui-g-12 ui-md-12 ui-lg-12 pl-0 pb-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1009'"
            [innerHtml]="subQuestionObj?.subCategoryQuestionOptions[0]?.option">
            <!-- {{ subQuestionObj.subCategoryQuestionOptions[0].option }} -->
          </div>
        </div>
        <!-- HTML format -->

      </div>
    </div>
  </div>

  <div class="ui-g-12 ui-g-nopad preview-heading-card">
    <div class="ui-g-6">

    </div>
    <div class="form-buttons ui-g-6">
      <button pButton class="save-btn" type="button" [label]="'label_submit' | translate" [disabled]="displayBtn"
        (click)="saveForm()"></button>
      <!-- <button class="cancel-btn" pButton type="button" [label]="'label_cancel' | translate" (click)="goBack()"></button> -->
    </div>
  </div>

  <div class="ui-g-12 ui-md-12 ui-lg-12 preview-heading-card" style="background-color: #FFF; padding: 20px">
    <div class="ui-g-4 ui-g-nopad footer-logo text-center">
      <img class="company-logo-background" src='/assets/images/temp/rainbowcare_logo.png' width="40%">
    </div>
    <div class="ui-g-8 ui-g-nopad pt-20 header-address text-center">
      <span translate>label_thank_you_msg</span><br>
      <span *ngIf="clientInfo?.organisationName">{{ clientInfo?.organisationName }} Program</span>
    </div>
  </div>
</div>

<div class="form-preview-container" *ngIf="submitted">
  <div class="ui-g-12 ui-md-12 ui-lg-12" style="margin-top: 8%;">
    <div class="card">
      <div class="card-container">
        <div class="submitted-lable">
          <h3>{{submittedMsg}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
