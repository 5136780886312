import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.scss']
})
export class ConsentFormComponent implements OnInit {
  formCategories;
  token: any;
  formDetails: any;
  displayBtn = true;
  today;
  submitted = false;
  alreadtSubmitted = false;
  alreadtSubmittedMsg: any;
  submittedMsg: any;
  downloadFileId: any;
  cDateRange: string;

  constructor(private commonServices: UserCommonService,
    private uiService: UiService,
    private route: ActivatedRoute,
    private restApiService: RestApiService,
    private commonBindingDataService: CommonBindingDataService
  ) { }

  ngOnInit(): void {
    this.getMetadata();
    this.cDateRange = this.commonBindingDataService.getYearRangeForCalendar();
    this.today = new Date();
    this.token = this.route.snapshot.queryParams['t'];
    this.commonServices.getConsentFormData(this.token).subscribe(results => {
      this.formDetails = results;
      this.formCategories = results.formCategories;
      results.formCategories.forEach(cat => {
        cat.formCategorySubcategories.forEach(subCat => {
          subCat.subCategoryQuestions.forEach(que => {
            que.subCategoryQuestionOptions.forEach(opt => {
              if (que.questionType === '1006') {
                opt.option = this.today;
              }
            });
          });
        });
      });
    }, (error) => {
      // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
      this.alreadtSubmittedMsg = error.general[0].message;
      this.alreadtSubmitted = true;
    });
  }

  getMetadata() {
    this.commonServices.getPublicMetadata('online_referral').subscribe(results => {
      // this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      this.downloadFileId = results?.ptConsentFormFileId;
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });

  }

  radioChange(object, subQuestionObj) {
    this.displayBtn = false;
    if (subQuestionObj.questionType === '1001') {
      for (let l = 0; l < subQuestionObj.subCategoryQuestionOptions.length; l++) {
        const subCategoryOptions1 = subQuestionObj.subCategoryQuestionOptions;
        if (subCategoryOptions1[l].id === object.id) {
          if (subCategoryOptions1[l].subCategoryQuestionId === object.subCategoryQuestionId) {
            subCategoryOptions1[l].selectedOption = true;
          }
        } else {
          subCategoryOptions1[l].selectedOption = false;
        }
      }
    }
  }

  saveForm() {
    this.displayBtn = true;
    const dataModel = this.getDataModel();
    setTimeout(() => {
      this.commonServices.submitConsentForms(dataModel).subscribe(results => {
        // this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
        this.displayBtn = false;
        this.submitted = true;
        this.submittedMsg = results.general[0].message;
      }, (error) => {
        this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
        this.displayBtn = false;
      });
    }, 800);
  }

  getDataModel() {
    this.setAnswerArray();

    const FormModel: any = {
      'token': this.token,
      'id': this.formDetails.id,
      'createdAt': this.formDetails.createdAt,
      'updatedAt': this.formDetails.updatedAt,
      'tenantId': this.formDetails.tenantId,
      'formId': this.formDetails.formId,
      'formName': this.formDetails.formName,
      'formAlias': this.formDetails.formAlias,
      'formType': this.formDetails.formType,
      'instruction': this.formDetails.instruction,
      'description': this.formDetails.description,
      'programType': this.formDetails.programType,
      'patientCondition': this.formDetails.patientCondition,
      'otherDescription': this.formDetails.otherDescription,
      'programTypeId': this.formDetails.programTypeId,
      'patientConditionId': this.formDetails.patientConditionId,
      'assigneeRoleId': this.formDetails.assigneeRoleId,
      'activated': this.formDetails.activated,
      'formTypeId': this.formDetails.formTypeId,
      'applicableEventTypes': this.formDetails.applicableEventTypes,
      'formCategories': this.formCategories,
      'subCategoryQuestions': this.formDetails.subCategoryQuestions,
      'categories': this.formDetails.categories,
      'formPermissions': this.formDetails.formPermissions,
    };
    return FormModel;
  }

  setAnswerArray() {

    for (let i = 0; i < this.formCategories.length; i++) {

      for (let j = 0; j < this.formCategories[i].formCategorySubcategories.length; j++) {

        for (let k = 0; k < this.formCategories[i].formCategorySubcategories[j].subCategoryQuestions.length; k++) {
          const subCategory = this.formCategories[i].formCategorySubcategories[j].subCategoryQuestions;
          for (let l = 0; l < subCategory[k].subCategoryQuestionOptions.length; l++) {
            if (subCategory[k].questionType === '1001') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].selectedOption === true) {
                subCategory[k].answered = true;
              }
              if (subOptions[l].selectedOption === false && subOptions[l].otherOptionAdded === true) {
                subOptions[l].option = 'other';
              }
            }
            if (subCategory[k].questionType === '1002') {
              for (let l = 0; l < subCategory[k].associateQuestions.length; l++) {
                const subOptions = subCategory[k].associateQuestions;
                for (let m = 0; m < subOptions[l].associateQuestionOption.length; m++) {
                  const associateQuestionOptions = subOptions[l].associateQuestionOption;
                  if (associateQuestionOptions[m].selectedOption === true) {
                    subCategory[k].answered = true;
                  }
                }
              }
            }
            if (subCategory[k].questionType === '1003') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].option !== '') {
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1004') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].option !== '') {
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1005') {
              if (subCategory[k].linearScaleAnswer !== '0') {
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1006') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].option !== '') {
                subOptions[l].option = moment(subOptions[l].option).valueOf();
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1007') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].selectedOption === true) {
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1008') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].selectedOption === true) {
                subCategory[k].answered = true;
              }
            }
          }

        }
      }
    }
  }

  downloadConsentTemplate() {
    let url = '';
    url = `/file/${this.downloadFileId}`;
    return this.restApiService.pdf('', url, 'Consent Form', 'page-center');
  }

}
