import { StorageService } from './../../shared/services/storage.service';
import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { AppSettings } from '../../shared/app.settings';
import { Observable } from 'rxjs';
import { CommonBindingDataService } from '../../shared/services/common-binding-data.service';

@Injectable()
export class BookingService {
  bookingDetail: any = null;
  obj: any;
  selectedService: any = '';
  statusOptions: any[] = [];
  selectedStatus: any = '';
  params: any = '';
  createdFrom: any = '';
  createdTo: any = '';
  packageApiError = [{
    driverId: false,
    serviceTypeId: false,
    partnerId: false,
    partnerType: false,
    phoneNum: false,
    passengerName: false,
    vehicleTypeId: false,
    vehicleId: false,
    packageId: false,
    bookingStatus: false,
    sourceLattitude: false,
    sourceLongitude: false,
    destinationLatitude: false,
    destinationLongitude: false,
    pickupLocationName: false,
    dropLocationName: false,
    pickupDateTime: false,
    passangerCount: false,
    vip: false,
    vehicleAutomatic: false,
    withFuel: false,
    note: false,
    general: false,
    makeModel: false,
  }];

  constructor(
    private restApiService: RestApiService,
    private commonService: CommonBindingDataService,
    private storageService: StorageService
  ) { }

  addUpdateBooking(data, serviceType, id?): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}`, data);
  }

  addChauffeurBooking(data, serviceType): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}/chauffeur`, data);
  }


  addUpdateRentalBooking(data, serviceType): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}/rental`, data);
  }

  packageBind(serviceType, passengerId?, vehicleTypeId?): Observable<any> {
    if (vehicleTypeId !== null && vehicleTypeId !== undefined) {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType +
        '?vehicleTypeId=' + vehicleTypeId + '&passengerId' + passengerId;
      return this.restApiService.get('', url, 'page-center');
    } else {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType;
      return this.restApiService.get('', url, 'page-center');
    }
  }

  searchDriver(url: string): Observable<any> {
    return this.restApiService.get('', url, 'page-center');
  }

  searchPassengers(url: string): Observable<any> {
    return this.restApiService.get('', url, 'page-center');
  }

  assigningDriver(bookingId, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + bookingId + '/assign-driver', data);
  }

  assignDriver(bookingId, driverId, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/assign-driver/' + bookingId + '/' + driverId, data);
  }

  reassignDriver(tourId, driverId, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/re-assign-driver/' + tourId + '/' + driverId, data);
  }

  cancelBooking(id, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + id + '/cancel', data);
  }

  bookingDetails(id): Observable<any> {
    return this.restApiService.get('', '/secure/booking/' + id, 'page-center');
  }

  fetchTourBooking(tourId): Observable<any> {
    return this.restApiService.get('', '/secure/tour/' + tourId, 'page-center');
  }

  startTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/start-trip', data);
  }

  endTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/end-trip', data);
  }

  cancelTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/cancel-trip', data);
  }

  resendCode(bookingId, passengerId): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + bookingId + '/verification-code');
  }

  getCities(): Observable<any> {
    return this.restApiService.get('', '/secure/service-area/cities', 'page-center');
  }

  getRegionByCityId(cityId): Observable<any> {
    return this.restApiService.get('', `/secure/service-area/regions/${cityId}`, 'page-center');
  }

  sendEmailInvoice(tourId, data): Observable<any> {
    return this.restApiService.post('', `/secure/tour-receipt/email/` + tourId, data, 'page-center');
  }

  refundBookingAmount(tourId, data): Observable<any> {
    return this.restApiService.post('', `/secure/tour/refund/` + tourId, data, 'page-center');
  }

  getPassengersList(searchString?): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/search/list?searchText=' + searchString, 'page-center');
  }

  getPassengersListWithMid(searchString?, id?): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/search/list?searchText=' + searchString + '&mId=' + id, 'page-center');
  }

  getProviderList(searchString?): Observable<any> {
    return this.restApiService.get('', '/secure/provider/list/data?searchText=' + searchString, 'page-center');
  }

  // code for demo start
  getproviderForDemoList(searchString?): Observable<any> {
    return this.restApiService.get('', '/secure/passengers/search/list?searchText=' + searchString, 'page-center');
  }
  // code for demo end

  getDriversList(srcLat, srcLng, destLat, DestLng, numOfSeats, rideTypeId, serviceTypeId,
    vehicleTypeId, searchString?, pickupDateTime?, sortType?, limit?, offset?): Observable<any> {
    return this.restApiService.get('', '/secure/admin-booking/driver-list?offset=' + offset + '&limit=' + limit +
      '&pickupLat=' + srcLat + '&pickupLong=' + srcLng + '&destLat=' + destLat + '&destLong=' + DestLng + '&numOfSeats=' +
      numOfSeats + '&vehicleTypeId=' + vehicleTypeId + '&searchString=' + searchString
      + '&pickupDateTime=' + pickupDateTime + '&rideTypeId=' + rideTypeId + '&serviceTypeId=' + serviceTypeId + '&sortType=' + sortType);
  }

  getPreBookingDetails(): Observable<any> {
    return this.restApiService.get('', `/secure/admin-booking/pre-booking/data`, 'page-center');
  }

  getOnlineReferralDetails(id): Observable<any> {
    return this.restApiService.get('', '/secure/online-referrals/' + id, 'page-center');
  }

  getReasons(type): Observable<any> {
    return this.restApiService.get('', `/secure/reasons?type=${type}`, 'page-center');
  }

  getFareEstimate(servicetypeid, data): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${servicetypeid}/fare-estimation`, data, 'page-center');
  }

  updateFareEstimate(servicetypeid, data, estimateId): Observable<any> {
    return this.restApiService.put('', `/secure/booking/${servicetypeid}/fare-estimation/${estimateId}`, data, 'page-center');
  }

  getFareEstimateForRideShare(data): Observable<any> {
    return this.restApiService.post('', `/secure/ride-share/fare-estimate`, data, 'page-center');
  }

  bookRideNowAndRideLater(servicetypeid, data): Observable<any> {
    return this.restApiService.post('', `/secure/booking/manual/${servicetypeid}`, data, 'page-center');
  }

  getProviderSchedule(data, limit = 5, offset = 0): Observable<any> {
    return this.restApiService.put('', `/secure/providers-schedules?limit=${limit}&offset=${offset}`, data, 'page-center');
    // return this.restApiService.put('', `/secure/providers-schedules`, data, 'page-center');
  }

  getProviderSchedulelist(data, limit = 5, offset = 0): Observable<any> {
    return this.restApiService.put('', `/secure/providers/list?limit=${limit}&offset=${offset}`, data, 'page-center');
    // return this.restApiService.put('', `/secure/providers-schedules`, data, 'page-center');
  }

  reAssignProvider(tripId, data): Observable<any> {
    return this.restApiService.put('', `/secure/tour/${tripId}/reassign-provider`, data, 'page-center');
  }

  setAppointmentFilter(data): Observable<any> {
    return this.restApiService.post('', `/secure/user-filters`, data, 'page-center');
  }

  getAppointmentFilter(userId, filterType): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userId + '?filterType=' + filterType, 'page-center');
  }

  removeAppointmentFilter(userId, filterType): Observable<any> {
    return this.restApiService.delete('', '/secure/user-filters/' + userId + '?filterType=' + filterType, 'page-center');
  }

  updateBookRideNowAndRideLater(servicetypeid, data, tripId): Observable<any> {
    return this.restApiService.put('', `/secure/tour/update/${servicetypeid}/${tripId}`, data, 'page-center');
  }

  bookRideShare(data): Observable<any> {
    return this.restApiService.post('', `/secure/ride-share/booking/manual`, data, 'page-center');
  }

  getNearByVehicles(sLatitude, sLongitude, dLatitude, dLongitude, radius, serviceTypeId, vehicleTypeId): Observable<any> {
    return this.restApiService.get('', `/secure/vehicles/data/nearbyvehicles/eta?sLatitude=
    ${sLatitude}&sLongitude=${sLongitude}&dLatitude=${dLatitude}&dLongitude=${dLongitude}&radius=
    ${radius}&serviceTypeId=${serviceTypeId}&vehicleTypeId=${vehicleTypeId}`, 'page-center');
  }


  getNearByVehiclesForRideShare(sLatitude, sLongitude, dLatitude, dLongitude, serviceTypeId): Observable<any> {
    return this.restApiService.get('', `/secure/ride-share/near-by-vehicles/eta?slat=${sLatitude}&slong=` +
      `${sLongitude}&dlat=${dLatitude}&dlong=${dLongitude}&serviceTypeId=${serviceTypeId}`, 'page-center');
  }

  getUpdatedLocation(bookingId?): Observable<any> {
    return this.restApiService.get('', '/secure/track-my-location/live/driver-location/' + bookingId, 'page-center');
  }

  getEstimateTimeDistance(data): Observable<any> {
    return this.restApiService.post('', `/secure/tour/estimate/timedistance`, data, 'page-center');
  }

  importExceltripdata(data): Observable<any> {
    return this.restApiService.post('', `/secure/booking/import/xls`, data, 'page-center');
  }

  getDarketDrivers(pickupDateTime, primaryMarket, pickupLat?, pickupLong?, searchString?, ): Observable<any> {
    // return this.restApiService.get('',
    //  '/secure/admin-booking/market-driver-list?pickupDateTime=<pickupTime>&rideTypeId=2&serviceTypeId=1',
    //  'page-center');

    return this.restApiService.get('',
      `/secure/admin-booking/market-driver-list?searchString=${searchString}&pickupLat=${pickupLat}&pickupLong=${pickupLong}` +
      `&pickupDateTime=${pickupDateTime}&primaryMarket=${primaryMarket}&rideTypeId=2&serviceTypeId=1`,
      'page-center');
  }

  getDispatchDrivers(primaryMarket, pickupLat?, pickupLong?, limit?, offset?): Observable<any> {
    // return this.restApiService.get('',
    //  '/secure/admin-booking/market-driver-list?pickupDateTime=<pickupTime>&rideTypeId=2&serviceTypeId=1',
    //  'page-center');

    return this.restApiService.get('',
      `/secure/admin-booking/market-driver-list?pickupLat=${pickupLat}&pickupLong=${pickupLong}` +
      `&marketId=${primaryMarket}&rideTypeId=2&serviceTypeId=1&limit=${limit}&offset=${offset}`,
      'page-center');
  }

  assignMarketDriver(data): Observable<any> {
    return this.restApiService.post('', `/secure/tour/assign-market-driver`, data, 'page-center');
  }

  getProvider(marketName?, providerType?, secondaryMarketName?): Observable<any> {
    return this.restApiService.get('', `/secure/driver/search/list?primaryMarket=${marketName}&secondaryPrimaryMarket=` +
      `${secondaryMarketName}&providerType=${providerType}`, 'page-center');
  }

  validationDriver(marketName?): Observable<any> {
    return this.restApiService.get('', `/secure/driver/search/list?marketName=${marketName}`, 'page-center');
  }

  validateDriverAvailability(data): Observable<any> {
    return this.restApiService.put('', '/secure/tours/driver-availability', data, 'page-center');
  }

  updateMeetingLink(tourid, data): Observable<any> {
    return this.restApiService.put('', '/secure/tours/' + tourid + '/meeting-link', data);
  }

  startAppointmentCall(tripid, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/driver/start-trip/' + tripid, data, 'page-center');
  }

  endAppointmentCall(data): Observable<any> {
    return this.restApiService.post('', '/secure/tour/drop', data, 'page-center');
  }

  rescheduleAppointment(tourId, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/reschedule-appointment/' + tourId, data, 'page-center');
  }

  collectCash(tourId, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/collect-cash/' + tourId, data, 'page-center');
  }

  getProviderForAppointment(marketName?, providerType?, secondaryMarketName?): Observable<any> {
    return this.restApiService.get('', `/secure/providers/search/list?primaryMarket=${marketName}&secondaryPrimaryMarket=
    ${secondaryMarketName}&providerTypeIds=${providerType}`, 'page-center');
  }

  assignRemoteProvider(tourid, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/assign/remote-provider/' + tourid, data, 'page-center');
  }

  updateTripNotes(tourid, data): Observable<any> {
    return this.restApiService.put('', '/secure/tours/' + tourid + '/trip-notes', data);
  }

  updateCovidScreening(tourid, data): Observable<any> {
    return this.restApiService.put('', '/secure/tours/' + tourid + '/covid-screening', data);
  }

  // validiteDriverAvailability(servicetypeid, data): Observable<any> {
  //   return this.restApiService.put('', `/secure/tours/driver-availability${servicetypeid}`, data, 'page-center');
  // }

  // /api/:tenantid/secure/tour/assign-market-driver

  // data = {
  //   "tourId":"5655545744331313",
  //   "driverIdList":[ "54654646456464646", "4489566565656656" ]
  // }

  getDataforCatSubcat(type): Observable<any> {
    return this.restApiService.get('', `/secure/sdoh-screening-form?formType=${type}`, 'page-center');
  }
  sendSDoHData(data): Observable<any> {
    return this.restApiService.post('', '/secure/sdoh-screening-form', data, 'page-center');
  }
  updateSDoHData(data): Observable<any> {
    return this.restApiService.put('', '/secure/sdoh-screening-form', data);
  }
  sendScreeningSDoHData(data): Observable<any> {
    return this.restApiService.post('', '/secure/sdoh-screening-request', data, 'page-center');
  }

  getMarketname(city, state): Observable<any> {
    return this.restApiService.get('', `/secure/market-timezones?city=${city}&state=${state}`, 'page-center');
  }
  getProviders(data): Observable<any> {
    return this.restApiService.put('', '/secure/providers-schedules/list', data);
  }
  approveAppointment(tourid): Observable<any> {
    return this.restApiService.put('', '/secure/tours/' + tourid + '/approve');
  }
  getTwilioToken(id): Observable<any> {
    return this.restApiService.get('', '/twilio/access-token?identity=' + id, 'page-center');
  }
  getTwilioRoomName(data): Observable<any> {
    return this.restApiService.post('', '/secure/twilio/start-video-call', data, 'page-center');
  }
  addPatientAddress(id, data): Observable<any> {
    return this.restApiService.put('', '/secure/users/' + id + '/address', data, 'page-center');
  }

  addNewDispatchNote(data): Observable<any> {
    return this.restApiService.post('', `/secure/dispatch-notes`, data, 'page-center');
  }

  getDispatchNotes(onlineReferralId, dispatchNotesId): Observable<any> {
    return this.restApiService.get('', `/secure/dispatch-notes/${onlineReferralId}/${dispatchNotesId}`, 'page-center');
  }

  updateDispatchNotes(data): Observable<any> {
    return this.restApiService.put('', `/secure/dispatch-notes`, data, 'page-center');
  }

  getNotesGridById(type, onlineReferralId): Observable<any> {
    return this.restApiService.get('', `/secure/dispatch-notes/grid/list/data?type=${type}&id=${onlineReferralId}`, 'page-center');
  }

  removeAppointmentMapping(visitId, tourId): Observable<any> {
    return this.restApiService.delete('', `/secure/visits/appointment/${visitId}/${tourId}`, 'page-center');
  }
}
