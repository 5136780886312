import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppSettings } from '../../app.settings';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html'
})

export class OtpComponent implements OnInit {
  @Output() otpProcess: EventEmitter<any> = new EventEmitter();
  @Output() otpPopClose: EventEmitter<any> = new EventEmitter();
  @Output() otpPopSave: EventEmitter<any> = new EventEmitter();
  @Input() inputRequired = true;
  @Input() inputLabel;
  otpForm = new FormGroup({
    otpInput: new FormControl()
  });
  constructor(private formBuilder: FormBuilder,
    private uiService: UiService) { }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otpInput: ['', [Validators.required]],
    });

    if (!this.inputRequired) {
      this.otpForm.controls.otpInput.setValue('notRequired');
    }
  }

  dispalyMessage(msgObj, msgType) {
    this.uiService.showMessage(msgObj.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
  }

  closeEditForgot(event) {
    if (!this.inputRequired) {
      this.otpForm.controls.otpInput.setValue('notRequired');
    } else {
      this.otpForm.reset();
    }
    this.otpPopClose.emit();
  }

  OTPProcess(event) {
    const otpInput = this.otpForm.controls.otpInput.value;
    this.otpForm.reset();
    this.otpProcess.emit(otpInput);
  }

}
