import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';

@Component({
  selector: 'app-referral-page',
  templateUrl: './referral-page.component.html',
  styleUrls: ['./referral-page.component.scss']
})
export class ReferralPageComponent implements OnInit {
  token: any;
  referralInfo: any;

  constructor(private commonServices: UserCommonService,
    private route: ActivatedRoute,
    private commonBindingDataService: CommonBindingDataService, ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['t'];
    this.commonServices.getReferralFormData(this.token).subscribe(results => {
      this.referralInfo = results;
    }, (error) => {
      // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  setPhoneNumberFormate(phone) {
    return this.commonBindingDataService.numberWithDes(phone);
  }

  setFullAddress(data) {
    let fullAddress = '';
    if (data.addressLine1) { fullAddress += data.addressLine1; }
    if (data.addressLine2) { fullAddress += ', ' + data.addressLine2; }
    if (data.city) { fullAddress += ', ' + data.city; }
    if (data.addressLine3) { fullAddress += ', ' + data.addressLine3; }
    if (data.state) { fullAddress += ', ' + data.state; }
    if (data.zipcode) { fullAddress += ', ' + data.zipcode; }
    return fullAddress;
  }

  setContactPersonDetails(data) {
    let str = '';
    if (data.contactPersonName) { str += data.contactPersonName; }
    // if (data.contactNumCountryCode) { str += ', ' + data.contactNumCountryCode; }
    if (data.contactPhoneNum) {
      str += str.length === 0 ? (data.contactNumCountryCode !== null ? data.contactNumCountryCode : '') + this.setPhoneNumberFormate(data.contactPhoneNum)
        : ', ' + (data.contactNumCountryCode !== null ? data.contactNumCountryCode : '') + this.setPhoneNumberFormate(data.contactPhoneNum);
    }
    if (data.contactEmail) { str += str.length === 0 ? data.contactEmail : ', ' + data.contactEmail; }
    if (str.length === 0) { str += '--'; }
    return str;
  }

}
