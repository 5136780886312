import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-satisfaction-survey-form',
  templateUrl: './satisfaction-survey-form.component.html',
  styleUrls: ['./satisfaction-survey-form.component.scss']
})
export class SatisfactionSurveyFormComponent implements OnInit {

  formCategories: any[] = [];
  formDetails: any;
  displayBtn: boolean;
  submitted: boolean;
  submittedMsg: any;
  token: any;
  cDateRange: string;
  clientInfo: any;

  constructor(private commonServices: UserCommonService,
    private uiService: UiService,
    private route: ActivatedRoute,
    private commonBindingDataService: CommonBindingDataService, ) { }

  ngOnInit(): void {
    this.getMetadata();
    this.cDateRange = this.commonBindingDataService.getYearRangeForCalendar();
    this.token = this.route.snapshot.queryParams['t'];
    this.commonServices.getFormsData('Feedback and Survey', this.token).subscribe(results => {
      this.formDetails = results;
      this.formCategories = results.formCategories;
    }, (error) => {
      this.submitted = true;
      this.submittedMsg = error.general[0].message;
      // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  getMetadata() {
    this.commonServices.getClientInfo().subscribe(results => {
      console.log(results);
      this.clientInfo = results.clientInfo;
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  radioChange(object, subQuestionObj) {
    this.displayBtn = false;
    if (subQuestionObj.questionType === '1001') {
      for (let l = 0; l < subQuestionObj.subCategoryQuestionOptions.length; l++) {
        const subCategoryOptions1 = subQuestionObj.subCategoryQuestionOptions;
        if (subCategoryOptions1[l].id === object.id) {
          if (subCategoryOptions1[l].subCategoryQuestionId === object.subCategoryQuestionId) {
            subCategoryOptions1[l].selectedOption = true;
          }
        } else {
          subCategoryOptions1[l].selectedOption = false;
        }
      }
    }
  }

  radioChangeMCG(object, subQuestionObj, selectedQuestion) {
    if (subQuestionObj.questionType === '1002') {
      for (let m = 0; m < selectedQuestion.associateQuestionOption.length; m++) {
        const associateOptions = selectedQuestion.associateQuestionOption;
        if (associateOptions[m].id === object.id) {
          if (associateOptions[m].subCategoryQuestionId === object.subCategoryQuestionId) {
            associateOptions[m].selectedOption = true;
          }
        } else {
          associateOptions[m].selectedOption = false;
        }
      }
    }
  }

  saveForm() {
    this.displayBtn = true;
    const dataModel = this.getDataModel();
    setTimeout(() => {
      this.commonServices.submitForms(dataModel).subscribe(results => {
        this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
        this.displayBtn = false;
        this.submitted = true;
        this.submittedMsg = results.general[0].message;
      }, (error) => {
        this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
        this.displayBtn = false;
      });
    }, 800);
  }

  getDataModel() {
    this.setAnswerArray();

    const FormModel: any = {
      'token': this.token,
      'id': this.formDetails.id,
      'createdAt': this.formDetails.createdAt,
      'updatedAt': this.formDetails.updatedAt,
      'tenantId': this.formDetails.tenantId,
      'formId': this.formDetails.formId,
      'formName': this.formDetails.formName,
      'formAlias': this.formDetails.formAlias,
      'formType': this.formDetails.formType,
      'instruction': this.formDetails.instruction,
      'description': this.formDetails.description,
      'programType': this.formDetails.programType,
      'patientCondition': this.formDetails.patientCondition,
      'otherDescription': this.formDetails.otherDescription,
      'programTypeId': this.formDetails.programTypeId,
      'patientConditionId': this.formDetails.patientConditionId,
      'assigneeRoleId': this.formDetails.assigneeRoleId,
      'activated': this.formDetails.activated,
      'formTypeId': this.formDetails.formTypeId,
      'applicableEventTypes': this.formDetails.applicableEventTypes,
      'formCategories': this.formCategories,
      'subCategoryQuestions': this.formDetails.subCategoryQuestions,
      'categories': this.formDetails.categories,
      'formPermissions': this.formDetails.formPermissions,
    };
    return FormModel;
  }

  setAnswerArray() {

    for (let i = 0; i < this.formCategories.length; i++) {

      for (let j = 0; j < this.formCategories[i].formCategorySubcategories.length; j++) {

        for (let k = 0; k < this.formCategories[i].formCategorySubcategories[j].subCategoryQuestions.length; k++) {
          const subCategory = this.formCategories[i].formCategorySubcategories[j].subCategoryQuestions;
          for (let l = 0; l < subCategory[k].subCategoryQuestionOptions.length; l++) {
            if (subCategory[k].questionType === '1001') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].selectedOption === true) {
                subCategory[k].answered = true;
              }
              if (subOptions[l].selectedOption === false && subOptions[l].otherOptionAdded === true) {
                subOptions[l].option = 'other';
              }
            }
            if (subCategory[k].questionType === '1002') {
              for (let l = 0; l < subCategory[k].associateQuestions.length; l++) {
                const subOptions = subCategory[k].associateQuestions;
                for (let m = 0; m < subOptions[l].associateQuestionOption.length; m++) {
                  const associateQuestionOptions = subOptions[l].associateQuestionOption;
                  if (associateQuestionOptions[m].selectedOption === true) {
                    subCategory[k].answered = true;
                  }
                }
              }
            }
            if (subCategory[k].questionType === '1003') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].option !== '') {
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1004') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].option !== '') {
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1005') {
              if (subCategory[k].linearScaleAnswer !== '0') {
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1006') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].option !== '') {
                subOptions[l].option = moment(subOptions[l].option).valueOf();
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1007') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].selectedOption === true) {
                subCategory[k].answered = true;
              }
            }
            if (subCategory[k].questionType === '1008') {
              const subOptions = subCategory[k].subCategoryQuestionOptions;
              if (subOptions[l].selectedOption === true) {
                subCategory[k].answered = true;
              }
            }
          }

        }
      }
    }
  }

}
