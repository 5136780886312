<div class="signin-box-container ui-g">
  <div class="signin-box-inner-container">
    <div class="signin-box ui-md-4 ui-md-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field ui-g-12 ui-g-nopad">
          <div class="form-field ui-g-12 ui-g-nopad text-center">
            <img class="company-logo-background" src='./assets/images/temp/rainbowcare_logo.png'>
          </div>
          <div class="text-center ui-g-12">
            <p class="buttom-text" translate>label_otp_send_text</p>
          </div>
          <div class="form-field ui-g-12"></div>
        </div>
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12 mt-30">
          <form [formGroup]='otpForm'>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="username" translate>label_otp_header</label>
              </div>
              <div class="form-control-c">
                <input autocomplete="off" formControlName="otpInput" class="bottom-border" type="text" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="otpForm.controls.otpInput.errors  && (otpForm.controls.otpInput.dirty || otpForm.controls.otpInput.touched)">
                <div [hidden]="!otpForm.controls.otpInput.errors.required" translate>
                  invalid_otp
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12">
              <div [hidden]="otpCounterTimer == 0">
                Resend OTP in {{otpCounterTimer}} seconds.
              </div>
              <div class="form-buttons ui-g-12 ui-g-nopad">
                <button style="margin-right: 0;" class='signin-save-btn' type="button" [label]="'label_continue' | translate" [disabled]="!otpForm.valid"
                  (click)="changePassword($event)" pButton></button>

                <button class='signin-save-btn' type="button" [label]="'label_resend_otp' | translate" [disabled]="otpCounterTimer !== 0"
                  (click)="resendOtp($event)" pButton></button>

                <button pButton class="cancel-back-btn " type="button" [label]="'label_cancel' | translate"
                  (click)="onBackSign($event)"></button>
              </div>
            </div>

          </form>
        </div>
        <div class="remove-float"></div>
      </div>

    </div>
  </div>
</div>
