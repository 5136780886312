<div class="ui-g form-container">
  <form class="ui-g-12 ui-g-nopad" [formGroup]="bookingCancelform">
    <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
      <div class="form-label">
        <label for="primaryCronicCondition" translate>label_cancellation_reason</label>
        <label class="asterisk">*</label>
      </div>
      <div class="form-control-c">
        <p-dropdown [options]="dispositionReasonOptions" formControlName="dispositionReasonId" 
          [filter]="true"> </p-dropdown>
      </div>
      <div class="form-errors ui-g-12"
        *ngIf="bookingCancelform.controls.dispositionReasonId.errors  && (bookingCancelform.controls.dispositionReasonId.dirty || bookingCancelform.controls.dispositionReasonId.touched)">
        <div [hidden]="!bookingCancelform.controls.dispositionReasonId.errors.required" translate>
          error_cancellation_reason_required
        </div>
      </div>
    </div>
    <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
      <div class="form-label">
        <label for="primaryCronicCondition" translate>label_notes</label>
        <!-- <label class="asterisk">*</label> -->
      </div>
      <div class="form-control-c">
        <textarea autocomplete="off" formControlName="cancelReason" maxlength="200" [placeholder]="'label_cancellation_notes' | translate" [rows]="5" [cols]="30" pInputTextarea
          autoResize="autoResize"></textarea>
      </div>
      <div class="form-errors"
        *ngIf="bookingCancelform.controls.cancelReason.errors  && (bookingCancelform.controls.cancelReason.dirty || bookingCancelform.controls.cancelReason.touched)">
        <div [hidden]="!bookingCancelform.controls.cancelReason.errors.required" translate>
          cancel_reason_req
        </div>
        <div [hidden]="!bookingCancelform.controls.cancelReason.errors.maxlength" translate>
          max_character_200
        </div>
      </div>
    </div>

    <div class="ui-g-12">
      <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["pickupLocationName"]'>
        <div *ngFor="let ErrorMessage of packageApiError['pickupLocationName']">
          {{ErrorMessage.message}}
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-g-nopad form-buttons">
      <button class="negative-button" pButton type="button" icon="fa fa-close" [label]="labelCancel"
        (click)="cancel()"></button>
      <button pButton type="button" [label]="labelSave" icon="fa fa-check" [disabled]="!bookingCancelform.valid"
        (click)="cancelBooking()"></button>
    </div>
  </form>
</div>
