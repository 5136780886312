import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AppSettings } from '../../../shared/app.settings';
import { StorageService } from './../../../shared/services/storage.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ErrorBindingService } from '../../../shared/services/error-binding.service';
import { EditablePackagesModel } from 'app/modules/settings/models/editable-packages-model';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-editable-pricing-chauffeur',
  templateUrl: './editable-pricing-chauffeur.component.html'
})

export class EditablePricingChauffeurComponent implements OnInit {
  @Input() corporatePricing = false;
  @Input() corporateId;
  @Input() settingApiError;
  @Input() pricingId;
  @Output() isPackagePopCancel: EventEmitter<any> = new EventEmitter();
  @Output() isPackagePopSave: EventEmitter<any> = new EventEmitter();
  labelSave;
  labelCancel;
  transmissionTypeOptions: any;

  chauffeurPricingForm = new FormGroup({
    packageName: new FormControl(),
    hours: new FormControl(),
    addHours: new FormControl(),
    cancelFee: new FormControl(),
  });

  constructor(
    private formBuilder: FormBuilder,
    private commonBindingDataService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService,
    private uiService: UiService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
    this.labelSave = this.commonBindingDataService.getLabel('label_save');
    this.transmissionTypeOptions = AppSettings.TRANSMISSION_TYPE;
    this.chauffeurPricingForm = this.formBuilder.group({
      packageName: ['', [Validators.required, Validators.pattern(AppSettings.NAME_PATTERN)]],
      hours: [0, [Validators.required, Validators.pattern(AppSettings.NUMBER_PATTERN)]],
      addHours: [0, [Validators.required, Validators.pattern(AppSettings.NUMBER_PATTERN)]],
      cancelFee: [0, [Validators.required, Validators.pattern(AppSettings.NUMBER_PATTERN)]],
      transmissionType: [{
        label: 'Please select',
        value: 0
      }, [Validators.required]],
    });
  }


  conversionMinToHour(min) {
    min = parseInt(min, 10);
    if (min > 0) {
      const hour = ((min / 60));
      return hour;
    } else {
      return 0;
    }
  }

  conversionHourToMin(hour) {
    hour = parseInt(hour, 10);
    if (hour > 0) {
      const min = ((hour) * 60);
      return min;
    } else {
      return 0;
    }
  }

  editChauffeurDataBinding(data, id) {
    if (id != null) {
      this.bindData(data);
    }
  }

  bindData(results) {
    const PackagesfoModel = new EditablePackagesModel(results);
    this.chauffeurPricingForm.controls.packageName.setValue(PackagesfoModel.packageName);
    this.chauffeurPricingForm.controls.addHours.setValue(PackagesfoModel.additionalMinuteRate);
    this.chauffeurPricingForm.controls.hours.setValue(
      this.conversionMinToHour(PackagesfoModel.minutes));
    this.chauffeurPricingForm.controls.cancelFee.setValue(PackagesfoModel.cancellationRate);
    this.chauffeurPricingForm.controls.transmissionType.setValue(PackagesfoModel.transmissionTypeCode);
  }

  addPackages() {
    if (this.corporatePricing) {
      const packages = {
        distance: null,
        minutes: this.conversionHourToMin(this.chauffeurPricingForm.controls.hours.value),
        baseRate: null,
        additionalDistanceRate: null,
        additionalMinuteRate: parseInt(this.chauffeurPricingForm.controls.addHours.value, 10),
        rideTimeFare: null,
        waitTimeFare: null,
        cancellationRate: this.chauffeurPricingForm.controls.cancelFee.value,
        applicableFrom: new Date().getTime(),
        transmissionType: this.chauffeurPricingForm.controls.transmissionType.value
      };
      this.corporatePricingAPI(packages);
    } else {
      const packages = new EditablePackagesModel({
        serviceAreaId: this.storageService.getItem(AppSettings.SERVICE_AREA_ID),
        serviceTypeId: AppSettings.SETTING_SERVICE_ID,
        packageName: this.chauffeurPricingForm.controls.packageName.value,
        vehicleTypeId: null,
        distance: null,
        minutes: this.conversionHourToMin(this.chauffeurPricingForm.controls.hours.value),
        baseRate: null,
        additionalDistanceRate: null,
        additionalMinuteRate: parseInt(this.chauffeurPricingForm.controls.addHours.value, 10),
        rideTimeFare: null,
        waitTimeFare: null,
        cancellationRate: this.chauffeurPricingForm.controls.cancelFee.value,
        applicableFrom: new Date().getTime(),
        transmissionType: this.chauffeurPricingForm.controls.transmissionType.value
      });
      this.adminPricingAPI(packages);
    }
  }

  adminPricingAPI(packages) {
    this.commonBindingDataService.addPricingPackages(JSON.stringify(packages), this.pricingId).subscribe(results => {
      this.showFloatingMessage(results);
      this.pricingId = null;
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.settingApiError);
    });
  }

  corporatePricingAPI(packages) {
    this.commonBindingDataService.updateCorporatePricingPackages(
      JSON.stringify(packages), this.pricingId, this.corporateId).subscribe(results => {
        this.showFloatingMessage(results);
        this.pricingId = null;
      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.settingApiError);
      });
  }

  showFloatingMessage(results) {
    this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
    this.chauffeurPricingForm.reset();
    this.isPackagePopSave.emit(results);
  }

  resetChauffeurForm() {
    this.chauffeurPricingForm.reset();
  }

  cancel() {
    this.pricingId = null;
    this.resetChauffeurForm();
    this.isPackagePopCancel.emit(false);
  }

}
