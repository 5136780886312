import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class MiTranslateLoaderService implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {
    return of({
      vehicle_type_slatus_change: 'Are you sure you want to {{statusLable}} this Vehicle Type?',
      label_id: 'ID',
      label_id_mrn: 'ID/MRN',
      label_mrn: 'MRN',
      Username: 'Email',
      Password: 'Password',
      exceed_max_length: 'Max length is exceed',
      password_too_short: 'Password is too short',
      comp_name: 'Company Name',
      label_sign_in: 'Sign In',
      label_log_in: 'Login',
      msg_logout: 'Are you sure that you want to perform this action?',
      lbl_confirm_idle_session: `Session has been idle over its time limit,
      It will be disconnected in 2 minutes. Click on "Yes" to continue session.`,
      label_sign_in_welcome_text: 'Welcome to the transport evolution',
      label_nemt_pulse: 'NEMT PULSE',
      label_sign_in_description: 'Powerful dispatching, scheduling and appointment management tool for medical transportation',
      label_read_more: 'Read More',
      about_comp: 'About Company',
      label_company_website: 'Company Website',
      label_company_location: 'Company Location',
      label_personal_information: 'Personal Information',
      label_information: 'Information',
      address: 'Address',
      address_col: 'Address',
      label_house_address: 'House Address',
      member_address: 'Patient Address',
      label_appointment_notes: 'Appointment Notes',
      rating_col: 'Rating',
      noOfBookings: 'No. of Bookings',
      address_line1: 'Address Line 1',
      address_line2: 'Address Line 2',
      address_line3: 'Address Line 3',
      web: 'Website',
      phone: 'Phone Number',
      label_phone: 'Phone',
      label_phone_number: 'Phone Number',
      label_primary_phone: 'Primary Phone',
      label_secondary_phone: 'Secondary Phone',

      label_primary_mobile_no: 'Primary Phone Number',
      label_secondary_mobile_no: 'Secondary Phone Number',
      fax: 'Fax',
      invalid: 'Invalid amount',
      label_amount: 'Amount',
      label_pay: 'Pay',
      invalid_hours: 'Invalid hours',
      invalid_package: 'Invalid route name',
      package: 'Route name required',
      hours: 'Hours required',
      cancellation: 'Cancellation fee required',
      add_hours: 'Additional hours required',
      addKm: 'Additional KM. required',
      base_fare: 'Base fare required',
      driver_percentage_req: 'Provider percentage is required',
      amount_req: 'Amount is required',
      distance_amount: 'Invalid distance amount',
      distance_invalid: 'Invalid distance',
      category: 'Category type is required',
      service: 'Service type is required',
      distance: 'Distance required',
      distance_fare: 'Distance fare required',
      wait_fare: 'Wait time fare required',
      ride_fare: 'Ride time fare required',
      hours_time: 'Hours',
      mins: 'Minutes',
      secs: 'Seconds',
      route_fare: 'Fare required',
      route_radius: 'Radius required',
      route_radius_src: 'Radius required',
      route_dest: 'Destination required',
      pickup_time_validation: 'Please select valid time',
      add_destination_validation: 'Please add Destination',
      please_add_value_to_settings: 'Please add time, 0 is not allowed',
      atleast_one_destination: 'Atleast have one Destination',
      pickup_location_validation_msg: 'Please select proper pickup location',
      pickup_location: 'Pickup location required',
      car_type_req: 'Car type Required',
      hotel_req: 'Hotel Required',
      quotation_amount_req: 'Quotation amount required',
      route_pattern: 'Use numbers only',
      amount_pattern: 'Use numbers with a decimal only',
      amount_pattern_without_zero: 'Use numbers without decimal',
      route_length_src: 'Range 0.1 to 1 KM',
      route_pattern_src: 'Use numbers only',
      invalid_number: 'Invalid Number',
      label_fullname: 'Full Name',
      f_name: 'First Name',
      m_name: 'Middle Name',
      l_name: 'Last Name',
      label_first_name: 'First Name *',
      label_last_name: 'Last Name *',
      label_phone_num: 'Phone Number *',
      label_npi: 'NPI Number',
      email: 'Email ID',
      label_email: 'Email',
      label_email_id: 'Email',
      label_verify_email: 'Verify Email',
      label_email_imp: 'Email *',
      document_type: 'Document type is required',
      docRegNo: 'Registration No is required',
      label_create_dept: 'Create Department',
      label_edit_dept: 'Edit Department',
      regId: 'Document ID',
      min_length: 'Minimum 3 characters required',
      min_length_2: 'Minimum 2 characters required',
      max_length: 'Exceeded character limit',
      issue_date: 'Date of issue required',
      expiry_date: 'Date of expiry required',
      end_date_required: 'Date of end required',
      start_date: 'Start date is required',
      end_date: 'End date is required',
      document: 'Document required',
      id_num: 'ID Num is required',
      driver_name: 'Provider name is required',
      name_pattern: 'Invalid name',
      pattern_alphabets: 'Use only alphabets',
      phone_invalid: 'Enter a valid phone number',
      number_pattern: 'Use numbers only',
      fax_pattern: 'Invalid fax number',
      email_required: 'Email is required',
      err_invalid_emailId: 'Please enter valid email address',
      lebel_date_birth: 'Date of Birth',
      dob_required: 'Date of birth is required',
      label_age: 'Age',
      lebel_gender: 'Gender',
      gender_required: 'Gender is required',
      lebel_sex: 'Gender',
      err_sex_required: 'Gender is required',
      provider_type_required: 'Provider type is required',
      default_timezone_required: 'Default timezone  is required',
      health_plan_required: 'Health plan is required',
      primary_market_required: 'Primary market  is required',
      associated_markets_required: 'Associated market  is required',
      specialization_required: 'Specialization is required',
      qualification_required: 'Qualification is required',
      description_required: 'Description is required',
      email_pattern: 'Email ID is invalid',
      address_req: 'Address is required',
      special_notes_req: 'Special notes is required',
      country_req: 'Country is required',
      state_req: 'State is required',
      company_name: 'Company name is required',
      city_req: 'City is required',
      city_pattern: 'Invalid city',
      approval_options: 'Select approval option',
      zipcode_req: 'Zip code is required',
      zipcode_invalid: 'Invalid zip code',
      account_number_invalid: 'Invalid account number',
      banck_code_invalid: 'Invalid account number',
      transmission_type: 'Transmission type required',
      invalid_staff_transport_name: 'Invalid name',
      contact_person_name: 'Contact person name is required',
      label_contact_number: 'Contact Number',
      label_contact: 'Contact',
      contact_number_require: 'Phone number is required',
      comp_name_required: 'Company name required',
      about_comp_required: 'About company required',
      website_required: 'Website required',
      phone_required: 'Phone number required',
      fax_required: 'Fax number required',
      invalid_site: 'Invalid URL',
      service_type: 'Service Type',
      label_service_area: 'Service Area',
      category_t: 'Vehicle Type',
      vehicle_no: 'Vehicle No.',
      vehicle_details: 'Vehicle Make And Model',
      vehicle_not_assigned: 'No vehicle assigned',
      vehicle_details_req: 'Required vehicle make and model',
      vehicle_make: 'Vehicle Make',
      model_yr: 'Model year',
      capacity: 'Capacity',
      lebel_id: 'ID',
      dev_id: 'Device ID',
      condition: 'Vehicle Condition',
      reg_no: 'Registration No.',
      date_issue: 'Date Of Issue',
      date_expiry: 'Date of Expiry',
      label_expired: 'Expired',
      label_empty_message: 'No data found',
      updated: 'Updated On',
      question_req: 'Question required',
      answer_req: 'Answer required',
      hotel_name: 'Hotel name required',
      dispatcher_req: 'Dispatcher required',
      corporate_name: 'Please enter corporate name',
      corporate_code: 'Please enter corporate code',
      name_invalid: 'Please enter valid name',
      corporate_contact_person_name: 'Please enter corporate person name',
      corporateCareExecutive: 'Please select a care executive',
      car_type: 'Car type required',
      drop_location: 'Drop location required',
      booking_date: 'Pickup date required',
      booking_date_and_time_req: 'Pickup date & time is required',
      return_booking_date_and_time_req: 'Return Pickup date & time is required',
      appointment_date_and_time_req: 'Appointment date & time is required',
      passenger_name: 'Passenger name required',
      Package_req: 'Package required',
      cmm_req: 'Car make & model required',
      service_type_req: 'Service Type required',
      note_req: 'Charge code required',
      charge_req: 'Note required',
      deptAdmin_req: 'Assign Department Admin required',
      name_req: 'Name required',
      empId_req: 'Employee id required',
      empId_pattern: 'Invalid Employee id',
      department_req: 'Department required',
      message_passenger_not_assigned: 'Passenger data is not found',
      message_driver_not_assigned: 'Provider not assigned',
      label_date_time: 'Date & time',
      label_date_time_list: 'Date & time',
      label_name: 'Name',
      label_patient_name: 'Patient Name',
      label_topup: 'Top Up',
      label_topic: 'Topic',
      label_successful_trip: 'Successful Appointments',
      label_distance_travelled: 'Distance Travelled (Km)',
      label_total_trip_amount: 'Total Appointment Amount',
      label_credit: 'Credit',
      label_duty_time: 'Duty Time',
      label_bank_name: 'Bank Name',
      label_bank_name_req: 'Bank name is required',
      label_: '',
      label_average_rating: 'Average Rating',
      label_driver_details_header: 'Provider Details',
      label_passenger_details_header: 'Passenger Details',
      label_status: 'Status',
      label_account_status: 'Account Status',
      label_account_number: 'Account Number',
      label_account_number_req: 'Account number is required',
      label_confirm_account_number: 'Confirm account number',
      label_confirm_account_number_req: 'Confirm account number is required',
      label_bank_code_header: 'Bank Code',
      label_bank_code: 'Bank Code (Routing Number/IFSC)',
      label_bank_code_req: 'Bank code (Routing Number/IFSC) is required',
      label_account_type: 'Account Type',
      label_account_type_req: 'Account type is required',
      label_rental_docs_uploaded: 'Rental Documents Uploaded',
      label_hotel_booking: 'Hotel Bookings',
      label_room_num: 'Room No.',
      label_quotation_amount_accepted: 'Accepted Quotation Amount',
      lable_room_no_req: 'Please Enter Room No.',
      label_booking: 'Bookings',
      label_tripID: 'Appointment ID',
      label_balance: 'Balance',
      label_trip_id: 'Appointment ID:',
      label_trip_id_text: 'Appointment ID',
      label_vehicle_id: 'Vehicle ID',
      label_driver_id: 'Provider ID',
      label_member_id: 'Patient ID',
      label_mem_id: 'Member ID',
      label_provider_id: 'Provider ID',
      label_service_id: 'Service Type:',
      label_add_amount: 'Add Top Up Amount',
      label_pickup: 'Pickup',
      label_pickup_header: 'Pick Up',
      label_dropup_header: 'Drop Up',
      label_hotel_succuss: 'Hotel added successfully',
      label_pickup_date_time: 'PickUp Date & Time',
      label_return_pickup_date_time: 'Return PickUp Date & Time',
      label_appointment_date_time: 'Appointment Date & Time',
      label_trip_date: 'Pickup Date and Time',
      label_pickup_latLong: 'Pickup Latitude-Longitude',
      label_appointment_date: 'Appointment Date',
      label_appointment_time: 'Appointment Time',
      label_trip_date_time_elapsed: 'Pickup Date and Time is elapsed',
      label_From_date_greater: 'To Date should be greater then From Date',
      label_return_trip_date_time_elapsed: 'Return pickup date & time should be greater than pickup date & time',
      label_return_trip_date_time_elapsed_with_appointment:
        'Return pickup date & time should be greater than pickup date & time and appointment date & time',
      label_appointment_date_time_elapsed: 'Appointment date & time should be greater than pickup date & time',
      label_return_trip_date_time_retype: 'Patient has other appointment at same pickup date and time so cannot create the appointment',
      label_pickup_date_time_without_col: 'PickUp Date & Time',
      label_no_driver: 'No provider is available',
      label_filter: 'FILTER',
      label_drop_date_time_without_col: 'Drop Date & Time',
      label_vehicle_type: 'Vehicle Type',
      label_vehicle_type_name: 'Vehicle Name',
      label_enter_vehicle_name: 'Enter Vehicle Name',
      label_vehicle_type_header: 'Vehicle Type',
      label_trip_type: 'Appointment Type',
      label_trip_booked: 'Appointment Booked by',
      label_appointment_approve_by: 'Appointment Approve by',
      label_appointment_approve_date: 'Appointment Approve Date',
      label_doc_expiry_header: 'Docs Expiring in',
      label_vehicle_icon_header: 'Vehicle Icon',
      label_vehicle_type_text: 'Vehicle type',
      label_select_language: 'Select Language',
      noOfPackages: 'No. of Packages',
      label_add_vehicle_type: 'Add Vehicle Type',
      label_edit_vehicle_type: 'Edit Vehicle Type',
      label_change_vehicle_name: 'Change Vehicle Name',
      label_no_of_passenger: `No. of Passenger's `,
      label_view_note_header: 'View Note',
      label_view_note: 'View Note',
      label_rejected: 'You have rejected',
      label_package: 'Package',
      label_get_estimation: 'Get Estimation',
      label_one_way_estimation: 'Estimation for one way appointment',
      label_km: 'Km',
      label_hr: 'Hr',
      label_mins: 'Mins',
      label_previous_quotation: 'Previous Quotations',
      label_service_type_header: 'Service Type',
      label_quoation_amount: 'Quotation amount',
      label_quoation_note: 'Note',
      label_qoutation_reason_for_cancel: 'Reason',
      invalid_amount: 'Invalid amount',
      forgot_password: 'Forgot Password?',
      select_area: 'Select Area',
      label_car_type: 'Car Type',
      label_car_make_model: 'Car make & model',
      label_pickup_location: 'Pickup Location',
      label_drop_location: 'Drop Location',
      label_current_location: 'Current Location',
      label_location: 'Location Name',
      label_location_header: 'Location',
      lbl_location_last_updated: 'Location Last Updated At',
      label_RECUR_AFTER_EVERY: 'RECUR AFTER EVERY',
      label_recurrence_type: 'Please Select  Recurrence Type',
      label_search_by_provider: 'Search By Provider ID Or Name',
      label_search_pickup: 'Search Pickup Location',
      label_search_drop: 'Search Drop Location',
      label_week: 'WEEK',
      lebel_npi: 'NPI',
      label_last_sync: 'Last Synced',
      room_num_max_length: 'Max. 9 character allowed',
      label_member_search: 'Search Patient',
      label_driver_search: 'Search Provider',
      label_passenger_name: 'Passenger Name',
      label_member_name: 'Patient Name',
      label_driver_mobile_no: 'Provider Mobile No.',
      label_mobile: 'Mobile Number',
      label_alternate_number: 'Alternate Number',
      label_assign_driver: 'Assign Provider',
      label_reassign_driver: 'Reassign Provider',
      label_assign_drivers: 'Assign Providers',
      label_note: 'Note',
      label_packages: 'Packages',
      label_CMM: 'Make',
      label_No_Passenger: 'No Passenger',
      label_from_date: 'From Date',
      label_pickup_from_date: 'Pickup From Date',
      label_from: 'From',
      label_to: 'To',
      label_occurrence: 'Occurrence',
      label_start_date: 'Start Date',
      label_passenger_registered_with: 'Passenger Registered with',
      label_to_date: 'To Date',
      label_pickup_to_date: 'Pickup To Date',
      label_end_date: 'End Date',
      label_start_date_registration: 'Start Date (Registration)',
      label_role: 'Role',
      label_service_region: 'Service region',
      label_invalid_role: 'Invalid role, contact to server team',
      label_ID_Number: 'ID No.',
      label_address_line_1: 'Address line 1',
      label_address_line_2: 'Address line 2',
      label_address_type: 'Address Type',
      label_country: 'Country',
      label_state: 'State',
      label_city: 'City',
      label_county: 'County',
      label_approval_by_admin: 'Approval by Admin',
      label_zip_code: 'Postal Code',
      zip_code: 'Zip Code',
      label_PO_box: 'PO box',
      label_assign_role: 'Assign Role',
      label_No: 'No.',
      label_read: 'READ',
      label_select: 'Select',
      label_select_hotel: 'Select Hotel',
      label_driver_name: 'Provider Name',
      label_driver_email: 'Provider Email',
      label_driver_phone_num: 'Provider Phone No.',
      label_driver_phone: 'Provider Phone',
      label_driver_fax: 'Provider Fax',
      label_driver: 'Provider',
      label_member: 'Patient',
      label_new_member: 'Add New Patient',
      label_new_patient: 'New Patient',
      label_booking_type: 'Booking Type',
      label_ride_schedule_at: 'Ride Schedule At',
      label_ride_now: 'Ride Now',
      label_ride_later: 'Ride Later',
      label_registration_state: 'Registration State/Province',
      label_registration_state_req: 'Registration State/Province is Required',
      label_booking_service: 'Booking Service',
      label_booking_services: 'Booking Services',
      label_payment_mode: 'Payment Mode',
      label_invoice_date: 'Invoice Date',
      label_invoice_amount: 'Invoice Amount',
      label_refund_date: 'Refund Date',
      label_refund_amount: 'Refund Amount',
      err_refund_exceeds_value: 'Refund value exceeds total amount',
      label_passenger_type: 'Passenger Type',
      label_completed_bookings: 'Completed Bookings',
      label_cancelled_bookings: 'Cancelled Bookings',
      label_active_bookings: 'Active Bookings',
      label_total_amount: 'Total Amount',
      label_payment_received: 'Payment Received',
      label_payment_due: 'Payment Due',
      label_booking_code: 'Booking Code',
      label_booking_count: 'Booking Counts',
      label_waiting_time: 'Waiting Time',
      label_km_in: 'KMs In',
      label_km_covered: ' KM Covered',
      label_km_out: 'KMs Out',
      label_total_vehicles: 'Total Vehicles',
      label_taxi_drivers: 'Taxi Providers',
      label_rental_car_drivers: 'Rental Car Providers',
      label_hotel_dispatchers: 'Hotel Dispatchers',
      label_corporate_dispatchers: 'Corporate Dispatchers',
      label_no_of_bookings: 'No. of Bookings',
      label_month: 'Month',
      label_year: 'Year',
      label_transmission_type: 'Transmission Type',
      label_ative_package_info: 'Active Package Information',
      label_expires_today: 'Expires Today',
      label_already_expired: 'Already Expired',
      label_package_not_started: 'Package is not started',
      label_registration_num: 'Registration No.',
      label_date_issue: 'Date of Issue',
      label_date_expiry: ' Date of Expiry',
      label_date_end: ' Date of End',
      label_documents: 'Documents',
      label_document: 'Document',
      label_document_type: 'Document Type',
      label_category: 'Category',
      label_VM: 'Vehicle Make',
      label_vehicle_name: 'Vehicle Name',
      label_VM_name: 'Model Name',
      label_vehicle_no: 'Vehicle No.',
      label_vehicle_reg_no: 'Vehicle Registration Number',
      label_reg_no: 'Registration Number',
      label_reg_no_required: 'Registration number is required',
      label_vehicle_number: 'License Plate Number',
      label_vehicle_number_with_colon: 'License Plate Number :-',
      label_model: 'Model',
      label_model_year: 'Model Year',
      label_model_color: 'Model Color',
      label_residence: 'Residence',
      label_provider: 'Provider',
      label_providers: 'Providers',
      label_provider_name: 'Provider Name',
      label_providers_name: 'Providers Name',
      label_insurance_details: 'Insurance Details',
      label_group_number: 'Group number',
      label_languages_known: 'Languages Known',
      label_languages_preferred: 'Languages Preferred',
      label_race: 'Race',
      label_ethnicity: 'Ethnicity',
      label_primary_chronic_condition: 'Primary chronic condition',
      label_program_enrolled: 'Program Enrolled',


      label_capacity: 'Capacity',
      label_ETA: 'ETA',
      label_car_condition: 'Car Condition',
      label_device_id: 'Device ID',
      category_req: ' Category Required',
      vehicle_number_req: ' License Plate Number Required',
      vehicle_number_pattern: 'Invalid license plate number',
      vehicle_model_year_req: 'Model year Required',
      vehicle_model_year_pattern: 'Invalid Model year',
      capacity_req: 'Capacity is Required',
      two_digit_max: 'Only 2 digits allowed',
      car_condition_req: 'Car Condition Required',
      device_id_req: 'Device ID Required',
      aplha_numeric_pattern: 'Use only alpha-numeric characters',
      label_dashboard: 'Dashboard',
      label_sales_quotations: 'Sales Quotations',
      label_Passenger_type: 'Type of Passengers',
      label_Passengers: 'Passengers',
      label_Dispatchers: 'Dispatchers',
      label_Vehicles: 'Vehicles',
      label_Vehicle: 'Vehicle',
      label_Drivers: 'Providers',
      label_Staff_Transport: 'Staff Transport',
      label_hotel: 'Hotel',
      label_Hotels: 'Hotels',
      label_corporate: 'Corporate',
      label_Corporates: 'Corporates',
      label_private: 'Private',
      label_App_Settings: 'App Settings',
      label_Settings: 'Settings',
      label_Reports: 'Reports',
      label_region: 'Region',
      label_reference_setting: 'Reference Setting',
      label_regions: 'Regions',
      label_taxes: 'Taxes',
      admin_settings: 'Admin Settings',
      label_add_region: 'Add Region',
      label_edit_region: 'Edit Region',
      label_promo_title: 'Promo title',
      lable_promo_title_require: 'Title is required',
      lable_promo_code_require: 'Code is required',
      lable_promo_date_range_require: 'Date range is required',
      lable_usage_require: 'Limit is required',
      lable_usage_pattern: 'invalid limit',
      lable_discount_require: 'Discount is required',
      lable_max_discount_require: 'Max discount is required',
      lable_discount_pattern: 'Invalid Discount',
      lable_max_discount_pattern: 'Invalid max discount pattern',
      lable_already_uploadd_document: 'Document already uploaded for this type.',
      lable_close_package_permision: 'Are you sure do you really want to close this Package?',
      lable_no_vehicle_type_found: 'No Vehicle Type found',
      lable_no_vehicle_found: 'No Vehicle found',
      lable_updated_successfully: 'Updated successfully',
      lable_added_succesfully: 'Added succesfully',
      label_added_by: 'Added By',
      label_promo_code: 'Promo Code',
      label_no_of_uses: 'No. of uses',
      label_usage_limit: 'Usage Limit',
      label_per_user_usage: 'Usage Limit per User',
      label_add_promo_code: 'Add Promo Code',
      label_update_promo_code: 'Edit Promo Code',
      label_date_range: 'Date Range',
      label_usage: 'Usage',
      label_usage_count: 'Usage Count',
      label_limit_per_user: 'Limit per user',
      label_mode: 'Mode',
      label_discount: 'Discount',
      label_max_discount: 'Max Discount',
      label_time_settings: 'Time Settings',
      label_Pricing: 'Pricing',
      label_Routes: 'Routes',
      label_edit: 'Edit',
      label_update: 'Update',
      label_delete: 'Delete',
      label_add: 'Add',
      label_package_name: 'Package Name',
      label_base_fare: 'Base Fare',
      label_base_fare_placeholder: 'Base Fare *',
      label_booking_fee: 'Booking Fee',
      label_distance: 'Distance',
      label_distance_fare: 'Additional Distance Fare Per KM',
      label_wait_time_fare: 'Wait Time Fare',
      label_wait_time_fare_placeholder: 'Wait Time Fare (Minutes) *',
      label_wait_time_fare_mins: 'Wait Time Fare (Minutes)',
      label_ride_time_fare: 'Ride Time Fare',
      label_cancellation_fee: 'Cancellation Charges',
      label_cancellation_fee_placeholder: 'Cancellation Charges *',
      label_actions: 'Actions',
      label_action: 'Action',
      label_details: 'Details',
      label_remove_mapping: 'Remove Mapping',
      label_create_trip: 'Create Appointment',
      label_trip_created: 'Appointment Created at',
      label_trip_created_by: 'Appointment Created by',
      label_created_by: 'Created by',
      label_additional_hours: 'Additional Rate Per Hour',
      label_hours: 'Hours',
      label_additional_KM: 'Additional Rate Per KM',
      label_category_type: 'Vehicle type',
      label_distance_unit: 'Distance (KM)',
      label_distance_for_base_fare: 'Distance (KMs) For Base Fare',
      label_send: 'Send',
      label_save: 'Save',
      label_submit: 'Submit',
      label_submitted_on: 'Submitted on',
      label_book: 'Book',
      label_basic_details: 'Basic Details',
      label_cancel: 'Cancel',
      please_select: 'Please Select',
      label_all_cities: 'All Cities',
      label_route_name: 'Route Name',
      label_radius: 'Radius',
      label_radius_m: 'Radius(meters)',
      label_radius_unit: 'Radius(KM)',
      label_no_routes: 'No. of Routes',
      label_updated_on: 'Updated On',
      label_Radius_Meters: 'Radius (Meters)',
      label_source: 'Source',
      label_destination: 'Destination',
      label_add_destination: 'Add Destination',
      label_fare: 'Fare',
      label_fares: 'Fares',
      label_add_route: 'Add Route',
      label_update_route: 'Edit Route',
      label_add_packages: 'Add Packages',
      label_update_packages: 'Update Packages',
      label_FAQ: 'FAQ',
      label_description: 'Description',
      label_qualification: 'Qualification',
      label_description_placeholder: 'Description *',
      label_terms_conditions: 'Terms & Conditions',
      label_privacy_policy: 'Privacy Policy',
      label_rental_privacy_policy: 'Rental Policy',
      label_chauffeur_privacy_policy: 'Chauffeur Policy',
      label_password_match: 'password does not match',
      label_docs_validation: 'Add atleast one document',
      label_otp: 'Please enter OTP',
      label_otp_header: 'OTP',
      label_otp_msg: 'We will send One Time Password (OTP) on your registered email to reset password',
      label_current_km: 'Please enter Current KM',
      label_enter: 'Please enter',
      invalid_otp: 'Invalid OTP',
      verified_otp: 'OTP Verified',
      matched_otp: 'OTP matched',
      label_our_partners: 'Our Partners',
      label_choose: 'Choose',
      label_outcome: 'Outcome',
      label_upload: 'Upload',
      label_upload_trip_data: 'Upload Appointment Data',
      label_upload_document: 'Upload Document',
      label_choose_file: 'Choose File',
      label_qusetion: 'Question',
      label_answer: 'Answer',
      label_clear: 'Clear',
      label_edit_term_Condition: 'Edit Terms & Conditions',
      label_edit_privacy_policy: 'Edit Privacy Policy',
      label_edit_rental_policy: 'Edit Rental Policy',
      label_edit_chauffeur_policy: 'Edit Chauffeur Policy',
      label_add_partner: 'Add Partner',
      label_hash: '#',
      label_corporate_info: 'Corporate Info',
      label_contact_person: 'Contact Person',
      label_department_count: 'Total Departments',
      label_registration_Date: 'Registration Date',
      label_total_bookings: 'Total Bookings',
      label_deactivate: 'Deactivate',
      label_activate_edit: 'Active & Edit',
      label_activate: 'Activate',
      label_start_call: 'Start Session',
      label_end_call: 'End Session',
      label_highest_priorities: 'Highest Priority',
      label_start_zoom_call: 'Re-join',

      label_Total: 'Total',
      label_active: 'Active',
      label_deactive: 'Deactive',
      label_Active: 'active',
      label_Deactive: 'deactive',
      label_cancelled: 'Cancelled',
      label_completed: 'Completed',
      label_new: 'New',
      label_regret: 'Regret',
      label_corporate_information: 'Corporate Information',
      label_package_pricing: 'Package Pricing',
      lable_route_pricing: 'Route Pricing',
      label_corporate_name: 'Corporate Name',
      label_corporate_code: 'Corporate Code',
      label_contact_person_name: 'Contact Person Name',
      label_assign_corporate_executive: 'Assign Corporate Care Executive',
      label_save_next: 'Save & Next',
      label_save_quit: 'Save & Quit',
      label_add_princing: 'Add Pricing',
      label_back: 'Back',
      label_finish: 'Finish',
      label_next: 'Next',
      label_quit: 'Quit',
      label_additional_hours_fee: 'Additional Hours Rate',
      label_hotel_info: 'Hotel Info',
      label_dispatcher_name: 'Dispatcher Name',
      label_dispatcher_no: 'Dispatcher No.',
      label_hotel_name: 'Hotel Name',
      label_dispatcher_details: 'Dispatcher Details',
      label_hotel_information: 'Hotel Information',
      labe_notifications_to: 'to',
      label_notifications: 'Notifications',
      label_no_notification_found: 'No more notification found',
      label_no_driver_found: 'No provider found',
      label_date_and_time: 'Date & time',
      label_resend: 'Resend',
      label_vehicle_info: 'Vehicle info',
      label_vehicle_count: 'Vehicle Count',
      label_assign: 'Assign',
      label_add_documents: 'Add Documents',
      label_add_documents_details: 'Attached Documents Details',
      label_rating: 'Ratings',
      label_avg_rating: 'Avg. Ratings',
      label_yes: 'Yes',
      label_no: 'No.',
      label_no_without_dot: 'No',
      label_all: 'All',
      label_busy: 'Busy',
      label_online: 'Online',
      label_on_the_way: 'On the way',
      label_available: 'Available',
      label_not_available: 'Not Available',
      label_idle: 'Idle',
      label_mailing_address: 'Mailing Address',
      label_off_duty: 'Off Duty',
      label_S_NO: 'S.No.',
      label_vehicle: 'Vehicle',
      label_vehicle_code: 'Vehicle Code',
      label_accesse_name: 'Accesses Name',
      label_card_details: 'Card Details',
      label_dispatcher: 'dispatcher',
      label_trip_completed: 'Appointments Completed',
      label_user_type: 'User Type',
      label_vehicleNumber: 'Vehicle Number',
      label_active_vehicle: 'Active Vehicles',
      label_inactive_vehicle: 'Inactive Vehicles',
      label_idle_vehicle: 'Idle Vehicles',
      label_driverID: 'Provider ID',
      label_trip_amount: 'Appointment Amount',
      label_bank_details: 'Bank Details',
      label_trip_date_time: 'Appointment Date & Time',
      label_BookingId: 'Booking ID',
      label_trip_status: 'Appointment Status',
      label_source_address: 'Source Address',
      label_destination_address: 'Destination Address',
      label_reset: 'Reset',
      label_apply: 'Apply',
      label_reset_filter: 'Reset Filter',
      label_passenger: 'Passenger',
      label_pickup_drop_location: 'Pickup & Drop location',
      lable_pickup_date: 'Pickup Date',
      lable__date_time: 'Date and Time',
      lable_pickup_date_time: 'Pickup Date and Time',
      lable_select_date_time: 'Select Date & Time',
      lable_select_date: 'Select Date',
      label_booking_ID: 'Booking ID',
      lable_upload_quote_pdf: 'Upload Quote (PDF)',
      label_booking_Date: 'Booking Date',
      label_upload_quote: 'Upload Quote',
      label_start_trip: 'Start Appointment',
      label_end_trip_message: 'Appointment is ended successfully',
      label_extend_trip: 'Extend Appointment',
      label_end_trip: 'End Appointment',
      label_cancel_trip: 'Cancel Appointment',
      label_security_check: 'Security Check',
      label_delivery_check: 'Delivery Check',
      label_car_return_check: 'Car Return Check',
      label_return_security_check: 'Return Security Check',
      label_resend_quote: 'Resend Quote',
      label_taxi: 'Taxi',
      label_rental_car: 'Rental Car',
      label_chauffeur: 'Chauffeur',
      label_booking_status: 'Booking Status',
      label_payment_status: 'Payment Status',
      label_search: 'Enter text to search.',
      label_search_header: 'Search',
      label_charge_code: 'Charge Code',
      label_department: 'Department',
      label_department_admin: 'Department Admin',
      lable_employeeID: 'Employee ID',
      label_active_trips: 'Active Appointments',
      label_completed_trips: 'Completed Appointments',
      label_upcoming_trips: 'Upcoming Appointments',
      label_critical_trips: 'Critical Appointments',
      label_cancelled_trips: 'Cancelled Appointments',
      label_driver_availability: 'Provider Availability',
      label_on_trips: 'On Appointment',
      label_on: 'ON',
      label_off: 'OFF',
      label_assign_department: 'Assign Department Admin',
      lable_department_name: 'Department Name',
      lable_employee_name: 'Employee Name',
      label_no_trips: 'No. of Appointments',
      label_view_details: 'View Details',
      label_mark_vip: 'Mark As VIP',
      label_approve: 'Approve',
      label_reject: 'Reject',
      label_pending_approval: 'Pending for Approval',
      label_manage_employee: 'Manage Employees',
      label_manage_departments: 'Manage Departments',
      label_pricing_details: 'Pricing Details',
      label_taxi_service: 'Taxi Service',
      label_rental_car_service: 'Rental Car Service',
      label_chauffeur_service: 'Chauffeur Service',
      label_start_end_date: 'Start-End Date',
      labee_pickup_time: 'Pickup Time',
      label_staff_transport: 'staff-transport',
      label_day: 'Days',
      label_Assign_vehicle: 'Assign Vehicle',
      label_View_Assign_vehicle: 'View Assign Vehicle',
      label_total_fare: 'Total Fare (TNZ)',
      label_holiday: 'Holidays',
      label_another_drop_location: 'Another Drop Location',
      label_type: 'Type',
      label_view_route: 'View Route info',
      label_route_information: 'Route Information',
      label_renew_package: 'Renew Package',
      label_track_driver: 'Track Provider',
      label_car_no: 'Car No.',
      charge_code_maxlength: 'Allowed maximum 4 character',
      label_Cancelation: 'Cancellation Reasons',
      label_disposition_reason: 'Disposition reason',
      label_cancellation_reason: 'Cancellation Reason',
      label_cancelled_at: 'Cancelled At',
      label_cancellation_note: 'Cancellation Note',
      label_booking_cancel: 'Cancel Appointment',
      label_rating_comments: 'Rating & Comments',
      label_reply: 'Reply',
      label_cancel_reason: 'Cancel Reason',
      label_reason: 'Reason',
      label_trip_history: 'Appointment History',
      label_forgot_password: 'Forgot Password',
      label_forgot: 'User Name/ Email /Phone No.',
      label_validate_otp: 'Validate OTP',
      cancel_reason_req: 'Cancel Reason Required',
      disposition_reason_req: 'Disposition reason is required',
      label_cancel_booking: 'Cancel Appointment',
      label_country_code: 'Country Code',
      country_code_required: 'Please select country code',
      country_code_invalid: 'Country code is invalid',
      booking_details_result: 'No booking record found',
      booking_comment_details: 'No comment',
      label_comment: 'Comment',
      comment_heading: 'Rating & Comments',
      view_all: 'View All',
      label_driver_type: 'Provider Type',
      label_confirmation: 'Confirmation',
      label_confirm: 'Confirm',
      label_select_access: 'Select Access',
      label_confirm_extend_package: 'Do you really want to Extend Package?',
      label_extend_package_confirmation_header: 'Extend Package Confirmation',
      label_confirm_emp_confirm_booking_p1: 'Are you sure that you want to',
      lable_confirm_emp_confirm_booking_p2: ' this booking',
      label_select_service_type: 'Select service type',
      label_invalid_user: 'Invalid User Name/ Email /Phone No.',
      label_driver_location: 'Provider Location',
      label_report_driver_login_logout: 'Provider Login-Logout',
      label_report_driver_on_duty: 'Provider On Duty',
      label_report_driver_income: 'Provider Income',
      label_report_driver_mileage: ' Provider Mileage',
      label_report_driver_performance: 'Provider Performance',
      label_report_car_mileage: 'Car Mileage',
      label_report_transction: 'Transaction',
      label_report_refund: 'Refund',
      label_report_promo_code: 'Promo Code',
      label_report_referral_credit: 'Referral Credit',
      label_report_passenger_feedback: 'Passenger Feedback',
      label_report__feedback: 'Feedback',
      label_mode_promo_code: 'Promo Code Mode',
      label_mode_usage_type: 'Usage Type',
      label_credit_amount: 'Credit Amount',
      label_referred_on: 'Referred on',
      label_clear_polygon: 'Clear Polygon',
      label_region_name: 'Region Name',
      label_alias_name: 'Alias Name',
      label_sr_no: 'Sr No.',
      label_view: 'View',
      label_contact_us: 'Contact Us',
      label_city_regions: 'City Regions',
      label_city_fares: 'City Fares',
      label_edit_fares: 'Edit Fares',
      label_cities: 'Cities',
      label_trip_fare: 'Actual Appointment Fare',
      label_qoutation_fare_estimate: 'Appointment Fare',
      label_estimated_trip_fare: 'Appointment Fare',
      label_estimated_fare: 'Appointment Fare',
      label_estimated_distance: 'Estimated Distance',
      label_estimated_time: 'Estimated Time',
      label_total_fares: 'Total Fare',
      label_estimated_total_fares: 'Total Fare',
      label_via_messageService: 'Via MessageService',
      label_service_message: 'Service Message',
      file_size_limit: 'This file is too large. The maximum size allowed is 20MB',
      file_size_limit_for_messages: 'File size should be less than 20MB',
      file_size_limit_for_forms: 'This file is too large.',
      error_max_file_count: 'Maximum number of files exceeded.',
      invalid_file_type: 'Invalid file type',
      vehicle_assigned_already: 'Are you sure do you really want to assign this Provider? He is already assigned to other vehicle',
      driver_is_not_assigned: 'Provider is not assigned',
      label_ride_type: 'Ride Type',
      ride_type: 'Ride type is required',
      payment_mode_required: 'Payment mode is required',
      passenger_type_reqd: 'Passenger type is required',
      driver_type_reqd: 'Provider type is required',
      first_name_reqd: 'First name is required',
      last_name_reqd: 'Last name is required',
      lbl_pickup_location_eta_distance: 'Pick up ETA distance',
      lbl_pickup_location_eta_time: 'Pick up ETA time',
      lbl_drop_location_eta_distance: 'Drop up ETA distance',
      lbl_drop_location_eta_time: 'Drop up ETA time',
      lbl_passenger_name: 'Passenger Name',
      lbl_passenger_phone: 'Passenger Phone',
      lbl_passenger_email: 'Passenger Email',
      lbl_patient_name: 'Patient Name',
      lbl_patient_phone: 'Patient Phone',
      lbl_patient_email: 'Patient Email',
      label_estimated_trip_statistics: 'Estimated Appointment Statistics',
      label_estimated_fare_details: 'Estimated Fare Details',
      label_reassign: 'Reassign',
      label_center_name: 'Center Name',
      label_special_notes: 'Special Notes',
      label_other_details: 'Other Details',
      label_session_length: 'Session length',
      label_program_name: 'Program Name',
      label_enrollment_duration: 'Enrollment Duration',

      // patient : Documents
      label_doc_name: 'Document name',
      err_doc_name_req: 'Document name is required',
      msg_program_req_an_upload_doc_type_consent: 'Please assign a program to the patient before uploading the document.',
      msg_program_req_an_ask_for_consent: 'Please assign a program to the patient before updating the consent.',
      msg_program_req_on_status_change: 'Please assign a program to the patient before the Enrollment and Graduation.',

      // Care request
      label_care_request: 'Care Requests',
      label_dispatch_request: 'Dispatch Requests',
      label_dispatch_tier: 'Dispatch Tier',
      label_triage_protocol: 'Triage protocol',
      label_preferred_dateTime: 'Preferred Date/Time',
      label_instructions_to_access_home: 'Instructions to Access Home',
      label_triage_note: 'Triage Note',
      label_incident_location: 'Incident Location',
      label_incident_address: 'Incident Address',
      label_additional_Notes_Comments: 'Additional Notes/Comments',
      label_requesting_provider_first_name: 'Requesting Provider First Name',
      label_requesting_provider_last_name: 'Requesting Provider Last Name',
      label_requesting_provider: 'Requesting Provider',
      label_requesting_provider_information: 'Requesting Provider Information',
      label_dispatch_request_information: 'Dispatch Request Information',
      label_dispatch_summary: 'Dispatch summary',
      label_client: 'Client',
      label_time_lapsed: 'Time Lapsed',
      label_accepted: 'Accepted',
      lbl_rejected: 'Rejected',
      label_dispatched: 'Dispatched',
      label_information_requested: 'Information Requested',
      label_primary_address_on_file: 'Primary address on file',
      error_primary_address_file_required: 'Primary address on file is required',
      error_primary_phone_required: 'Primary phone number is required',
      error_dispatch_tier_required: 'Dispatch Tier is required',
      error_triage_protocol_required: 'Triage Protocol is required',
      error_preffered_date_time_required: 'Preferred Date/Time is required',
      error_name_required: 'Name is required',
      error_incident_address_required: 'Incident address is required',
      label_driver_details: 'Provider Details',
      error_provider_id_required: 'Provider Id is required',
      error_fax_len: 'Fax should contain minimum 10 digit',
      label_attach_document: 'Attach Document',
      label_attached_document: 'Attached Document',
      label_okay: 'Okay',
      label_save_reject: 'Save & Reject',
      label_save_accept: 'Save & Accept',
      label_recieved_care_request: 'Recieved Care Request',
      label_action_taken: 'Action taken',
      label_mrn_matched_patient: 'MRN Matched Patient',
      label_request_more_info: 'Request More Info',
      label_referral_source_type: 'Referral Source Type',
      label_organization_name: 'Organization Name',
      error_organization_name_required: 'Organization Name is required',
      error_referral_source_type_required: 'Referral Source Type is required',
      label_Pdf: 'Pdf',
      label_image: 'Image',
      label_excel: 'Excel',
      lebel_preferred_date_range: 'Preferred Date (Range)',
      label_pending: 'Pending',
      label_create_dispatch_request: 'Create Dispatch Request',
      error_dispatch_note_required: 'Dispatch note is required',

      // USC : patient
      label_pharmacy_site: 'Pharmacy Site',
      label_patient_status: 'Patient Status',
      label_discharged_reason: 'Discharged Reason',
      label_other_discharged_reasons: 'Other Discharged Reasons',
      label_practitioner_type: 'Practitioner type',
      label_referral_source: 'Referral source',
      label_if_other: 'Other',
      label_street_address: 'Street address',
      label_medical_facility: 'Medical facility',
      label_notes: 'Notes',
      label_baseline_health_questions: 'Baseline Health Questions',
      label_height: 'Height',
      label_tobacco_status: 'Tobacco Status',
      label_current_tobacco_status: 'Current Tobacco Status',
      label_alcohol_consumption: 'Alcohol Consumption',
      label_caffeine_consumption: 'Caffeine Consumption',
      label_drinks: 'Drinks',
      label_recreationnal_drug_consumption: 'Recreational Drug Consumption',
      label_allergies: 'Allergies',
      label_family_history: 'Family History',
      label_relationship: 'Relationship',
      label_past_hospitalizations: 'Past Hospitalizations/ER Visits',
      label_chronic_Conditions_for_cmm: 'Chronic Conditions for CMM',
      label_other_medical_conditions: 'Other Medical Conditions',
      label_vaccinations: 'Vaccinations',
      label_vaccinations_date: 'Vaccination date',
      label_other_type: 'Other type',
      label_surgical_history: 'Surgical History',
      label_previous: 'Previous',
      label_add_new: 'Add New',
      label_identification: 'Identification',
      label_health_history: 'Health History',
      label_updated_by: 'Updated By',
      label_primary_referral_reason: 'Primary Referral Reason',
      label_visit_id: 'Visit ID',
      label_visit_type: 'Visit type',
      label_visit_date: 'Visit Date',
      label_visit_time: 'Visit Time',
      label_visit_count: 'Visit Count',
      label_documented_by: 'Documented by',
      label_new_visit: 'New Visit',
      label_create_care_task: 'Create Care-tasks',
      label_visit: 'Visit',

      // Appointment
      label_add_address_header: 'The selected patient doesn"t have an address. Please provide it before booking an appointment.',
      label_confirm_remote_provider_update_scheduler: 'Confirm Remote provider update scheduler',
      label_remote_provider_update_scheduler_text: 'You are updating remote provider schedule. After clicking "Yes" earlier timeslot selection will get cleared.',
      label_another_appt_scheduled_text: 'Another appointment is scheduled within selected timeslot.',
      label_primary_associated_market_not_same_text: 'Primary market and associated market could not be same.',
      label_select_atleast_one_value_to_save_filter_text: 'Select atleast one value to save filter.',
      label_on_demand_onsite: 'On Demand onsite',
      label_request_for_appointment_for_market_providers: 'You are about to add the request for an appointment for market providers.',
      label_select_patient: 'Select Patient',
      label_ignore_field: 'Ignore Field',
      label_select_date_and_time: 'Select Date and Time',
      label_select_time: 'Select Time',
      label_highly_recommended: 'Highly Recommended',
      label_recommended: 'Recommended',
      label_scheduled: 'Scheduled',
      label_select_onsite_provider: 'Select Onsite Provider',
      label_select_remote_provider: 'Select Remote Provider',
      label_onsite_provider_name: 'Onsite Provider Name',
      label_remote_provider_name: 'Remote Provider Name',
      label_onsite_provider_checklist: 'Onsite Provider Checklist',
      label_interventions: 'Interventions',
      label_religious_affiliation: 'Religious Affiliation',
      label_patient_consent_time: 'Patient Consent Time',
      label_primary_diagnosis: 'Primary Diagnosis',
      label_current_market: 'Current Market',
      label_update_patient_address: 'Update Patient Address',
      label_dispatchers_notes: 'Dispatchers Notes',
      label_select_field_provider: 'Select Field Provider',

      // label_visit_type: 'Visit Type',
      label_weight: 'Weight',
      label_lbs: 'lbs',
      label_body_temp: 'Body temperature',
      label_temperature: 'Temperature',
      label_temperature_unit: '°F',
      label_resp_rate: 'Respiration rate',
      label_oxygen: 'Oxygen',
      label_oxygen_saturation: 'Oxygen saturation',
      label_heart_rate: 'Heart Rate',
      label_heart_rate_unit: 'bpm',
      label_glucose_unit: 'mg/dL',
      label_eye_opening: 'Eye opening',
      label_verbal_response: 'Verbal response',
      label_motor_response: 'Motor response',
      label_plan_name: 'Plan name',
      label_care_type: 'Care type',
      label_referral_id: 'Referral ID',
      label_screening_id: 'Screening ID',
      label_referral_sender: 'Referral sender',
      label_referral_receiver: 'Referral receiver',
      label_service_cat: 'Service category',
      label_required_visit_id: 'Visit ID is required',
      label_run_number: 'Run Number',
      label_required_run_number: 'Run Number is required',
      label_run_number_contain: 'Run Number should contain minimum 12 characters',
      label_required_date: 'Date is required',
      label_required_time: 'Time is required',

      label_license_number: 'License Number',
      label_npi_grid: 'NPI',
      label_modcdes: 'MOD-CDES',
      label_prescribe_ind: 'Prescribe-IND',
      label_dispensingInd: 'Dispensing-IND',
      label_doctor_speciality_name: 'Doctor Speciality Name',
      label_ac_SpO2_test: 'Air condition during SpO2 test',
      label_supplemental_oxygen: 'Supplemental oxygen',
      label_left_lung_sounds: 'Left lung sounds',
      label_right_lung_sounds: 'Right lung sounds',


      // Online Referral
      label_referral_info: 'Referral Information',
      label_ref_reson: 'Reason for referral',
      label_person_name: 'Person Name',
      label_additional_comments: 'Additional comments',
      label_req_info: 'Requested more information',
      label_req_more_info: 'Request more information',
      label_rejection_reason: 'Rejection reason',
      label_rejection_notes: 'Rejection notes',
      label_vital_info: 'Vital Info',
      label_inch: 'Inch',
      label_feet: 'Feet',
      label_height_in_cm: 'Height (in cm): ',
      label_weight_in_kg: ' Weight (in kg):',
      label_bmi: 'BMI:',
      label_degree_f: 'degrees F',
      label_breath: 'breaths/min',
      label_blood_glucose_level: 'Blood Glucose Level',
      label_glucose_level: 'Glucose Level',
      label_glucose: 'Glucose',
      label_glucose_type: 'Blood Glucose Type',
      label_level_of_consciousness: 'Level of Consciousness (AVPU)',
      label_glassgow_coma_scale: 'Glasgow Coma Scale (GCS)',
      label_vital_signs: 'Vital Signs',
      upload_patient_documents: 'Upload Patient Document',
      online_referrals: 'Online Referrals',
      referral_accepted: 'Referral Accepted',
      referral_rejected: 'Referral Rejected',
      other_chronic_condition: 'Other Chronic Condition',
      reason_online_referral_rejection: 'Please share reason for online referral rejection',
      reason_dispatch_request_rejection: 'Please share reason for dispatch request rejection',
      request_more_information: 'Request more information about this referral',
      patient_may_already_exist: 'Patient may already exist',
      current_patient: 'Current Patient',
      possible_duplicates: 'Possible Duplicates',
      rejection_reason_is_required: 'Rejection reason is required',
      label_action_to_take: 'Action to take',
      label_create_new_patient: 'Create New Patient',
      label_program_to_enroll: 'Program to enroll',
      referral_create_new_patient_text: 'Are you sure to create a new patient for this referral?',
      referral_link_new_patient_text: 'Are you sure you want to link this new referral?',
      label_reject_with_creating_patient: 'Reject With Creating Patient',
      label_add_eew_referral: 'Add New Referral',

      // menu handles
      menu_bookings: 'Bookings',
      menu_corporate_bookings: 'Corporate Bookings',
      menu_hotel_bookings: 'Hotel Bookings',
      menu_sales_quotation: 'Sales Quotations',
      menu_admin_dashboard: 'Dashboard',
      menu_corporate_dashboard: 'Corporate Dashboard',
      menu_passengers: 'Passengers',
      menu_dispatchers: 'Dispatchers',
      menu_vehicles: 'Vehicles',
      menu_drivers: 'Providers',
      menu_staff_transport: 'Staff Transport',
      menu_hotels: 'Hotels',
      menu_corporates: 'Corporates',
      menu_app_settings: 'App Settings',
      menu_settings: 'Settings',
      menu_notifications: 'App Notifications',
      menu_reports: 'Reports',
      menu_corporate_reports: 'Corporate Reports',
      menu_corporate_approval: 'Corporate Approval',
      menu_corporate_pending_approval: 'Pending for Approval',
      menu_corporate_manage_employee: 'Manage Employees',
      menu_corporate_manage_departments: 'Manage Departments',
      menu_corporate_settings: 'Corporate Settings',
      menu_manage_cities: 'Manage Cities',
      lebel_health_plan: 'Health Plan',
      lebel_health_plan_id: 'Health Plan ID',


      msg_success_summary: 'Success Message',
      msg_error_summary: 'Error Message',
      msg_warning_summary: 'Warning Message',
      msg_info_summary: 'Info Message',


      // app settings access
      access_get_privacy_policy: 'Get Privacy Policy',
      access_get_contact_support: 'Get Contact Support',
      access_get_rental_policy: 'Get Rental Policy',
      access_getChauffeur_policy: 'Get Chauffeur Policy',
      access_update_about_us: 'Update About Us',
      access_update_terms_and_conditions: 'Update Terms And Conditions',
      access_get_tenant_by_id: 'Get Tenant By ID',
      access_add_faq: 'Add FAQ',
      access_update_faq: 'Update FAQ',
      access_get_faq: 'Get FAQs',
      access_add_partner: 'Add Partner',
      access_update_partner: 'Update Partner',
      access_update_privacy_policy: 'Update Privacy Policy',
      access_delete_partner: 'Delete Partner',
      access_get_partners: 'Get Partners',
      acces_get_service_types: 'Get Service Types',
      access_get_labels: 'Get Labels',
      access_get_service_area: 'Get Service Area',
      access_get_terms_and_conditions: 'Get Terms And Conditions',
      access_get_about_us: 'Get About Us',
      access_udpate_rental_policy: 'Update Rental Policy',
      access_update_chauffer_policy: 'Update Chauffeur Policy',

      // Bookings accesses
      access_get_corporate_booking_grid: 'Get Corporate Bookings Grid',
      access_cancel_trip: 'Cancel Appointment',
      access_send_verification_code_to_passenger: 'Send Verification Code To Passenger',
      access_get_booking_bill_details: 'Get Booking Bill Details',
      access_add_driver_trip_emergency: 'Add Provider Appointment Emergency',
      access_add_passenger_trip_emergency: 'Add Passenger Appointment Emergency',
      access_get_reasons_by_type: 'Get Reasons By Type',
      access_get_corporate_pending_approval_grid: 'Get Corporate Pending Booking Approvals Grid',
      access_approve_booking: 'Approve Booking',
      access_get_booking_estimation: 'Get Booking Estimation',
      access_get_booking_details_by_trip_id: 'Get Booking Details By TripId',
      access_extend_booking_package: 'Extend Booking Package',
      access_udpate_rental_booking_security_checks: 'Update Rental Booking Security Checks',
      access_end_rental_booking: 'End Rental Booking',
      access_get_user_active_bookings: 'Get User Active Bookings',
      access_bookings_export_to_excel: 'Bookings Export To Excel',
      access_add_passenger_trip_rating: 'Add Passenger Appointment Rating',
      access_add_driver_trip_rating: 'Add Provider Appointment Rating',
      access_add_booking: 'Add Booking',
      access_add_corporate_booking: 'Add Corporate Booking',
      access_add_rental_booking: 'Add Rental Booking',
      access_add_chauffer_booking: 'Add Chaueffer Booking',
      access_get_passenger_bookings_list: 'Get Passenger Bookings List',
      access_get_bookings_by_user_type: 'Get Bookings By User Type',
      access_get_booking_grid: 'Get Booking Grid',
      access_bookings_export_to_pdf: 'Bookings Export To pdf',
      access_get_booking_details: 'Get Booking Details',
      access_cancel_booking: 'Cancel Booking',
      access_assign_driver: 'Assign Provider',
      access_acknowledge_booking_by_driver: 'Acknowledge Booking By Provider',
      access_accept_trip: 'Accept Appointment',
      access_start_trip: 'Start Appointment',
      access_end_trip: 'End Appointment',
      access_update_booking_bill_cash: 'Update Booking Bill Cash',
      access_update_booking_bill_credit_note: 'Update Booking Bill Credit Note',
      access_update_booking_driver_arrived: 'Update Booking Provider Arrived',
      access_add_trip_comment_reply: 'Add Appointment Comment Reply',
      access_get_note_by_trip_id: 'Get Note By TripId',
      resend_notification_message: 'Do you really want to resend the notification?',
      delete_faq: 'Are you sure you want to delete the FAQ?',
      delete_partner: 'Are you sure you want to delete this partner?',
      action_note1: 'Are you sure you want to ',
      corporate_action_note2: ' this Corporate?',
      dispatcher_action_note2: '  this dispatcher ?',
      invalid_corporate_message: 'Unable to load corporate details',
      edit_company_info: 'Edit Company Info',

      // corporate booking approval
      access_get_pending_approvals_grid: 'Get Pending Approvals Grid',
      access_approva_reject_corp_booking: 'Approve/Reject Corporate Booking',
      access_get_Corporate_booking_details: 'Get Corporate Booking Details',
      access_get_charge_codes_for_corporate: 'Get Charge Codes For Corporate',
      access_get_corporates_and_dept: 'Get Corporates And Departments',
      // corporate dashboard
      access_get_emergency_details: 'Get Emergency Details',
      access_get_corp_dash_emer_grid: 'Corporate Dashboard Emergencies Grid',
      access_get_corp_dash_graph: 'Get Corporate Dashboard Graph Data',
      access_get_corporate_dash: 'Get Corporate Dashboard Data',
      // corporate
      access_corp_count: 'Corporate Count',
      access_corp_search: 'Search Corporate',
      access_member_search: 'Search Patient',
      access_member_search_id: 'Search Patient by ID',
      access_member_search_neme: 'Search Patient by Name',
      access_driver_search_neme: 'Search Provider by Name',
      access_corp_export_to_pdf: 'Corporate Export To PDF',
      access_corp_export_to_xls: 'Corporate Export To Excel',
      access_corp_activate: 'Activate Corporate',
      access_corp_and_dept: 'Get Corporates And Departments',
      access_corp_dept_by_code: 'Get Corporate Departments By Corporate Code',
      access_corp_add: 'Add Corporate',
      access_corp_details: 'Get Corporate Details',
      access_corp_update: 'Update Corporate',
      access_corp_grid: 'Get Corporate Grid',
      access_corp_booking_history: 'Corporate Booking History',
      access_member_search_id_or_name: 'Search Patient by ID',
      // corporate settings
      access_corp_setting_get_charge_code: 'Get Charge Codes For Corporate',
      access_corp_setting_charge_code_grid: 'Charge Code Grid',
      access_corp_setting_add_chage_code: 'Add Charge Code',
      access_corp_setting_routes_grid: 'Routes Grid',
      access_corp_setting_partner_packge_grid: 'Partner Packages Grid',
      // dashboard
      access_admin_dash_emer_and_booking_details: 'Emergency and booking details',
      access_admin_dash_emer_grid: 'Emergency Grid',
      access_admin_dash_get: 'Get Dashboard Data',
      access_admin_dash_get_graph: 'Get Dashboard Graph Data',
      label_manual_bookings: 'Manual Bookings',
      label_manual_booking_count: 'Total Manual Bookings',
      label_app_bookings: 'App Bookings',
      label_count_app_bookings: 'Total App Bookings',
      label_first_visited: 'First Visited',
      label_enrolled_days: 'Enrolled Days',
      label_last_visited: 'Last Visited',
      label_last_visit_date_after: 'Last Visit Date After',
      label_last_visit_date_before: 'Last Visit Date Before',
      label_enrollment_date: 'Enrollment Date',
      label_graduation_date: 'Graduation Date',
      label_enrollment_score: 'Enrollment Score',
      label_graduation_score: 'Graduation Score',
      label_difference: 'Difference',
      label_average_peat_score: 'Average PEAT score',
      label_graduation: 'Graduation',
      label_enrollment: 'Enrollment',
      label_appointment_alerts: 'Appointment Alerts',
      label_care_tasks: 'Care Tasks',
      label_care_task: 'Care Task',
      label_open_visits: 'Open Visits',
      label_visits: 'Visits',
      label_utilized_med_review_sessions: 'Utilized med-review sessions',
      label_date_range_due: 'Date range (Due)',
      label_care_tasks_hyphen: 'Care-tasks',
      label_care_task_hyphen: 'Care-task',
      label_update_tasks: 'Update Tasks',
      label_follow_ups: 'Follow-ups',
      label_assigned_to: 'Assigned to',
      label_due_by: 'Due by',
      label_complete: 'Complete',
      // dispatchers
      access_disp_get_details: 'Get Dispatcher Details',
      access_disp_update: 'Update Dispatcher',
      access_disp_export_to_pdf: 'Dispatchers Data For Export To PDF',
      access_disp_export_to_xls: 'Dispatchers Data For Export To Excel',
      access_disp_get_count: 'Get Dispatchers Count',
      access_disp_grid: 'Dispatchers Grid',
      access_disp_search: 'Search Dispatchers',
      access_disp_get_booking_history: 'Get Dispatcher Booking History',
      access_disp_get_roles: 'Get Dispatcher Roles',
      access_disp_activate_deactivate: 'Activate/Deactivate Dispatcher',
      access_disp_add: 'Add Dispatcher',
      // providers
      access_driver_get: 'Get Provider Info',
      access_driver_update: 'Update Provider',
      access_driver_search: 'Search Provider',
      access_driver_activate_deactivate: 'Activate/Deactivate Provider',
      access_driver_grid: 'Providers Grid',
      access_driver_export_to_xls: 'Providers Export To Excel',
      access_driver_export_to_pdf: 'Providers Export To PDF',
      access_driver_get_count: 'Get Providers Count',
      access_driver_get_vehicle_and_assigne: 'Get Vehicle Assigned To Provider',
      access_driver_update_duty_status: 'Update Provider Duty Status',
      access_driver_get_near_by: 'Get Near By Providers',
      access_driver_get_booking_history: 'Get Provider Booking History',
      access_driver_get_location: 'Get Location Of Provider',
      access_driver_get_docs_types: 'Get Provider Document Types',
      access_driver_add: 'Add Provider',
      label_service_cities: 'Service Cities',
      label_service_city: 'Service City',
      label_shift_details_for: 'Shift Details for',
      label_no_shift_available: 'No shift available',
      error_npi_is_invalid: 'NPI Number is invalid',
      label_turn_on_Email_notification_text: 'This will turn on the Email service for this user. Please confirm.',
      label_turn_off_Email_notification_text: 'This will turn off the Email service for this user. User will receive critical email notifications like password reset. Please confirm.',
      label_turn_on_SMS_notification_text: 'This will turn on the SMS service for this user. Please confirm.',
      label_turn_off_SMS_notification_text: 'This will turn off the SMS service. User will receive critical SMS notifications like password reset. Please confirm.',

      // hotel bookings
      access_hotel_bookings_grid: 'Hotel Bookings Grid',
      access_hotel_bookings_add: 'Add Hotel Bookings',
      access_hotel_bookings_export_to_xls: 'Hotel Bookings Export To Excel',
      access_hotel_bookings_export_to_pdf: 'Hotel Bookings Export To PDF',
      access_hotel_bookings_list: 'Get All Hotels List',
      // hotels
      access_hotels_activate: 'Activate/Deactivate Hotel',
      access_hotels_booking_grid: 'Get Hotel Booking History Grid',
      access_hotels_grid: 'Hotels Grid',
      access_hotels_export_to_xls: 'Hotels Export To Excel',
      access_hotels_export_to_pdf: 'Hotels Export To PDF',
      access_hotels_get_count: 'Get Hotels Count',
      access_hotels_search: 'Search Hotel',
      access_hotels_add: 'Add Hotel',
      access_hotels_details: 'Get Hotel Details',
      access_hotels_update: 'Update Hotel',
      // manage departments
      access_dept_update: 'Update Department',
      access_dept_add: 'Add Department',
      access_dept_export_to_xls: 'Departments Grid Export To Excel',
      access_dept_grid: 'Departments Grid',
      access_dept_search: 'Search Employee',
      // manage employee
      access_emp_add: 'Add Employee',
      access_emp_trip_history_grid: 'Employee Appointment History Grid',
      access_emp_set_vip: 'Set Employee As VIP',
      access_emp_reject: 'Reject Employee',
      access_emp_approve: 'Approve Employee',
      access_emp_export_to_xls: 'Employees Grid Export To Excel',
      access_emp_activate: 'Activate/Deactivate Employee',
      access_emp_for_pending_approval: 'Get Employee For Pending Approval',
      access_emp_details: 'Get Employee Details',
      access_emp_udpate: 'Update Employee',
      access_emp_grid: 'Employees Grid',

      // notifications
      access_noti_send: 'Send Notification',
      access_noti_delete_of_user: 'Delete Notifications Of User',
      access_noti_delete: 'Delete Notification',
      access_noti_mark_read: 'Mark Notification Read',
      access_noti_send_status: 'Send Notification Status',
      access_noti_get_list: 'Get Notifications List',

      // passengers
      access_passengers_get_details: 'Get Passenger Details',
      access_passengers_get_trip_history: 'Get Appointment History For Passenger',
      access_passengers_get_by_type: 'Get Passengers By Type',
      access_passengers_search: 'Search Passengers',
      access_passengers_grid: 'Passenger Grid',
      access_passengers_get_count: 'Get Passenger Count',
      access_passengers_export_to_xls: 'Passenger Data Export To Excel',
      access_passengers_export_to_pdf: 'Passenger Data Export To PDF',
      access_passengers_activate: 'Active/Deactive Passenger',
      // sales quotations
      access_sales_quo_accept_reject: 'Accept/Reject Sales Quotation',
      access_sales_quo_upload: 'Upload Sales Quotation',
      access_sales_quo_details: 'Sales Quotation Details',
      access_sales_quo_grid: 'Sales Quotations Grid',
      access_sales_quo_cancel: 'Cancel Sales Quotation',
      access_sales_quo_export_to_xls: 'Sales Quotations Export To Excel',
      access_sales_quo_export_to_pdf: 'Sales Quotations Export To PDF',
      access_sales_quo_view_quotations_details: 'View Quotation Details',

      // staff transport
      access_staff_update: 'Update Staff Transport',
      access_staff_activate: 'Activate/Deactivate Company',
      access_staff_get_drop_points: 'Get Staff Transport Vehicle Drop Points',
      access_staff_add: 'Add Staff Transport',
      access_staff_grid: 'Staff Transport Grid',
      access_staff_details: 'Get Staff Transport Details',
      access_staff_activate_vehicle: 'Activate/Deactivate Staff Transport Vehicle',
      access_staff_count: 'Get Staff Transport Count',
      access_staff_add_booking: 'Add Booking For Staff Transport',
      access_staff_export_to_xls: 'Staff Transport Export To Excel',
      access_staff_export_to_pdf: 'Staff Transport Export To PDF',
      access_staff_update_status: 'Update Staff Transport Status',
      access_staff_renew_package: 'Renew Staff Transport Package',

      // vehicles
      access_vehicle_get_by_type: 'Get Vehicle By Vehicle Type',
      access_vehicle_get_types: 'Get Vehicle Types',
      access_vehicle_get_service_type: 'Get Service Types',
      access_vehicle_activate: 'Activate/Deactivate Vehicle',
      access_vehicle_grid: 'Vehicles Grid',
      access_vehicle_get_docs_types: 'Get Vehicle Document Types',
      access_vehicle_add: 'Add Vehicle',
      access_vehicle_get_count: 'Get Vehicle Counts',
      access_vehicle_update: 'Update Vehicle',
      access_vehicle_get_details: 'Get Vehicle Details',
      access_vehicle_export_to_xls: 'Vehicles Data Export To Excel',
      access_vehicle_export_to_pdf: 'Vehicles Data Export To PDF',
      access_vehicle_search: 'Search Vehicle',
      access_vehicle_near_by_vehicles: 'Get Near By Vehicles',
      access_vehicle_get_driver_info_by_vehicle: 'Get Provider Info By Vehicle',
      access_vehicle_get_near_by_vehicles_by_area: 'Get Near By Vehicles By Area',
      access_vehicle_booking_history_grid: 'Vehicle Booking History Grid',
      access_vehicle_add_type: 'Add Vehicle Type',
      access_vehicle_update_type: 'Update Vehicle Type',
      access_vehicle_type_grid: 'Vehicle Types Grid',
      access_vehicle_activate_type: 'Activate/Deactivate Vehicle Type',
      cnf_deactivate_city_then_all_driver_not_receive_request:
        'If you deactivate city then all providers attached to this city will not receive any appointment requests',
      cnf_realy_want_to: 'Are you sure you want to ',
      cnf_this_area: ' this area?',
      cnf_this_company: ' this company?',
      cnf_this_region: ' this region?',

      // cities
      label_city_name: 'City Name',
      lable_alias_name: 'Alias Name',
      label_add_city: 'Add City',
      label_city_name_msg: 'City Name required',
      label_alias_msg: 'Alias Name required',
      label_last_hour: 'Last Hour',
      label_last_4_hours: 'Last 4 Hours',
      label_current_day: 'Current Day',
      label_last_7_days: 'Last 7 Days',
      label_last_15_days: 'Last 15 Days',
      label_last_30_days: 'Last 30 Days',
      label_from_custom_date: 'Custom Date',
      label_today: 'Today',
      label_yesterday: 'Yesterday',
      label_this_month: 'This Month',
      label_last_month: 'Last Month',
      label_export: 'Export',
      label_total_trips_cancelled_by_drivers: 'Total Appointments Cancelled By Providers',
      label_cancelled_by: 'Cancelled By',
      label_total_trips: 'Total Appointments',
      label_trips: 'Appointments',
      label_total_trips_cancelled_by_passengers: 'Total Appointments Cancelled By Passengers',
      label_total_drivers_on_platform: 'Total Providers On Platform',
      label_drivers_received_trips: 'Providers Received Appointments',
      label_total_drivers_payout: 'Total Providers Payout',
      label_total_platform_commission: 'Total Platform Commission',
      label_total_receivable_from_drivers: 'Total Receivable From Providers',
      label_total_promo_discount: 'Total Promo Discount',
      label_total_refunded_amount: 'Total Refunded Amount',
      label_view_report: 'View Report',
      label_role_name: 'Role Name',
      role_name_pattern: 'Invalid role name',
      label_role_code: 'Role Code',
      label_user_code: 'User Code',
      label_user_name: 'User Name',
      label_tax_name: 'Tax Name',
      label_tax_percentage: 'Tax Percentage',
      label_free_distance: 'Free Distance',
      label_free_distance_placeholder: 'Free Distance (KM) *',
      free_dist_req: 'Free distance is required',
      label_per_distance_fare: 'Per Miles Fare',
      label_per_distance_fare_placeholder: 'Per KM Fare',
      label_per_Mile_fare: 'Per Mile Fare',
      label_per_min_fare: 'Per min. Fare',
      label_per_min_fare_placeholder: 'Per min. Fare *',
      label_driver_percentage: 'Provider Percentage',
      label_driver_percentage_placeholder: 'Provider Percentage *',
      label_contactNumber: 'Contact Number',
      label_supportEmail: 'Support Email',
      label_minimum_fare: 'Minimum Fare',
      label_minimum_fare_placeholder: 'Minimum Fare *',
      minimum_fare_req: 'Minimum fare is required',
      label_referrer_benefit: 'Referrer Benefit',
      label_referee_benefit: 'Referee Benefit',
      have_promo_code: 'Have A Promotional Code?',
      label_manual_booking: 'Manual Booking',
      label_book_now: 'Book Now',
      label_book_ride_now: 'Book Ride Now',
      label_book_ride_later: 'Book Ride Later',
      label_book_ride_share: 'Book Ride Share',
      label_ride_share: 'Ride Share',
      label_car_details: 'Car Details',
      label_trip_details: 'Appointment Details',
      label_car_type_with_colon: 'Car Type :-',
      label_model_with_colon: 'Model Name :-',
      label_vehicle_make_with_colon: 'Vehicle Make :-',
      label_color_with_colon: 'Color :-',
      label_vehicle_type_with_colon: 'Vehicle Type :-',
      label_plate_number_with_colon: 'Plate Number :-',
      label_status_with_colon: 'Status',
      label_email_text: 'Email',
      label_color: 'Color',
      label_refund: 'Refund',
      label_rider_details: 'Rider Details',
      label_vehicle_details: 'Vehicle Details',
      label_rating_given_by_driver: 'Ratings given by provider',
      label_rating_given_by_passenger: 'Ratings given by passenger',
      label_fare_breakdown: 'Fare Breakdown',
      label_trip_statistics: 'Appointment Statistics',
      label_distance_fare_text: 'Distance fare',
      label_time_fare: 'Time fare',
      label_total: 'Total',
      label_duration: 'Duration',
      label_total_time: 'Total time',
      label_email_invoice: 'Email Invoice',
      label_invoice_receipt: 'Invoice Receipt',
      label_cash_collected: 'Cash Collected',
      label_profile_password: 'Profile Password',
      label_edit_profile: 'Edit Profile',
      label_password: 'Password',
      label_profile_password_required: 'Profile password is required',
      label_invalid_amount: 'Invalid amount',
      refund_amount_required: 'Refund amount is required',
      label_free_cars: 'Free Vehicles',
      label_busy_cars: 'Busy Vehicles',
      label_idle_cars: 'Idle Vehicles',
      label_no_nearby_driver: 'It seems there are no nearby providers available',
      label_tour_time_fare: 'Appointment Time Fare',
      label_enroute_waiting_fare: 'Enroute Waiting Fare',
      label_manage_taxes: 'Manage Taxes',
      label_add_tax: 'Add Tax',
      label_update_tax: 'Update Tax',
      label_app_store_url: 'App Store URL',
      label_play_store_url: 'Play Store URL',
      label_facebook_url: 'Facebook URL',
      label_twitter_url: 'Twitter URL',
      label_google_plus_url: 'Google Plus URL',
      label_number_of_seats: 'Number Of Seats',
      label_number: 'Number',
      label_additional_seat_fare: 'Additional Seat Fare',
      label_ride_share_service: 'Ride Share Service',
      label_profile_updated_successfully: 'User profile is updated Successfully',
      label_load_more_notifications: 'Load More Notifications',
      label_show_more: 'Show More..',
      label_load_more: 'Load More',
      label_load_more_uppercase: 'LOAD MORE',
      label_map: 'View Map',
      label_heat_map: 'Heat Map',
      label_plateform_summary_report: 'Dashboard',
      label_data_charts: 'Data Charts',
      label_emergency: 'Emergency',
      label_driver_emergency: 'Provider Emergency',
      label_passenger_emergency: 'Passenger Emergency',
      label_member_emergency: 'Patient Emergency',
      label_by_drivers: 'By Providers',
      label_by_members: 'By Patients',
      label_new_request: 'New Request',
      label_attened: 'Attended',
      label_attend_it: 'Attend It',
      label_closed: 'Closed',
      label_close_it: 'Close It',
      label_request_status: 'Request Status',
      label_requested_form: 'Requested Form',
      label_requested_by: 'Requested By',
      label_arrange_vehicles: 'Arrange Vehicles',
      label_all_vehicle_type: 'All Vehicle Type',
      label_selected_vehicle_type: 'Selected Vehicle Type',
      label_company_name: 'Company Name',
      label_company: 'Company',
      company_pattern: 'Invalid company name',
      label_add_company: 'Add Company',
      label_edit_company: 'Edit Company',
      label_change_password: 'Change Password',
      label_appointment_type: 'Appointment Type',
      label_cancelled_trip_reason: 'Cancelled Appointment Reason',
      label_appointment_reason: 'Appointment Reason',
      label_member_requirment: 'Patient Requirement',
      label_member_details: 'Patient Details',
      label_additional_notes: 'Instructions',
      label_recurrence_pattern: 'Recurrence Pattern',
      label_range_of_recurrence: 'Range of recurrence',
      label_additional_notes_placeholder: 'Ex: Drive slow',
      label_old_password: 'Old Password *',
      label_new_password: 'New Password *',
      label_new_confirm_password: 'Confirm New Password *',
      label_otp_send_text: 'Please enter OTP which has been sent to your Email ID',
      label_add_user: 'Add User',
      label_edit_user: 'Edit User',
      label_add_program: 'Add Program',
      label_edit_program: 'Edit Program',
      label_add_operator: 'Add Operator',
      label_edit_operator: 'Edit Operator',
      label_operator_name: 'Operator Name',
      label_reset_password: 'Reset Password',
      label_select_no_of_seats: 'Select Number Of Seat(s)',
      label_vehicle_assigned: 'Vehicle Assigned',
      label_assigned: 'Assigned',
      label_non_assigned: 'Non-Assigned',
      label_unassigned: 'Unassigned',
      label_admin_settlement: 'Admin Settlement',
      label_promo_discount: 'Promo Discount',
      label_credits: 'Credits',
      label_percentage: 'Percentage',
      label_driver_amount: 'Provider Amount',
      label_date: 'Date',
      label_time: 'Time',
      label_bid_amount: 'Bid Amount',
      label_driver_status: 'Provider Status',
      label_passenger_status: 'Passenger Status',
      label_bidding_details: 'Bidding Details',
      label_total_invoice: 'Total Invoice',
      label_booking_fares: 'Booking Fare',
      label_total_distance: 'Total Distance',
      label_total_driver: 'Total Providers',
      label_active_driver: 'Active Providers',
      label_inactive_driver: 'Inactive Providers',
      label_offline_driver: 'Offline Providers',
      label_online_driver: 'Online Providers',
      label_doc_expiring: 'Docs Expiring In 7 Days',
      label_total_passengers: 'Total Passengers',
      label_online_passengers: 'Online Passengers',
      label_online_vehicles: 'Online Vehicles',
      label_total_dispatchers: 'Total Dispatchers',
      label_online_dispatchers: 'Online Dispatchers',
      label_total_hotels: 'Total Hotels',
      label_online_hotels: 'Online Hotels',
      label_total_companies: 'Total Companies',
      label_online_companies: 'Online Companies',
      label_total_corporates: 'Total Corporates',
      label_online_corporates: 'Online Corporates',
      label_ride_share_name: 'Ride Share Name',
      label_source_radius: 'Source Radius(miles)',
      label_destination_radius: 'Destination Radius(miles)',
      label_per: 'Per',
      label_source_radius_dynamic: 'Source Radius',
      label_destination_radius_dynamic: 'Destination Radius',
      label_no_of_drivers: 'No. of Providers',
      label_create_segment: 'Create Segment',
      label_create_notification: 'Create Notification',
      label_segment_name: 'Segment Name',
      label_segment: 'Segment',
      label_created_date_time: 'Created Date & Time',
      label_created_date_and_time: 'Created Date and Time',
      label_created: 'Created',
      label_contact_match: 'Contact match your condition',
      label_number_of_users: 'Number Of Users',
      label_role_type: 'Role Type',
      label_radius_area: 'KM *',
      label_near_by_driver: 'Near by Providers',
      label_user: 'User',
      label_segment_required: 'Segment Name is required',
      label_send_notification: 'Send Notification',
      label_sent_on: 'Sent On',
      label_received_on: 'Received On',
      label_message_type: 'Message Type',
      label_select_message_type: 'Select Message Type',
      label_message: 'Message',
      label_message_title: 'Message Title',
      label_title: 'Title',
      label_segment_list: 'Segment List',
      label_select_segment: 'Select Segment',
      label_add_new_segment: 'Add New Segment',
      label_message_description: 'Message Description',
      label_message_description_place: 'Message Description (Maximum characters limit: 150)',
      label_message_email_description_place: 'Message Description (Maximum characters limit: 150)',
      label_add_segment: 'Add Segment',
      mins_required: 'Minutes required',
      secs_required: 'Seconds required',
      label_promocode_pattern: 'Promocode should be in capital and numbers only',
      label_driver_information: 'Provider Information',
      label_vehicle_information: 'Vehicle Information',
      label_member_information: 'Patient Information',
      label_distance_travelled_miles: 'Distance Travelled',
      label_distance_travelled_miles_KM: 'Distance Travelled( KM )',
      label_fare_details: 'Fare Details',
      label_current_driver_online: 'Current Provider Online',
      label_valid_date_range: 'Please Select valid Date range for reports',
      label_offline: 'Offline',
      label_vehicle_make_model: 'Vehicle make & model',
      label_driver_required: 'Provider required',
      label_drop_trip: 'Drop Appointment',
      label_drop_time_required: 'Drop time required',
      label_total_time_in_minuites: 'Appointment Time',
      label_no_route_available: 'No Route Available',
      label_referral: 'Referral',
      label_referral_code: 'Referral Code',
      label_wallet_amount: 'Wallet Amount',
      label_trip_note: 'Appointment Note',
      label_add_note: 'Add Note',
      label_wallet_update: 'Wallet Update',
      label_amount_required: 'Amount required',
      label_amount_limit_exceeded: 'Amount limit exceeded',
      label_wallet: 'Wallet',
      label_referral_credit: 'Referral Credit',
      label_payment_type: 'Payment Type',
      label_tour_start_date_time: 'Tout Start Date & Time',
      label_tour_end_date_time: 'Tout End Date & Time',
      label_tour_status: 'Appointment Status',
      label_service_type_code: 'Service Type Code',
      lbl_credit_card_info: 'Card Info',
      label_sub_total: 'Sub Total',
      label_tax_amount: 'Tax Amount',
      label_grand_total: 'Grand Total',
      label_vehicle_condition: 'Vehicle condition',
      label_company_percentage: 'Company Percentage',
      label_company_percentage_placeholder: 'Company Percentage *',
      label_total_completed_trips: 'Total completed Appointments',
      label_total_invoice_amount: 'Total Invoice Amount',
      label_company_share: 'Company Share',
      label_admin_share: 'Admin Share',
      label_add_vehicle: 'Add Vehicle',
      label_add_driver: 'Add Provider',
      label_new_provider: 'New Provider',
      label_edit_provider: 'Edit Provider',
      label_remove_vehicle: 'Remove Vehicle',
      label_add_vehicle_description: 'Please select one or more vehicle(s)',
      label_view_assign_vehicle: 'View Assigned Vehicle',
      label_vehicle_not_found: 'No assigned vehicle found for this provider',
      label_draw_region_on_map: 'Please draw region on map.',
      label_select_valid_app_user: 'Please select valid app user.',
      label_fillup_guest_user_details: 'Please fill up guest user details.',
      label_select_driver_from_driver_list: 'Please select provider from provider list.',
      label_add_provider: 'Add Provider',
      label_provider_type: 'Provider Type',
      label_specialization: 'Specialization',
      label_market_nane: 'Market Name',
      label_market: 'Market',
      label_markets: 'Markets',
      label_primary_market: 'Primary Market',
      label_timezone: 'Timezone',
      label_default_timezone: 'Default Timezone',
      label_associated_markets: 'Associated Markets',
      label_saved_appointment_filter: 'Appointment filter is saved Successfully.',
      label_reset_appointment_filter: 'Appointment filter is reset Successfully.',
      label_close: 'Close',
      label_add_market: 'Add Market',
      label_update_market: 'Update Market',
      label_add_client: 'Add Client',
      label_update_client: 'Update Client',
      label_market_name: 'Market Name',
      label_patient_market: 'Patient Market',
      label_timezone_name: 'Timezone Name',
      label_abbr: 'Abbreviation',

      // Error messages
      invalid_input: 'Invalid input',
      min_length_phone_number: 'Phone number should contain minimum 10 digit',
      max_length_phone_number: 'Phone number should contain maximum 10 digit',
      max_address: 'Maximum 100 characters allowed',
      max_trip_node: 'Maximum 500 characters allowed',
      max_length_special_note: 'You have exceeded the maximum 200 character limit',
      max_number_10: 'You have exceeded the maximum 10 numbers limit',
      max_number_15: 'You have exceeded the maximum 15 numbers limit',
      max_number_20: 'You have exceeded the maximum 20 numbers limit',
      max_character_10: 'You have exceeded the maximum 10 character limit',
      max_character_20: 'You have exceeded the maximum 20 character limit',
      max_character_30: 'You have exceeded the maximum 30 character limit',
      max_character_19: 'You have exceeded the maximum character limit',
      max_character_50: 'You have exceeded the maximum 50 character limit',
      max_character_100: 'You have exceeded the maximum 100 character limit',
      max_character_200: 'You have exceeded the maximum 200 character limit',
      max_character_250: 'You have exceeded the maximum 250 character limit',
      max_character_300: 'You have exceeded the maximum 300 character limit',
      max_character_500: 'You have exceeded the maximum 500 character limit',
      max_character_err: 'You have exceeded the maximum character limit',

      file_upload_error: 'Entity is too large to upload',
      error_region_name_required: 'Region Name is required',
      error_country_code: 'Please select country code',
      error_alias_name_required: 'Alias Name is required',
      error_cities_required: 'City is required',
      error_service_area_required: 'Service area is required',
      error_base_fare_required: 'Base fare is required',
      error_invalid_base_fare: 'Invalid base fare',
      error_booking_fee_required: 'Booking fee is required',
      error_invalid_booking_fee: 'Invalid booking fee',
      error_ride_time_fare_required: 'Ride time fare is required',
      error_invalid_ride_time_fare: 'Invalid ride time fare',
      error_vehicle_required: 'Vehicle is required',
      error_about_us_required: 'About us is required',
      error_model_name_required: 'Model name is required',
      error_model_color_required: 'Model color is required',
      error_vehicle_no_required: 'License plate number is required',
      error_vehicle_reg_no_required: 'Vehicle Registration Number is required',
      error_document_id_required: 'Document ID is required',
      error_invalid_fax: 'Fax number is invalid',
      error_max_length_excced: 'Character max length exceed',
      error_email_id_required: 'Email is required',
      error_vehicle_make_required: 'Vehicle make is required',
      error_number_of_seats_required: 'Number of seats is required',
      error_invalid_number_of_seats: 'Invalid number of seats',
      error_additional_seat_fare_required: 'Additional seat fare is required',
      error_invalid_additional_seat_fare: 'Invalid additional seat fare',
      error_mode_required: 'Mode is required',
      error_usage_required: 'Usage is required',
      error_limit_of_uses_required: 'No of uses is required',
      error_company_required: 'Company is required',
      error_company_name: 'Company name is required',
      error_old_password_required: 'Old password is required',
      error_new_password_required: 'New password is required',
      error_new_confirm_password_required: 'Confirm new password is required',
      error_new_confirm_password_not_match: 'New and confirmation passwords do not match',
      error_account_confirm_account_not_match: 'Account number and confirm account number are not matched',
      error_similar_location_name: 'Pickup location and Drop location cannot be the same',
      error_from_date_required: 'From date is required',
      error_invalid_date: 'Invalid Date & time',
      error_quotation_amount: 'please enter quotation amount',
      error_quotation_note: 'Please enter quotation note',
      error_quotatin_file: 'please upload quotation',
      error_vehicle_type_required: 'Vehicle type is required',
      error_trip_type_required: 'Appointment type is required',
      error_member_required: 'Patient ID is required',
      error_invalid_patient_id: 'Invalid patient ID',
      error_invalid_question_key: 'Invalid Question key',
      error_appointment_type_required: 'Appointment type is required',
      error_select_one: 'Please select one',
      error_appointment_type_reason_required: 'Appointment reason is required',
      error_member_requirement_required: 'Please select one Patient',
      error_additional_note_required: 'Additional note is required',
      error_vehicle_name_required: 'Vehicle name is required',
      error_languageCode_required: 'Language is required',
      error_recurrence_week_max_min: 'Range must be 1 - 9',
      error_recurrence_max_min: 'Range must be 1 - 99',
      error_to_date_required: 'To date is required',
      error_invalid_access_group: 'No access group assigned to the user',
      error_fetch_meta_data: 'Error occured during fetching user meta data',
      error_invalid_email: 'Email is invalid',
      error_invalid_phone_number: 'Phone number is invalid',
      error_invalid_country_code: 'Country code is invalid',
      error_enroute_waiting_fare_required: 'Enroute waiting fare is required',
      error_invalid_enroute_waiting_fare: 'Enroute waiting fare is invalid',
      error_free_distance_required: 'Free distance is required',
      error_invalid_free_istance: 'Invalid free distance',
      error_source_radius_required: 'Source radius is required',
      error_invalid_source_radius: 'Source radius is invalid',
      error_destination_radius_required: 'Destination radius is required',
      error_invalid_destination_radius: 'Destination radius is invalid',
      error_ride_share_name_required: 'Ride share name is required',
      error_per_km_fare_required: 'Per km fare is required',
      error_invalid_per_km_fare: 'Invalid per km fare',
      error_per_miles_fare_required: 'Per miles fare is required',
      error_invalid_per_miles_fare: 'Invalid per miles fare',
      error_invalid_per_mile_mile_fare: 'Invalid per mile fare',
      error_per_minute_fare_required: 'Per min fare is required',
      error_invalid_per_minute_fare: 'Invalid per min fare',
      error_wait_time_fare_required: 'Wait time fare is required',
      error_invalid_wait_time_fare: 'Invalid wait time fare',
      error_cancellation_charges_required: 'Cancellation charges is required',
      error_invalid_cancellation_charges: 'Invalid cancellation charges',
      error_driver_percentage_required: 'Provider percentage is required',
      error_invalid_driver_percentage: 'Invalid provider percentage',
      error_minimum_fare_required: 'Minimum fare is required',
      error_invalid_minimum_fare: 'Invalid minimum fare',
      error_tour_time_fare_required: 'Appointment time fare is required',
      error_invalid_tour_time_fare: 'Invalid appointment time fare',
      error_vehicle_icon_required: 'Vehicle icon is required',
      error_role_type_required: 'Role type is required',
      error_status_required: 'Status is required',
      error_radius_required: 'Radius is required',
      error_radius_area_required: 'Radius area is required',
      error_description_required: 'Description is required',
      error_user_required: 'User is required',
      error_segment_list_required: 'Segment is required',
      error_message_type_required: 'Message type is required',
      error_message_title_required: 'Message type is required',
      error_message_description_required: 'Message description required',
      error_select_date_range: 'Please select date range',
      error_valid_parameter_to_view_export: 'Please select any valid parameter to view exported file',
      error_service_city_required: 'Service city is required',
      error_getting_platform_summery_data: 'Error occured during getting platform summary data',
      error_getting_heat_map_data: 'Error occured during getting heat map data',
      error_role_required: 'Role name is required',
      error_service_region_required: 'Service region name is required',
      error_first_name_required: 'First name is required',
      error_last_name_required: 'Last name is required',
      error_tax_name_required: 'Tax name is required',
      error_tax_percentage_required: 'Tax percentage is required',
      error_invalid_tax_percentage: 'Invalid tax percentage',
      error_per_Mile_fare_required: 'Per mile fare is required',
      error_password_required: 'Password is required',
      error_company_name_required: 'Company name is required',
      error_company_website_required: 'Company website is required',
      error_password_pattern: `Password should be minimum 8 characters and must include a combination of
      uppercase and lowercase alphabets, symbols and numbers.`,
      error_invalid_company_website: 'Invalid company website',
      error_invalid_website_url: 'Invalid website URL',
      error_address_1_required: 'Address 1 is required',
      error_vehice_is_requied: 'Vehicle is required',
      error_not_exist_page: 'The page you are looking is not exist. Please contact to your Admin.',
      error_max_provider_selection_limit: 'You can select upto maximum 5 providers.',
      error_market_name_required: 'Market name is required',
      error_client_required: 'Client is required',
      error_market_timezone_required: 'Timezone name is required',
      error_timezone_abbr_required: 'Abbreviation is required',
      error_mrn_required: 'MRN is required',
      max_50_char_allowed: 'Maximum 50 characters allowed',
      error_invalid_mrn: 'Invalid Medical Record Number',
      label_pu_addr: 'PU Addr',
      label_do_addr: 'DO Addr',
      label_pu_time_date: 'PU Time',
      label_appt_time: 'Appt time',
      label_estimated_distance_time: 'Estimated Distance And Time',
      label_trip_by_appointment_type: 'Appointments by Appointment Type',
      label_import_data: 'Import Appointment Data',
      label_import_providers: 'Import Providers',
      label_import: 'Import',
      label_import_provider_availability: 'Import Provider Availability',
      label_remote_provider_timeslot_availabile: 'Remote provider timeslot available.',
      label_onsite_provider_timeslot_availabile: 'Onsite provider timeslot available.',
      lbl_view_schedule: 'View Schedule',
      label_import_for: 'Import For',
      label_weeks_in_excel: 'Weeks in excel',
      label_import_till: 'Import till',
      label_appointment_ended: 'Appointment session ended successfully.',
      label_appointment_started: 'Appointment session started successfully.',

      label_new_sub_category: 'Add New Sub-Category',
      label_update_sub_category: 'Update Sub-Category Details',
      label_cat_name: 'Category Name',
      label_subcat_name: 'Sub-Category Name',
      cat_name_req: 'Category Name is required',
      subcat_name_req: 'Sub-Category Name is required',
      que_name_req: 'Question is required',
      label_new_form: 'Add New Form',
      label_update_form: 'Update Form',
      label_form_name: 'Form Name',
      form_name_req: 'Form Name is required',
      label_formtype: 'Form Type',
      label_form_color: 'Form Color',
      label_source_type: 'Source Type',
      label_enable_color: 'Enable Color',
      label_assignee: 'Assignee',
      select_category: 'Select Category',
      invalid_category_pattern: 'Invalid Category Name',
      invalid_subcategory_pattern: 'Invalid Sub-Category Name',
      invalid_form_pattern: 'Invalid Form Name',

      label_book_dual_appointment: 'Book Dual Appointment',
      label_confirm_dual_booking: 'Request added successfully',
      label_confirm_dual_booking_msg: 'Your appointment request is successfully added in the system. Please wait while the supervisor approves your request.',
      label_pending_approval_lbl: 'Pending Approval',
      label_pending_alerts_count: 'Pending dispatch request(s)',

      label_email_notifications: 'Email Notifications',
      label_sms_notifications: 'SMS Notifications',

      label_education_category_type: 'Category Type',
      label_patient_complete_by: 'Complete By',
      label_education_role_filter: 'Role',

      education_title_req: 'Education Material Title is required',
      education_type_req: 'Education Material Type is required',
      education_access_req: 'Education Material Access is required',
      education_link_req: 'Education Material Link is required',
      education_category_req: 'Education Material Category Type is required',
      invalid_youtube_link: 'Invalid youtube link',

      label_document_name: 'Document',
      label_document_sent_by: 'Sent By',
      label_document_role: 'Role',
      label_document_date: 'Date',
      label_document_time: 'Time',
      label_document_filetype: 'File Type',
      label_file_types: 'File types',
      error_file_type_required: 'File type is required',
      label_file_size: 'Allowed size (per file in MB)',
      error_file_size_required: 'File size is required',
      error_max_file_size: 'Max 25MB file size allowed',
      error_min_file_size: 'Min 1MB file size allowed',
      label_file_count: 'Max image count',
      error_file_count_required: 'Max image count is required',
      label_choose_document_file: 'Upload Document',
      label_document_date_and_time: 'Date & Time',
      label_visiblity: 'Visibility',

      label_preview: 'Preview',

      label_master_forms: 'Masters - Forms',
      label_screening: 'Screening',
      label_plan_of_Care: 'Plan of Care',
      label_assessment: 'Assessment',
      label_assessment_name: 'Assessment Name',
      label_feedback_Survey: 'Feedback and Survey',
      label_permissions: 'Permissions',
      label_admin: 'Admin',
      label_before_appointment: 'Before Appointment Creation',
      label_after_appointment: 'After Appointment Creation',
      label_instructions: 'Instructions',
      label_form_description: 'Description',
      label_patient: 'Patient',
      label_remote_provider: 'Remote Provider',
      label_onsite_provider: 'Onsite Provider',
      label_form_assignee: 'Assignee',
      label_staff_member: 'Staff Member',
      label_questions: 'Questions',
      label_add_new_question: 'Add New Question',
      label_add_new_section: 'Add New Section',
      label_question_txt: 'Question',
      label_program_type: 'Program Type',
      label_patient_condition: 'Patient Condition',
      label_applicable_event: 'Applicable Event',
      label_section_name: 'Section Name',
      label_section_alignment: 'Section alignment',
      label_independent: 'Independent',
      label_appointment: 'Appointment',
      label_select_appointment_type: 'Appointment Type',
      label_add_option: 'Add Option',
      label_add_other: 'Add Other',
      label_additional_note: 'Additional Notes',
      label_add_care_task: 'Add New Care-task',
      label_or_txt: 'Or',
      label_to_txt: 'to',
      label_grid_rows: 'Rows',
      label_grid_columns: 'Columns',
      label_add_rows: 'Add Row',
      label_add_columns: 'Add Column',
      label_subjective: 'Subjective Questions',
      label_select_date: 'Select Date',
      label_form_description_footer: 'Description Footer',
      label_forms_details: 'Forms Details',
      form_title_req: 'Form name required',
      form_type_req: 'Form type required',
      appointment_type_req: 'Appointment type required',
      label_add_new_screening: 'Add New Screening',
      label_section_order_index: 'Order Index',
      label_order_index: 'Order Index',
      label_form_order_index: 'Form Order Index',
      label_placeholder_text: 'Placeholder Text',
      label_info_text: 'Info Text',
      label_question_key: 'Question key',
      label_report_column_title: 'Report column title',
      label_reportable: 'Reportable',
      error_invalid_heightft: 'Please enter valid details in Height field',
      error_invalid_heightIn: 'Please enter valid details in Inch field',
      section_name_req: 'Section name is required',
      order_index_req: 'Order Index is required',
      question_name_req: 'Question is required',
      option_req: 'Option is required',
      error_input_field_title_req: 'Input field title required',
      option_min_req: 'Min value required',
      option_max_req: 'Max value required',
      min_option_create_limit: 'Minimum 2 options required',
      max_option_create_limit: 'Maximum 30 options allowed',
      min_peat_value: 'Minimum 0 value required',
      max_peat_value: 'Maximum 99 value allowed',
      error_bp: 'Please enter valid blood pressure value',
      error_boby_temp: 'Body Temperature is incorrect; the value should be between 95 and 110',
      error_resp_rate: 'Respiration Rate is incorrect; the value should be between 4 and 200',
      error_spo2: 'SpO2 is incorrect; the value should be between 0 and 100',
      error_glasgow_coma_scale: 'Glasgow Coma Scale is incorrect; the value should be between 3 and 15',
      label_group_id: 'Group ID',
      label_ct_group: 'Care-task group',
      label_ct_group_name: 'Care-task group name',
      label_online_referral_patient_name: 'Patient Name',
      label_online_referral_phone_number: 'Phone Number',
      label_online_referral_date_of_birth: 'Date of Birth',
      label_online_referral_chronic_condition: 'Chronic Condition',
      label_online_referral_submitted_on: 'Submitted On',
      label_download: 'Download',
      referral_status: 'Referral Status',
      label_organization: 'Organization',
      label_referral_email: 'Referral Email',
      label_referral_phone: 'Referral Phone Number',
      label_referring_person: 'Referring Person',
      label_save_column_filter: 'Selected Column is saved Successfully.',
      label_plan: 'Plan',
      placeholder_enter_description: 'Please enter description',
      placeholder_enter_assessment: 'Please enter assessment',
      placeholder_enter_plan: 'Please enter plan',
      extension_invalid: 'Invalid extension code',
      placeholder_Extension: 'Ext',
      label_manage_data_sources: 'Masters - Manage Data Sources',
      label_data_source_heading: 'Data-Source Type',
      label_data_source_required: 'Data-Source Type is required',
      label_data_source_description: 'Data-Source Type Description',
      label_data_source_item_description: 'Data-Source Item Description',
      label_data_source_description_required: 'Data-Source Type Description is required',
      label_add_data_source: 'Add Data-Source',
      label_edit_data_source: 'Update Data-Source',
      label_add_data_source_item: 'Add Data-Source Item',
      label_edit_data_source_item: 'Update Data-Source Item',
      label_data_source_item_heading: 'Data-Source Item',
      label_data_source_item_color: 'Data-Source Item Color',
      label_data_source_item_required: 'Data-Source item is required',
      label_data_source_item_color_required: 'Data-Source item color is required',
      label_search_by_data_source_type: 'Search by Data-Source Type',
      label_search_by_data_source_item: 'Search by Data-Source Item',
      label_overwrite_visit: 'Overwrite Visit',



      // Shailesh mi-translate
      label_messages: 'Messages',
      label_connecting_to_websocket: 'Connecting to websocket...',
      label_new_chat: 'New Chat',
      label_patient_enroll: 'Patient Enroll',
      label_messages_patient_graduated_text: 'As this patient has been graduated, further communication is restricted by the system.',
      label_signatures: 'Signatures',
      label_patients: 'Patients',
      label_assign_care_tasks_to_provider: 'Assign Care-tasks to provider',
      error_care_task_name_required: 'Care-task name is required',
      error_no_white_space_allowed: 'No White Space Allowed',
      label_assign_to_provider: 'Assign To Provider',
      error_provider_is_required: 'Provider is required',
      error_due_date_required: 'Due date is required',
      error_topic_required: 'Topic is required',
      label_assign_care_task: 'Assign Care-task',
      label_add_new_internal_care_team_member: 'Add New Internal Care Team Member',
      label_update_internal_care_team_member: 'Update Internal Care Team Member',
      label_add_new_external_care_team_member: 'Add New External Care Team Member',
      label_update_external_care_team_member: 'Update External Care Team Member',
      label_select_care_team_member: 'Select Care Team Member',
      error_care_type_required: 'Care Type is required',
      error_invalid_care_type: 'Invalid Care Type',
      label_point_of_contact: 'Point of contact',
      error_visit_type_required: 'Visit Type required',
      label_encounter_location: 'Encounter Location',
      label_this_patient_is_homeless: 'This patient is homeless.',
      label_monitor_type: 'Monitor Type',
      label_cuff_size: 'Cuff size',
      label_side: 'Side',
      label_position: 'Position',
      label_systolic_bp_unit: 'Systolic BP (mm/Hg)',
      label_systolic_bp: 'Systolic BP',
      label_diastolic_bp_unit: 'Diastolic BP (mm/Hg)',
      label_diastolic_bp: 'Diastolic BP',
      label_phq2_score: 'PHQ-2 Score',
      label_phq9_score: 'PHQ-9 Score',
      label_ASCVD_risk_score: 'ASCVD Risk Score',
      label_ASCVD_risk_calculator: 'ASCVD Risk Calculator',
      label_blood_pressure: 'Blood Pressure',
      label_blood_pressure_unit: 'mmHg',
      label_blood_pressure_readings: 'Blood Pressure Readings',
      label_right_left_arm: 'Right/left arm',
      label_blood_glucose_reading: 'Blood Glucose Reading',
      label_notes_diabetes: 'Notes (Diabetes)',
      label_notes_dyslipidemia: 'Notes (Dyslipidemia)',
      label_statin: 'Statin',
      lavel_is_patient_on_statins: 'Is patient on statins?',
      label_follow_up: 'Follow-up',
      label_follow_up_description: 'Follow-up description',
      error_follow_up_description_required: 'Follow-up description is required',
      label_licence_number: 'Licence Number',
      label_primary_care_provider: 'Primary Care Provider',
      label_doctor_specialty: 'Doctor specialty',
      label_request_patient_feedback: 'Request Patient Feedback',
      label_select_feedback_form: 'Select Feedback Form',
      label_request_feedback: 'Request Feedback',
      label_add_new_lab_test: 'Add New Lab Test',
      label_lab_date: 'Lab Date',
      label_lab_type: 'Lab Type',
      label_select_medications_for_MRP: 'Select Medications for the MRP',
      label_add_medication: 'Add Medication',
      label_update_medication: 'Update Medication',
      label_medications_details: 'Medication Details',
      label_medication: 'Medication',
      label_medications: 'Medications',
      label_comments: 'Comments',
      error_comment_required: 'Comment is required',
      label_load_previous_medications: 'Load Previous Medications',
      error_medication_name_required: 'Medication Name is required',
      label_SIG: 'SIG',
      error_SIG_required: 'SIG is required',
      label_indication: 'Indication',
      error_indication_required: 'Indication is required',
      label_prescriber_name: 'Prescriber name',
      label_prescriber: 'Prescriber',
      error_prescriber_name_required: 'Prescriber name is required',
      label_brand: 'Brand',
      label_medication_brand: 'Medication Brand',
      label_strength: 'Strength',
      label_form: 'Form',
      label_route: 'Route',
      error_route_required: 'Route is required',
      label_dose: 'Dose',
      error_dose_required: 'Dose is required',
      label_newly_administered: 'Newly administered',
      label_was_an_IV_line_started_during_this_visit: 'Was an IV line started during this visit?',
      label_IV_started_on: 'IV started on',
      error_IV_started_on_date_required: 'IV started on Date is required',
      error_IV_started_on_time_required: 'IV started on Time is required',
      label_number_of_IV_attempts: 'Number of IV attempts',
      label_size_of_IV_line: 'Size of IV line',
      label_location_of_IV_line: 'Location of IV line',
      error_location_of_IV_line_required: 'Location of IV line is required',
      label_purpose_of_starting_IV: 'Purpose of starting IV',
      label_basic_life_support: 'Basic Life Support',
      label_dosage: 'Dosage',
      label_add_patient_notes: 'Add Patient Notes',
      label_update_patient_notes: 'Update Patient Notes',
      label_please_enter_note_here: 'Please enter note here',
      error_note_required: 'Note is required',
      label_select_referral_partner: 'Select Referral Partner',
      label_no_partners_available: 'No partners available.',
      label_refer: 'Refer',
      label_refer_uppercase: 'REFER',
      label_share_with_patient: 'Share with Patient',
      label_share_with_partner: 'Share with Partner',
      label_shared_by_other_means: 'Shared by other means',
      label_send_referral: 'Send Referral',
      label_screening_name: 'Screening Name',
      label_add_new_assessment: 'Add New Assessment',
      label_add_new_vitals: 'Add New Vitals',
      label_unchecking_care_task_reopen_care_task_and_associated_followups_text: 'Unchecking a task will reopen this care task and associated follow-ups. Are you sure to continue with this action?',
      label_medical_record_number: 'Medical Record Number',
      error_pharmacy_site_required: 'Pharmacy Site is required',
      label_deceased: 'Deceased',
      label_date_of_deceased: 'Date Of Deceased',
      label_phone_number_primary: 'Phone Number (Primary)',
      label_phone_number_alternative: 'Phone Number (Alternative)',
      label_enable_sms: 'Enable SMS',
      label_enable_email: 'Enable Email',
      label_social_security_number: 'Social Security Number',
      error_social_security_number_required: 'Social Security Number Required',
      error_invalid_social_security_number: 'Invalid Social Security Number',
      label_homeless: 'Homeless',
      label_secondary_address: 'Secondary Address',
      label_emergency_contact: 'Emergency Contact',
      label_emergency_name: 'Emergency name',
      error_invalid_group_number: 'Invalid Group Number',
      label_primary_insured_name: 'Primary Insured Name',
      error_invalid_primary_insured_name: 'Invalid Primary Insured Name',
      label_plan_level: 'Plan Level',
      label_insurance_card_images: 'Insurance Card Images:',
      error_languages_known_required: 'Languages Known is required',
      error_race_required: 'Race is required',
      error_ethnicity_required: 'Ethnicity is required',
      error_program_to_enroll_required: 'Program to enroll is required',
      label_feedback_requested_form: 'Feedback Requested Form',
      label_no_data_available: 'No data available.',
      error_cancellation_reason_required: 'Cancellation reason required',
      label_cancellation_notes: 'Cancellation Notes',
      label_appointment_detail: 'Appointment Detail',
      label_no_remote_provider_available: 'No Remote provider available.',
      label_no_onsite_provider_available: 'No Onsite provider available.',
      label_on_demand_onsite_content: 'You are about to add the request for an appointment for market providers.',
      label_continue: 'Continue',
      label_go_back: 'Go back',
      label_trips_imported_successfully: 'Trips imported successfully',
      label_enter_notes_here: 'Enter notes here',
      label_reassign_remote_provider: 'Re-assign Remote Provider',
      label_reassign_paramedic_provider: 'Re-assign Paramedic Provider',
      label_paramedic_provider: 'Paramedic Provider',
      label_requested_at: 'Requested At',
      label_accepted_at: 'Accepted At',
      label_dispatched_at: 'Dispatched At',
      label_completed_at: 'Completed At',
      label_sdoh_screening: 'SDoH Screening',
      label_sdoh_screening_details: 'SDoH Screening Details',
      label_patient_denied_to_provide_the_details: 'Patient denied to provide the details.',
      label_sdoh_screening_not_required: 'SDoH screening not required.',
      label_sdoh_form_sent_to_patient: 'SDoH form sent to patient.',
      label_screen_for_sdoh: 'Screen for SDoH',
      label_patient_denied_to_provide_details: 'Patient denied to provide details',
      label_send_sdoh_form_to_patient: 'Send SDoH form to patient',
      label_session_details: 'Session Details',
      label_remote_provider_session: 'Remote Provider Session',
      label_session_timing: 'Session Timing',
      label_start_time: 'Start Time',
      label_end_time: 'End Time',
      label_session_notes: 'Session Notes',
      label_session_documents: 'Session Documents',
      label_onsite_provider_session: 'Onsite Provider Session',
      label_market_provider_session: 'Market Provider Session',
      label_market_provider: 'Market Provider',
      label_end_session: 'End Session',
      label_request_patient_to_provide_sdoh_details: 'Request Patient to provide SDoH Details',
      label_send_appointment_request: 'Send Appointment Request',
      label_book_appointment: 'Book Appointment',
      label_dispatch_notes: 'Dispatch Notes',
      label_send_dispatch_request: 'Send Dispatch Request',
      label_no_provider_available: 'No Provider Available',
      label_reschedule_appointment: 'Reschedule Appointment',
      label_reschedule: 'Reschedule',
      label_remote_provider_schedule: 'Remote Provider Schedule',
      label_update_remote_scheduler: 'Update Remote Scheduler',
      label_confirm_booking: 'Confirm Booking',
      label_show_more_results: 'Show more results',
      label_download_template: 'Download Template',
      label_export_provider_schedule: 'Export Provider Schedule',
      label_add_new_category: 'Add New Category',
      label_add_sub_category: 'Add Sub Category',
      label_create_order: 'Create Order',
      label_add_external_care_team_member: 'Add External Care Team Member',
      label_add_internal_care_team_member: 'Add Internal Care Team Member',
      label_new_market: 'New Market',
      label_member_service_telephone: 'Member Service Telephone',
      label_find_a_provider_url: 'Find A Provider URL',
      label_find_a_provider_telephone: 'Find A Provider Telephone',
      label_summary__of_benifits_english: 'Summary  of Benifits (English)',
      label_other_information: 'Other Information',
      label_companion_care: 'Companion Care',
      label_dental: 'Dental',
      label_diabatic_supplies: 'Diabatic Supplies',
      label_dme_coverage_que: 'DME Coverage?',
      label_vision_coverage_que: 'Vision Coverage?',
      label_otc_allowence: 'OTC Allowence',
      label_front_side: 'Front Side',
      label_back_side: 'Back Side',
      label_transporter_vendor: 'Transporter Vendor',
      label_visit_submit_confirmation_text: 'Once you submit the visit details, this visit will be locked and won’t be available for further edits. Please confirm.',
      label_subjective_or_clinical_assessments: 'Subjective/Clinical Assessments',
      label_patient_program_history: 'Patient program history',
      label_referral_date_and_time: 'Referral Date & Time',
      label_pid_or_mrn: 'PID/MRN',
      label_sex: 'Sex',
      label_baseline_health_history_questions: 'Baseline Health History Questions',
      error_weight_is_required: 'Weight is required',
      label_other_tobacco_cessation_service: 'Other Tobacco cessation service?',
      label_volume: 'Volume',
      label_frequency: 'Frequency',
      label_per_day: 'Per day',
      label_recreational_substance: 'Recreational Substance',
      label_drug_use: 'Drug Use',
      label_last_used: 'Last Used',
      label_relevant_medical_history: 'Relevant Medical History',
      error_relation_is_required: 'Relation is required',
      error_family_history_is_required: 'Family History is required',
      label_ed_visits: 'ED Visits',
      label_facility_name_or_location: 'Facility Name/Location',
      label_reason_for_visits: 'Reason For Visits',
      label_number_of_er_visits_during_enrollment: 'Number of ER Visits During Enrollment',
      label_number_of_er_visits_for_12_months_prior_to_enrollment: 'Number of ER Visits For 12 Months Prior To Enrollment',
      label_admission_date: 'Admission Date',
      label_discharge_date: 'Discharge Date',
      label_planned: 'Planned',
      label_avoidable: 'Avoidable',
      label_hospital_admissions: 'Hospital Admissions',
      label_reason_for_visit: 'Reason For Visit',
      label_number_of_hospital_admissions_during_enrollment: 'Number of Hospital Admissions During Enrollment',
      label_number_of_hospital_admissions_for_12_month_prior_enrollment: 'Number of Hospital Admissions For 12 Month Prior Enrollment',
      label_number_of_hospital_readmissions_during_enrollment: 'Number of Hospital Readmissions During Enrollment',
      label_number_of_hospital_readmissions_for_12_months_prior_to_enrollment: 'Number of Hospital Readmissions For 12 Months Prior to Enrollment',
      label_pcp_visit_details: 'PCP Visit Details',
      label_pcp_name: 'PCP Name',
      label_last_pcp_visit: 'Last PCP Visit',
      label_medical_conditions: 'Medical Conditions',
      label_problem_list: 'Problem List',
      label_other_condition: 'Other condition',
      label_please_make_sure_that_you_have_saved_data: 'Please make sure that you have saved data.',
      label_score: 'Score',
      label_charts: 'Charts',
      label_vital_sign_charts: 'Vital Sign Charts',
      label_date_range_captured_at: 'Date Range (Captured At)',
      label_body_temperature_with_unit: 'Body Temperature (°F)',
      label_blood_pressure_with_unit: 'Blood Pressure (mm/Hg)',
      label_respiration_rate_with_unit: 'Respiration rate (Breaths/min)',
      label_oxygen_saturation_spo2_with_unit: 'Oxygen saturation spo2 (%)',
      label_heart_rate_with_unit: 'Heart rate (Beats/min)',
      label_blood_glucose_level_with_unit: 'Blood glucose level (mg/dl)',
      label_glasgow_coma_scale_with_unit: 'Glasgow Coma Scale (GCS)',
      label_captured_at: 'Captured At',
      label_weight_lbs: 'Weight (lbs)',
      label_body_temp_f: 'Body temp (°F)',
      label_systolic_bp_mmhg: 'Systolic BP (mm/Hg)',
      label_diastolic_bp_mmhg: 'Diastolic BP (mm/Hg)',
      label_spo2_percentage: 'SPO2 (%)',
      label_heart_rate_beatsmin: 'Heart rate (Beats/min)',
      label_blood_glucose_level_grid: 'Blood glucose level (mg/dl) - Type',
      label_gcs: 'GCS',
      label_request_sdoh_screening: 'Request SDoH Screening',
      label_add_vitals: 'Add Vitals',
      label_resend_otp: 'Resend OTP',
      label_add_role: 'Add Role',
      label_new_user: 'New User',
      label_mrn_number: 'MRN Number',
      label_date_of_call: 'Date of Call',
      label_cp_attending: 'CP Attending',
      label_tier: 'Tier',
      label_apc_md: 'APC/MD',
      label_response_time: 'Response time',
      label_caregiver_burnout: 'Caregiver Burnout?',
      label_able_to_travel_to_quest: 'Able to Travel to Quest?',
      label_avoided_er: 'Avoided ER?',
      label_quality_report: 'Quality Report',
      label_start_month: 'Start Month',
      label_end_month: 'End Month',


      // Nirav mi-translate
      educational_material: 'Education Material',
      add_new_education_material: 'Add New Education Material',
      edit_education_material: 'Edit Education Material',
      education_material_title: 'Education Material Title',
      invalid_education_material_title: 'Invalid Education Material Title',
      education_material_type: 'Education Material Type',
      education_material_content: 'Education Material Content',
      education_material_link: 'Education Material Link',
      education_material_access: 'Education Material Access',
      education_material_category_type: 'Education Material Category Type',
      uploaded_by: 'Uploaded By',
      modified_date: 'Modified Date',
      created_date: 'Created Date',
      assign_patient: 'Assign Patient',
      service_area: 'Service Area',
      duty_status: 'Duty Status',
      label_segments: 'Segments',
      label_manage_care_tasks: 'Masters - Manage Care-Tasks',
      label_add_new_care_tasks: 'Add New Care-task Group',
      label_update_new_care_tasks: 'Update Care-task Group',
      label_care_task_group_name_is_required: 'Care-task group name is required',
      care_task: 'Care-task',
      care_task_name_is_required: 'Care-task name is required',
      category_name: 'category name',
      label_manage_insurance_plan: 'Manage Insurance Plan',
      label_add_insurance_plan: 'Add Insurance Plan',
      label_update_insurance_plan: 'Update Insurance Plan',
      label_plan_information: 'Plan Information',
      label_plan_code: 'Plan Code',
      label_information_url: 'Information URL',
      label_service_phone_number: 'Service Phone Number',
      label_provider_url: 'Provider URL',
      label_provider_phone_number: 'Provider Phone Number',
      label_summary_of_benefits_url: 'Summary Of Benefits URL',
      label_benefits: 'Benefits',
      label_care_management: 'Care Management',
      label_dental_care: 'Dental Care',
      label_campanion_care: 'Campanion Care',
      label_diabetic_supplies: 'Diabetic Supplies',
      label_dme_coverage: 'DME Coverage',
      label_meal_delivery: 'Meal Delivery',
      label_nurse_hotline: 'Nurse Hotline',
      label_nurse_hotline_provided: 'Nurse Hotline Provided?',
      label_rn_hotline_phone: 'RN Hotline Phone #',
      label_pharmaceuticals: 'Pharmaceuticals',
      label_prescription_drug_coverage: 'Prescription Drug Coverage',
      label_otc_allowance: 'OTC Allowance',
      label_transportation: 'Transportation',
      label_transportation_benefits: 'Transportation Benefits',
      label_transportation_vendor: 'Transportation Vendor',
      label_transportation_phone_number: 'Transportation Phone Number',
      label_transportation_notes: 'Transportation Notes',
      label_vision_coverage: 'Vision Coverage',
      label_manage_referral_partner: 'Manage Referral Partner',
      label_add_referral_partner: 'Add Referral Partner',
      label_update_referral_partner: 'Update Referral Partner',
      label_invalid_fax_number: 'Invalid Fax Number',
      label_website_link: 'Website Link',
      label_enter_website_link: 'Enter Website Link',
      label_days_of_operation: 'Days Of Operation',
      label_enter_days_of_operation: 'Enter Days Of Operation',
      label_hours_of_operation: 'Hours Of Operation',
      label_enter_hours_of_operation: 'Enter Hours Of Operation',
      label_supported_languages: 'Supported Languages',
      label_enter_supported_languages: 'Enter Supported Languages',
      label_charges: 'Charges',
      label_enter_charges: 'Enter Charges',
      label_document_url: 'Document URL',
      label_enter_document_url: 'Enter Document Url',
      label_document_url_mandatory: 'Document URL Mandatory',
      label_enter_contact_person_name: 'Enter Contact Person Name',
      label_contact_email: 'Contact Email',
      label_enter_contact_email: 'Enter Contact Email',
      label_contact_phone_number: 'Contact Phone Number',
      label_received_referrals: 'Received Referrals',
      label_service_sub_category: 'Service sub-category',
      label_external_providers: 'External Providers',
      label_add_external_provider: 'Add External Provider',
      label_update_external_provider: 'Update External Provider',
      label_enter_organization_name: 'Enter Organization Name',
      medical_facility: 'Medical Facility',
      label_invalid_licence_number: 'Invalid Licence Number',
      label_npi_number_is_invalid: 'NPI Number is invalid',
      label_internal: 'Internal',
      label_sdoh: 'SDoH',
      label_score_points: 'Score Points',
      label_sdoh_editing_note: 'Please get in touch with RainbowCare team for editing the SDoH form.',
      label_category_details: 'Category Details',
      label_masters_category: 'Masters - Category',
      label_category_id: 'Category ID',
      label_masters_categories: 'Masters - Categories',
      label_form_details: 'Form Details',
      label_applicable_to: 'Applicable to',
      label_staff: 'Staff',
      label_masters_manage_external_providers: 'Masters - Manage External Providers',
      label_masters_manage_insurances: 'Masters - Manage Insurances',
      label_manage_master_list_data: 'Manage Master List Data',
      label_master_data_for: 'Master Data For',
      label_masters_manage_referral_partners: 'Masters - Manage Referral Partners',
      label_masters_subcategory: 'Masters - Sub-Category',
      label_sub_category_details: 'Sub-Category Details',
      label_sub_category_id: 'Sub-Category ID',
      label_sub_category_name: 'Sub-Category Name',
      label_terms_and_conditions: 'Terms And Conditions',
      label_provider_title: 'Provider Title',
      label_provider_title_is_required: 'Provider Title is required',
      label_role_details: 'Role Details',
      label_manage_roles: 'Manage Roles',
      label_manage_users: 'Manage Users',
      label_reports_appointments: 'Reports - Appointments',
      label_program: 'Program',
      label_reports_patient_demographics: 'Reports - Patient Demographics',
      label_consent_status: 'Consent Status',
      label_reports_patient_visits: 'Reports - Patient Visits',
      label_set_password: 'Set Password',
      label_sent_otp_msg: 'Your OTP may be send to your RainbowCare device or email depending upon your role.',
      label_password_set_successfully: 'Password set successfully.',
      label_referral_capital: 'Referral',
      label_online_referral_form: 'Online Referral Form',
      label_dial_emg: 'If this is medical emergency or a crisis please dial 911 immediately',
      label_referring: 'Who are you referring?',
      label_referral_form_note: 'This information you provide will be kept confidential and only used to provide treatment and to refer services. Please answer following questions as completely as possible.',
      label_referral_reason: 'Referral reason',
      label_referral_reason_is_required: 'Referral reason is required',
      label_primary_chronic_condition_is_required: 'Primary chronic condition is required',
      label_cares_referral_form: 'CARES Referral Form',
      label_dial_emg_msg_2: 'If this is medical emergency, dial 911 immediately before completing this form',
      label_referral_form_note_2: 'The information you provide will remain confidential and will only used to provide treatment and/or referral services. Please provide as much detail as possible regarding the reasons for referral.',
      label_person_being_referred: 'Person Being Referred',
      label_referral_details: 'Referral details',
      label_referring_individual_agency: 'Referring Individual/Agency',
      label_service_subcategory: 'Service subcategory',
      label_program_details: 'Program details',
      label_contact_details: 'Contact Details',
      label_thank_you_msg: 'Thank you for your time and comments',
      label_sdoh_details_submitted: 'SDoH screening details submitted successfully!',
      label_sdoh_details_already_submitted: 'SDoH screening form is already submitted',
      label_completed_on: 'Completed on',
      label_no_records: 'No records available to show at this moment.',
      label_care_task_description: 'Care-task description',
      error_care_task_description_required: 'Care-task description is required',
      label_assigned_to_provider: 'Assigned to provider',
      note_follow_up: 'Unchecking a task will reopen this care task and associated follow-ups. Are you sure to continue with this action?',
      note_update_care_task: 'You have selected all the tasks from the list. Are you sure to update them all?',
      label_acetaminophen_capsule: '(e.g. Acetaminophen 500 mg Oral Capsule)',
      label_time_est: 'Time (EST)',
      label_screening_status_pending: 'Screening status: Pending',
      label_waiting: 'Waiting',
      label_not_sent: 'Not sent',
      label_program_enrollment: 'Program Enrollment:',
      label_date_of_enrollment: 'Date of Enrollment:',
      label_graduation_dates: 'Graduation Date:',
      label_text_consent: 'Text consent',
      label_email_consent: 'Email consent',
      label_date_of_death: 'Date Of Death',
      label_view_plan_info: 'View Plan Info',
      label_no_consent_reason: 'No Consent Reason',
      label_enter_no_consent_reason: 'Enter No Consent Reason',
      label_complete_assessment: 'Complete Assessment',
      note_home_assignment: 'Please make sure that you have completed the Home safety and PEAT assessment for this patient. Please mention your comments if there are any.',
      label_graduate: 'Graduate',
      label_ask_for_consent_form: 'Ask for Consent Form',
      label_consent_form_shared_with_patient: 'Consent form shared with patient',
      label_submitted_by: 'Submitted by',
      label_calling: 'Calling...',
      label_import_patients: 'Import Patients',
      label_update_document_name: 'Update Document Name',
      label_only_for_staff: 'Only For Staff',
      label_lab_details: 'Lab Details',
      label_a1c: 'A1c',
      label_hemoglobin_a1c: 'Hemoglobin A1c',
      label_reports_are_normal: 'Reports are normal',
      label_attachment: 'Attachment',

    });
  }
}
