<div class="referral-preview-container" *ngIf="!submitted">
  <div class="ui-g-12 ui-md-12 ui-lg-12" style="padding: 20px">
    <div class="ui-g-5 ui-g-nopad text-left">
      <img class="company-logo-background" src='/assets/images/temp/rainbowcare_logo.png' width="178px">
    </div>
    <div class="ui-g-7 ui-g-nopad pt-20 text-right" style="margin-top: 62px;">
      <span class="ref-card" translate>label_referral_capital</span><br>
    </div>
  </div>
  <div class="ui-g-12 ui-md-12 ui-lg-12 ref-seperator"></div>

  <div class="ui-g form-container containar-layout add-patient-form">
    <form [formGroup]='Form'>
      <div class="ui-g-12 ui-md-12 ui-lg-12 dived-border">
        <span class="ui-g-12">
          <h2 translate>label_cares_referral_form</h2>
        </span>
        <span class="ui-g-12 referral-value caps" translate>
          label_dial_emg_msg_2
        </span>
        <span class="ui-g-12 referral-label" translate>
          label_referral_form_note_2
        </span>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12 dived-border">
        <div class="ui-g-12 ui-sm-12">
          <span class="ui-g-12 section-header caps" translate>
            label_person_being_referred
          </span>
        </div>
        <div class="ui-g-12 ui-sm-12">
          <div class="ui-g">
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="username" translate>f_name</label>
                <label class="asterisk">*</label>
              </div>
              <div class="form-control-c">
                <input autocomplete="off"
                  oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.substr(1)"
                  formControlName="firstName" type="text" [placeholder]="'f_name' | translate" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.firstName.errors  && (Form.controls.firstName.dirty || Form.controls.firstName.touched)">
                <div [hidden]="!Form.controls.firstName.errors.required" translate>
                  first_name_reqd
                </div>
                <div [hidden]="!Form.controls.firstName.errors.minlength" translate>
                  min_length
                </div>
                <div [hidden]="!Form.controls.firstName.errors.maxlength" translate>
                  max_character_50
                </div>
                <div [hidden]="!Form.controls.firstName.errors.pattern" translate>
                  name_pattern
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="username" translate>l_name</label>
                <label class="asterisk">*</label>
              </div>
              <div class="form-control-c">
                <input autocomplete="off"
                  oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.substr(1)" autocapitalize="none"
                  formControlName="lastName" type="text" [placeholder]="'l_name' | translate" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.lastName.errors  && (Form.controls.lastName.dirty || Form.controls.lastName.touched)">
                <div [hidden]="!Form.controls.lastName.errors.required" translate>
                  last_name_reqd
                </div>
                <div [hidden]="!Form.controls.lastName.errors.minlength" translate>
                  min_length
                </div>
                <div [hidden]="!Form.controls.lastName.errors.maxlength" translate>
                  max_character_50
                </div>
                <div [hidden]="!Form.controls.lastName.errors.pattern" translate>
                  name_pattern
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="gender" translate>lebel_sex</label>
                <label class="asterisk">*</label>
              </div>
              <div class="form-control-c">
                <p-dropdown [options]="genderList" formControlName="gender" [placeholder]="'lebel_sex' | translate"
                  [filter]="true"> </p-dropdown>
              </div>
              <div class="form-errors ui-g-12"
                *ngIf="Form.controls.gender.errors  && (Form.controls.gender.dirty || Form.controls.gender.touched)">
                <div [hidden]="!Form.controls.gender.errors.required" translate>
                  err_sex_required
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="username" translate>lebel_date_birth</label>
              </div>
              <div class="form-control-c">
                <p-calendar formControlName="dateOfBirth" [maxDate]="maxDate" dateFormat="{{dateFormat}}"
                  [monthNavigator]="true" [yearNavigator]="true" [yearRange]="issueDateRange" [showIcon]="true"
                  [placeholder]="'lebel_date_birth' | translate">
                </p-calendar>
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.dateOfBirth.errors  && (Form.controls.dateOfBirth.dirty || Form.controls.dateOfBirth.touched)">
                <div [hidden]="!Form.controls.dateOfBirth.errors.required" translate>
                  dob_required
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="address1" translate>address</label>
                <!-- <label class="asterisk">*</label> -->
              </div>
              <div class="form-control-c">
                <textarea autocomplete="off" maxlength="200" pInputTextarea [rows]="3" [cols]="30" formControlName="address1"
                  [placeholder]="'address' | translate"></textarea>
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.address1.errors  && (Form.controls.address1.dirty || Form.controls.address1.touched)">
                <div [hidden]="!Form.controls.address1.errors.required" translate>
                  address_req
                </div>
                <div [hidden]="!Form.controls.address1.errors.maxlength" translate>
                  max_character_100
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12" [hidden]="true">
              <div class="form-label">
                <label for="address2" translate>address_line2</label>
                <!-- <label class="asterisk">*</label> -->
              </div>
              <div class="form-control-c">
                <input autocomplete="off" formControlName="address2" type="text"
                  [placeholder]="'address_line2' | translate" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.address2.errors  && (Form.controls.address2.dirty || Form.controls.address2.touched)">
                <div [hidden]="!Form.controls.address2.errors.required" translate>
                  address_req
                </div>
                <div [hidden]="!Form.controls.address2.errors.maxlength" translate>
                  max_character_100
                </div>
                <div [hidden]="!Form.controls.address2.errors.pattern" translate>
                  invalid_input
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-4 ui-lg-4">
              <div class="form-label">
                <label for="city" translate>label_city</label>
                <!-- <label class="asterisk">*</label> -->
              </div>
              <div class="form-control-c">
                <input autocomplete="off" maxlength="70" formControlName="city" type="text" pInputText
                  [placeholder]="'City' | translate" pInputText />
              </div>
              <div class="form-errors ui-g-12"
                *ngIf="Form.controls.city.errors  && (Form.controls.city.dirty || Form.controls.city.touched)">
                <div [hidden]="!Form.controls.city.errors.required" translate>
                  city_req
                </div>
                <div [hidden]="!Form.controls.city.errors.pattern" translate>
                  invalid_input
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-4 ui-lg-4">
              <div class="form-label">
                <label for="state" translate>label_state</label>
                <!-- <label class="asterisk">*</label> -->
              </div>
              <div class="form-control-c">
                <p-dropdown [options]="statelist" formControlName="state" [(ngModel)]="defaultState"
                  [placeholder]="'State' | translate" [filter]="true"> </p-dropdown>
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.state.errors  && (Form.controls.state.dirty || Form.controls.state.touched)">
                <div [hidden]="!Form.controls.state.errors.required" translate>
                  state_req
                </div>
                <div [hidden]="!Form.controls.state.errors.pattern" translate>
                  invalid_input
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-4 ui-lg-4">
              <div class="form-label">
                <label for="zipcode" translate>zip_code</label>
                <!-- <label class="asterisk">*</label> -->
              </div>
              <div class="form-control-c">
                <input autocomplete="off" formControlName="zipcode" type="text" pInputText
                  [placeholder]="'zip_code' | translate" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.zipcode.errors  && (Form.controls.zipcode.dirty || Form.controls.zipcode.touched)">
                <div [hidden]="!Form.controls.zipcode.errors.required" translate>
                  zipcode_req
                </div>
                <div [hidden]="!Form.controls.zipcode.errors.minlength" translate>
                  zipcode_invalid
                </div>
                <div [hidden]="!Form.controls.zipcode.errors.pattern" translate>
                  zipcode_invalid
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="contactNumber" translate>phone</label>
                <!-- <label class="asterisk">*</label> -->
              </div>
              <div class="">
                <div class="contact-with-countory">
                  <div class="clearfix"></div>
                  <div class="left">
                    <p-dropdown class="countory-code" [options]="countryCodeList" [disabled]="true"
                      formControlName="numCountryCode" [style]="{'width': '100%'}" [filter]="true">
                    </p-dropdown>
                  </div>
                  <div class="right">
                    <input autocomplete="off" class="pl-20" maxlength="12" formControlName="phoneNum" type="text"
                      pInputText appPhoneMask [placeholder]="'label_phone_number' | translate" />
                  </div>
                </div>
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.phoneNum.errors  && (Form.controls.phoneNum.dirty || Form.controls.phoneNum.touched)">
                <div [hidden]="!Form.controls.phoneNum.errors.required" translate>
                  contact_number_require
                </div>
                <div [hidden]="!Form.controls.phoneNum.errors.pattern" translate>
                  phone_invalid
                </div>
                <div [hidden]="!Form.controls.phoneNum.errors.minlength" translate>
                  min_length_phone_number
                </div>
                <div [hidden]="!Form.controls.phoneNum.errors.maxlength" translate>
                  max_number_10
                </div>
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="username" translate>label_email</label>
                <!-- <label class="asterisk">*</label> -->
              </div>
              <div class="form-control-c">
                <input autocomplete="off" formControlName="email" maxlength="100" type="text" pInputText
                  [placeholder]="'label_email' | translate" />
              </div>
              <div class="form-errors"
                *ngIf="Form.controls.email.errors  && (Form.controls.email.dirty || Form.controls.email.touched)">
                <div [hidden]="!Form.controls.email.errors.required" translate>
                  email_required
                </div>
                <div [hidden]="!Form.controls.email.errors.pattern" translate>
                  email_pattern
                </div>
                <div [hidden]="!Form.controls.email.errors.maxlength" translate>
                  max_character_100
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12 dived-border">
        <div class="ui-g-12 ui-sm-12">
          <span class="ui-g-12 section-header caps" translate>
            label_referral_info
          </span>
        </div>
        <div class="ui-g-12 ui-sm-12">
          <div class="form-field ui-g-12 ui-md-12 ui-lg-12" *ngIf="primaryCronicConditionField?.visible">
            <div class="form-label">
              <label for="primaryCronicCondition" translate>{{primaryCronicConditionField?.label}} (select all that apply)</label>
              <label class="asterisk" *ngIf="primaryCronicConditionField?.validations?.required">*</label>
            </div>
            <div class="form-control-c">
              <p-multiSelect autocomplete="off" [options]="chronicConditionDataList" [defaultLabel]="primaryCronicConditionField?.label"
                formControlName="primaryCronicCondition" [style]="{'width': '100%'}" (onChange)="changeChronicCondition($event)"
                filter="filter" [resetFilterOnHide]="true">
              </p-multiSelect>
            </div>
            <div class="form-errors ui-g-12"
              *ngIf="Form.controls.primaryCronicCondition.errors  && (Form.controls.primaryCronicCondition.dirty || Form.controls.primaryCronicCondition.touched)">
              <div [hidden]="!Form.controls.primaryCronicCondition.errors.required" translate>
                {{primaryCronicConditionField?.label}} is required
              </div>
            </div>
          </div>
          <!-- <div class="form-field ui-g-12 ui-md-6 ui-lg-6"
            *ngIf="this.Form.get('primaryCronicCondition').value === '2924627221294'">
            <div class="form-label">
              <label for="primaryInsuredName" translate>Other chronic condition</label>
            </div>
            <div class="form-control-c">
              <input autocomplete="off" formControlName="otherCronicCondition" type="text"
                [placeholder]="'Other chronic condition' | translate" pInputText />
            </div>
          </div> -->
          <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
            <div class="form-label">
              <label for="username" translate>label_referral_details</label>
              <label class="asterisk">*</label>
            </div>
            <div class="form-control-c">
              <textarea autocomplete="off" maxlength="200" pInputTextarea [rows]="3" [cols]="30" formControlName="referralReason"
                [placeholder]="'label_referral_reason' | translate"></textarea>
            </div>
            <div class="form-errors"
              *ngIf="Form.controls.referralReason.errors  && (Form.controls.referralReason.dirty || Form.controls.referralReason.touched)">
              <div [hidden]="!Form.controls.referralReason.errors.required" translate>
                label_referral_reason_is_required
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12 dived-border">
        <div class="ui-g-12 ui-sm-12">
          <span class="ui-g-12 section-header caps" translate>
            label_referring_individual_agency
          </span>
        </div>
        <div class="ui-g-12 ui-sm-12">
          <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
            <div class="form-label">
              <label for="username" translate>label_referring_person</label>
            </div>
            <div class="form-control-c">
              <input autocomplete="off" type="text" formControlName="personName"
                [placeholder]="'label_referring_person' | translate" pInputText />
            </div>
            <div class="form-errors"
              *ngIf="Form.controls.personName.errors  && (Form.controls.personName.dirty || Form.controls.personName.touched)">
              <div [hidden]="!Form.controls.personName.errors.required" translate>
                error_name_required
              </div>
            </div>
          </div>

          <div class="form-field ui-g-12 ui-md-6 ui-lg-6" *ngIf="organizationName?.visible">
            <div class="form-label">
              <label for="username" translate>{{organizationName?.label}} (if applicable)</label>
              <label class="asterisk" *ngIf="organizationName?.validations?.required">*</label>
            </div>
            <div class="form-control-c">
              <input autocomplete="off" type="text" formControlName="agencyName"
                [placeholder]="organizationName?.label" pInputText />
            </div>
            <div class="form-errors"
              *ngIf="Form.controls.agencyName.errors  && (Form.controls.agencyName.dirty || Form.controls.agencyName.touched)">
              <div [hidden]="!Form.controls.agencyName.errors.required" translate>
                {{organizationName?.label}} is required
              </div>
              <div [hidden]="!Form.controls.agencyName.errors.minlength" translate>
                Minimum {{organizationName?.validations?.min_length}} characters required
              </div>
              <div [hidden]="!Form.controls.agencyName.errors.maxlength" translate>
                Maximum {{organizationName?.validations?.max_length}} characters allowed
              </div>
              <div [hidden]="!Form.controls.agencyName.errors.pattern" translate>
                Invalid data
              </div>
            </div>
          </div>

          <div class="form-field ui-g-12 ui-md-6 ui-lg-5">
            <div class="form-label">
              <label for="contactNumber" translate>phone</label>
            </div>
            <div class="">
              <div class="contact-with-countory">
                <div class="clearfix"></div>
                <div class="left">
                  <p-dropdown class="countory-code" [options]="countryCodeList" [disabled]="true"
                    formControlName="referralNumCountryCode" [style]="{'width': '100%'}" [filter]="true">
                  </p-dropdown>
                </div>
                <div class="right">
                  <input autocomplete="off" class="pl-20" maxlength="12" formControlName="referralPhoneNum" type="text"
                    pInputText appPhoneMask [placeholder]="'label_phone_number' | translate" />
                </div>
              </div>
            </div>
            <div class="form-errors"
              *ngIf="Form.controls.referralPhoneNum.errors  && (Form.controls.referralPhoneNum.dirty || Form.controls.referralPhoneNum.touched)">
              <div [hidden]="!Form.controls.referralPhoneNum.errors.required" translate>
                contact_number_require
              </div>
              <div [hidden]="!Form.controls.referralPhoneNum.errors.pattern" translate>
                phone_invalid
              </div>
              <div [hidden]="!Form.controls.referralPhoneNum.errors.minlength" translate>
                min_length_phone_number
              </div>
              <div [hidden]="!Form.controls.referralPhoneNum.errors.maxlength" translate>
                max_number_10
              </div>
            </div>
          </div>
          <div class="form-field ui-g-12 ui-md-6 ui-lg-2">
            <div class="form-label">
              <label for="contactExt" translate>placeholder_Extension</label>
            </div>
            <div class="form-control-c">
              <input type="text" autocomplete="off" class="pl-20" maxlength="15"
              formControlName="referralPhoneNumExt" [placeholder]="'placeholder_Extension' | translate"
              onkeypress="return /[0-9]/i.test(event.key)" pInputText/>
            </div>
          </div>
          <div class="form-field ui-g-12 ui-md-6 ui-lg-5">
            <div class="form-label">
              <label for="username" translate>label_email</label>
            </div>
            <div class="form-control-c">
              <input autocomplete="off" formControlName="referralEmail" maxlength="100" type="text" pInputText
                [placeholder]="'label_email' | translate" />
            </div>
            <div class="form-errors"
              *ngIf="Form.controls.referralEmail.errors  && (Form.controls.referralEmail.dirty || Form.controls.referralEmail.touched)">
              <div [hidden]="!Form.controls.referralEmail.errors.required" translate>
                email_required
              </div>
              <div [hidden]="!Form.controls.referralEmail.errors.pattern" translate>
                email_pattern
              </div>
              <div [hidden]="!Form.controls.referralEmail.errors.maxlength" translate>
                max_character_100
              </div>
            </div>
          </div>

          <div class="form-field ui-g-12 ui-md-6 ui-lg-6" *ngIf="programField?.visible">
            <div class="form-label">
              <label for="username" translate>{{programField?.label}} </label>
              <label class="asterisk" *ngIf="programField?.validations?.required">*</label>
            </div>
            <div class="form-control-c">
              <p-dropdown [options]="programsList" formControlName="programId"
                [placeholder]="programField?.label" [filter]="true"> 
              </p-dropdown>
            </div>
            <div class="form-errors"
              *ngIf="Form.controls.programId.errors  && (Form.controls.programId.dirty || Form.controls.programId.touched)">
              <div [hidden]="!Form.controls.programId.errors.required" translate>
                {{programField?.label}} is required
              </div>
            </div>
          </div>

          <div class="form-field ui-g-12 ui-md-6 ui-lg-6" *ngIf="referralSourceField?.visible">
            <div class="form-label">
              <label for="username" translate>{{referralSourceField?.label}} </label>
              <label class="asterisk" *ngIf="referralSourceField?.validations?.required">*</label>
            </div>
            <div class="form-control-c">
              <p-dropdown [options]="referralSourceList" formControlName="referralSourceTypeId"
                [placeholder]="referralSourceField?.label" [filter]="true"> 
              </p-dropdown>
            </div>
            <div class="form-errors"
              *ngIf="Form.controls.programId.errors  && (Form.controls.programId.dirty || Form.controls.programId.touched)">
              <div [hidden]="!Form.controls.programId.errors.required" translate>
                {{referralSourceField?.label}} is required
              </div>
            </div>
          </div>

          <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
            <div class="form-label">
              <label style="color: #32455F;font-weight: 600;" for="username" translate>label_additional_Notes_Comments</label>
            </div>
            <div class="form-control-c">
              <textarea autocomplete="off" [maxlength]="maxNotesCharAllowed" pInputTextarea [rows]="3" [cols]="30" formControlName="additionalNotes"
                [placeholder]="'label_additional_Notes_Comments' | translate"></textarea>
            </div>
          </div>

          <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
            <div class="form-field form-label ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad" 
            style="display: flex;
            justify-content: start;
            flex-direction: row;
            align-items: center;"
            >

              <label for="username" translate>label_attach_document</label>
              <span>
                <p-fileUpload name="file[]" [headers]="getHttpClientHeaders()" chooseLabel="Select File"
                  [maxFileSize]="maxSize" [url]="fileUploadUrl" [customUpload]="false" (onUpload)="onUpload($event)"
                  (onBeforeUpload)="onBeforeUpload()" (onBeforeSend)="onBeforeSend($event)" #fileInput
                  accept="image/*, application/pdf, .xlsx, .xls, .docx, .doc, .ppt, .txt, .tif, .tiff, .zip"
                  (onSelect)="onFileSelected($event)" [showCancelButton]="false" [showUploadButton]="false"
                  [withCredentials]="true" [invalidFileSizeMessageSummary]="maxSizeMessage"
                  [invalidFileTypeMessageSummary]='invalidFileMessage'>
                </p-fileUpload>
              </span>

            </div>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
              <p *ngIf="fileName" class="file-name">
                {{ fileName }}
                <img class="delete-btn" (click)="deleteCurrentDocument()"
                  src="../../../../../assets/images/temp/close.png" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-6">
        </div>
        <div class="form-buttons ui-g-6 ref">
          <button pButton class="ref-btn" type="button" [label]="'label_submit' | translate"
            [disabled]="doValidSave() || displayBtn" (click)="submit()"></button>
          <!-- <button class="cancel-btn" pButton type="button" [label]="'label_cancel' | translate" (click)="goBack()"></button> -->
        </div>
      </div>

    </form>
  </div>

  <div class="ui-g-12 ui-md-12 ui-lg-12">
    <div class="ui-g-12 ui-g-nopad text-center">
      <span class="footer-label"> Rainbow Health LLC, Houston, Texas. All Rights Reserved. ISO 27001:2013 Certified
      </span>
    </div>
  </div>
</div>

<div class="referral-preview-container" *ngIf="submitted">
  <div class="ui-g-12 ui-md-12 ui-lg-12" style="margin-top: 8%;">
    <div class="card">
      <div class="card-container">
        <div class="submitted-lable">
          <h3>{{submittedMsg}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
