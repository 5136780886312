export const environment = {
  production: false,
  basePath: 'https://walton-dev-api.rainbow.health/api',
  tenant: 'usc001',
  supportedCountry: ['IN', 'US'],
  idleTimeEnable: true,
  firebase: {
    apiKey: 'AIzaSyBqFvpLk8C5xmp92JkFOW30K08AvaoAEbE',
    authDomain: 'landmark-housecalls-dev.firebaseapp.com',
    projectId: 'landmark-housecalls-dev',
    storageBucket: 'landmark-housecalls-dev.appspot.com',
    messagingSenderId: '684605695872',
    appId: '1:684605695872:web:4146f789f92fe11e7f4365',
    measurementId: 'G-SZH7K91Y70'
  }
};
