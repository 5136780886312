import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../modules/shared/app.settings';
import { UserCommonService } from './../../modules/shared/services/user-common.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from './../../modules/shared/services/storage.service';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { NotificationsService } from './../../modules/shared/services/notifications.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';

@Component({
  selector: 'app-sign-in-layout',
  templateUrl: './sign-in-layout.component.html'
})

export class SignInLayoutComponent implements OnInit {
  signInResponse: any;
  otpId;
  forgotPopup = false;
  loginErrors = [{
    email: false,
    password: false,
  }];
  subscription: Subscription;
  otpPopup = false;
  setPasswordPopup = false;
  isMetadataLoaded = false;
  otpEmail;
  otpPhone;
  data;

  signInWelcomeTextLabel;
  signInDescription;
  readMoreLabel;
  labelResetPassword;
  labelValidateOtp;
  isCompanyRole = false;
  message1;
  deviceInfo = null;
  notification_token;
  device_details;

  constructor(
    private userCommonService: UserCommonService,
    private router: Router,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    private notificationService: NotificationsService,
    private uiService: UiService,
    private messagingService: MessagingService,
  ) { }

  ngOnInit() {
    this.messagingService.requestPermission();
    // this.messagingService.receiveMessage();
    // this.message1 = this.messagingService.currentMessage;

    this.signInWelcomeTextLabel = this.commonBindingDataService.getLabel('label_sign_in_welcome_text');
    this.signInDescription = this.commonBindingDataService.getLabel('label_sign_in_description');
    this.readMoreLabel = this.commonBindingDataService.getLabel('label_read_more');
    this.labelResetPassword = this.commonBindingDataService.getLabel('label_reset_password');
    this.labelValidateOtp = this.commonBindingDataService.getLabel('label_validate_otp');
    const isIntervalCheck = this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
  }

  doSignIn(signInData) {
    this.signInApiCall(signInData);
  }

  signInApiCall(signInData) {
    this.storageService.removeAllCookies();

    // web push notification :: notification token :: start
    this.notification_token = this.storageService.getItem('notificationToken');
    // console.log(this.notification_token);
    const ba = ['Chrome', 'Firefox', 'Safari', 'Opera', 'MSIE', 'Trident', 'Edge'];
    let b;
    const ua = navigator.userAgent;
    for (let i = 0; i < ba.length; i++) {
      if (ua.indexOf(ba[i]) > -1) {
        b = ba[i];
        break;
      }
    }
    if (b === 'MSIE' || b === 'Trident' || b === 'Edge') {
      b = 'Internet Explorer';
    }

    const temp = {
      deviceName: b,
      deviceType: 'web',
      os: navigator.platform,
      notificationToken: this.notification_token,
    };
    this.device_details = temp;
    signInData.deviceDetails = this.device_details;
    // web push notification :: notification token :: end

    this.userCommonService.signIn(JSON.stringify(signInData)).subscribe(signInResult => {
      if (signInResult) {
        this.signInResponse = signInResult;
        if (this.signInResponse.accessGroup !== null && this.signInResponse.accessGroup.length > 0) {
          this.notificationService.canFetchNotification = true;
          this.setSessionAndUserDetails(signInResult);
          this.userCommonService.getUserMasterMetaData();
          this.userCommonService.getAdminUserMetaData();
          this.userCommonService.setRolesAndGenerateSidebar();
          this.userCommonService.setDefaultUserMarket(signInResult?.userMarkets);
          const idleTime = Number(this.commonBindingDataService.getTenantConfigValue('session_timeout_in_mins')) ? Number(this.commonBindingDataService.getTenantConfigValue('session_timeout_in_mins'))
            : AppSettings.IDLE_TIME;
          this.storageService.setItem('idleTime', idleTime);
          this.storageService.setItemInCookies('sessionToken', signInResult.sessionToken);
          localStorage.setItem('saveAppointmentDraft', signInResult.saveAppointmentDraft);
          this.storageService.setLocalStorage('dispatchRequestAccess', signInResult.dispatchRequestAccess);

          // START :: Navigate to original URL (i.e user entered url) after the login
          const redirectionUrl = this.userCommonService.getRedirectionUrl();
          if (redirectionUrl) {
            if (redirectionUrl.includes('?')) {
              const qParam = redirectionUrl.split('?')[1].split('&');
              const query = {};
              let newArry = [];
              newArry = qParam.map(item => item.split('=')).reduce((a, b) => a.concat(b));
              for (let i = 0; i < newArry.length; i++) {
                const s = newArry[i].replace(/['"]+/g, '');
                if (!(i % 2)) {
                  query[newArry[i]] = newArry[i + 1];
                }
              }
              setTimeout(() => {
                this.router.navigate([redirectionUrl.split('?')[0]], { queryParams: query });
              }, 600);
            } else {
              setTimeout(() => {
                this.router.navigate([redirectionUrl]);
              }, 600);
            }
          }
          // END
        } else {
          this.uiService.showMessage(this.commonBindingDataService.getLabel('error_invalid_access_group'), AppSettings.MESSAGE_TYPES.ERROR);
        }
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  setSessionAndUserDetails(results) {
    this.storageService.setItem(AppSettings.USER_DETAILS, results.user);
    this.storageService.setItem(AppSettings.ACCESS_MENU, this.signInResponse.accessGroup);
    this.storageService.setItem('internalCheckListPermissions', results.internalCheckListPermissions);
    this.storageService.setItem('sdohScreeningPermissions', results.sdohScreeningPermissions);
    this.storageService.setLocalStorage('providerTimezoneName', results.user.timezoneName);
    this.storageService.setLocalStorage(AppSettings.USER_MARKETS, results.userMarkets);
    AppSettings.USER = results.user;
    this.setTagList();
    if (results.user.userDetails !== null) {
      this.storageService.setItem(AppSettings.CURRENT_USER_CORPORATE_ID, results.user.userDetails.corporateId);
    }
  }

  setTagList() {
    this.userCommonService.getTagsListApi().subscribe(result => {
      this.storageService.setLocalStorage(AppSettings.TAG_DATA, result);
    }, (error) => {
    });
  }

  moduleWiseRouteAccesses() {
    const groups = {};
    let accesses = this.signInResponse.accesses;
    for (let i = 0; i < accesses.length; i++) {
      const accessGroupName = accesses[i].accessGroupName;
      if (!groups[accessGroupName]) {
        groups[accessGroupName] = [];
      }
      groups[accessGroupName].push(accesses[i].accessName);
    }
    accesses = [];
    for (const accessGroupName in groups) {
      if (groups.hasOwnProperty(accessGroupName)) {
        accesses.push({ group: accessGroupName, accessName: groups[accessGroupName] });
      }
    }
    this.storageService.setItem(AppSettings.ACCESS_LIST, accesses);
  }

  uniqArray(arrArg) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) === pos;
    });
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  onPopClose(event) {
    this.forgotPopup = false;
  }

  onOtpPopClose(event) {
    this.otpPopup = false;
  }

  onSetPasswordPopClose(event) {
    this.setPasswordPopup = false;
  }

  onOtpPopSave(event) {
    if (event.general !== undefined) {
      this.uiService.showMessage(event.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    } else {
      if (event.otpId) {
        this.otpId = event.otpId;
        this.uiService.showMessage(this.commonBindingDataService.getLabel('matched_otp'), AppSettings.MESSAGE_TYPES.SUCCESS);
        this.setPasswordPopup = true;
      }
    }
    this.forgotPopup = false;
    this.otpPopup = false;
  }

  onPopSave(results) {
    this.otpEmail = results;
    this.otpPhone = results.phone;
    this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
    this.forgotPopup = false;
    this.otpPopup = true;
  }

}
