<div class="signin-box-container ui-g">
  <div class="signin-box-inner-container">
    <div class="signin-box ui-md-4 ui-md-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field ui-g-12 ui-g-nopad">
          <div class="form-field ui-g-12 ui-g-nopad text-center">
            <img class="company-logo-background" src='./assets/images/temp/rainbowcare_logo.png'>
          </div>
          <div class="form-field ui-g-12 switch-btn" translate>label_verify_email</div>
          <div class="form-field ui-g-12"></div>
        </div>
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
          <form [formGroup]='editforgotForm'>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="username" translate>Username</label>
              </div>
              <div class="form-control-c">
                <input autocomplete="off" formControlName="username" class="bottom-border" type="text"
                  (blur)="validateEmail()" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="editforgotForm.controls.username.errors  && (editforgotForm.controls.username.dirty || editforgotForm.controls.username.touched)">
                <div [hidden]="!editforgotForm.controls.username.errors.required" translate>
                  email_required
                </div>
                <!-- <div [hidden]="!editforgotForm.controls.username.errors.pattern" translate>
                  err_invalid_emailId
                </div> -->
                <div *ngIf="isEmailInvalid" translate>
                  err_invalid_emailId
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12">
              <div class="form-buttons ui-g-12 ui-g-nopad">
                <button pButton type="button" class="signin-save-btn" [label]="'label_send' | translate" [disabled]="!editforgotForm.valid"
                  (click)="changePassword($event)"></button>
                <button pButton class="cancel-back-btn " type="button" [label]="'label_cancel' | translate"
                  (click)="onBackSign($event)"></button>
              </div>
            </div>
          </form>
        </div>

        <div class="remove-float"></div>
      </div>

    </div>
  </div>
</div>
