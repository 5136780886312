import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { UiService } from 'app/modules/shared/services/ui.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';

@Component({
  selector: 'app-password-set-with-otp-verify',
  templateUrl: './password-set-with-otp-verify.component.html'
})
export class PasswordSetWithOtpVerifyComponent implements OnInit {

  newPassword: any = '';
  confirmPassword: any = '';
  token: any;
  setPass: any;
  emailVerify: any = false;
  verifyMessage: any = '';
  getResponse: any = false;
  passwordSetSucessFully: any = false;
  inputType = 'password';
  cnfinputType = 'password';
  setPasswordOtp = false;
  otpValue = '';
  otpCounterTimer;
  otpInterval: any;
  passwordForm = this.fb.group({
    newPassword: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(25),
      Validators.pattern(AppSettings.PASSWORD_PATTERN)]
    ],
    confirmPassword: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(25),
      Validators.pattern(AppSettings.PASSWORD_PATTERN)]
    ]
  });
  otpTimerValue;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userCommonService: UserCommonService,
    private fb: FormBuilder,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.getMetadata();
    this.token = this.route.snapshot.queryParams.token;
    // this.setPass = this.route.snapshot.queryParams.setPass;
    // this.verifyEmail();
  }

  verifyEmail() {
    const data = {
      verifyTokenId: this.token
    };
    // this.userCommonService.emailVerify(data).subscribe(result => {
    //   this.emailVerify = true;
    //   this.getResponse = true;
    //   this.verifyMessage = result.general[0].message;
    // }, (error) => {

    //   for (const firstKey in error) {
    //     this.verifyMessage = error[firstKey][0].message;
    //   }
    //   this.getResponse = true;
    // });
  }

  closeWalletPopup() {
    this.setPasswordOtp = false;
    this.otpValue = '';
    this.otpCounterTimer = this.otpTimerValue;
  }

  resendOTP() {
    this.otpValue = '';
    this.sendOTP();
  }

  sendOTP() {
    this.otpValue = '';
    const self = this;
    this.userCommonService.sendOtp(this.token).subscribe(result => {
      this.setPasswordOtp = true;
      this.otpCounterTimer = this.otpTimerValue;
      this.otpInterval = setInterval(function() {
        self.otpCounterTimer = self.otpCounterTimer - 1;
        if (self.otpCounterTimer <= 0) {
          self.otpCounterTimer = 0;
          clearInterval(self.otpInterval);
        }
      }, 1000);
    }, (error) => {
      for (const firstKey in error) {
        this.uiService.showMessage(error[firstKey][0].message, AppSettings.MESSAGE_TYPES.ERROR);
      }
    });
  }

  setPassword() {
    const data = {
      // verifyTokenId: this.token,
      password: this.newPassword,
      otp: this.otpValue
    };

    this.userCommonService.setPasswordFirtTime(data, this.token).subscribe(result => {
      this.passwordSetSucessFully = true;
      this.setPasswordOtp = false;
      this.otpCounterTimer = this.otpTimerValue;
      setTimeout(() => {
        this.router.navigate(['/redirect-page']);
      }, 1000);
    }, (error) => {

      for (const firstKey in error) {
        this.uiService.showMessage(error[firstKey][0].message, AppSettings.MESSAGE_TYPES.ERROR);
      }
    });
  }


  tooglePasswordType() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }

  toogleCnfPasswordType() {
    if (this.cnfinputType === 'password') {
      this.cnfinputType = 'text';
    } else {
      this.cnfinputType = 'password';
    }
  }

  getMetadata() {
    this.userCommonService.getPublicMetadata('forgot_password').subscribe(results => {
      this.otpTimerValue = results?.resendOtpTimeInSeconds ? parseInt(results?.resendOtpTimeInSeconds, 10) : AppSettings.DEFAULT_OTP_TIMER;
      this.otpCounterTimer = this.otpTimerValue;
    }, (error) => {
      this.otpTimerValue = AppSettings.DEFAULT_OTP_TIMER;
      this.otpCounterTimer = this.otpTimerValue;
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

}
