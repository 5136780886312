<div (window:resize)="onResize($event)" class="mi-grid-component">
  <div class="search-and-export-data-panel">
    <input autocomplete="off" *ngIf="!bottomSearchBar" type="text" class="grid-search-bar top" [(ngModel)]="search"
      maxlength="50" (keyup)="onQuickFilterChanged($event, dt)" (keypress)="keyPress($event)"
      [placeholder]="placeholderValue" />
    <label for="search-bar" *ngIf="!bottomSearchBar" class="fa fa-search input-icon"></label>

    <span class="export-button top" *ngIf="(exportUrl !== '') && totalRecords > 0">
      <span class="header-action-btn export" (click)="exportGridExcel($event)" title="Download Excel">
        <i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i>
        XLS
      </span>
    </span>

    <span class="export-button top" *ngIf="(printUrl !== '') && totalRecords > 0">
      <span class="header-action-btn export" (click)="exportGridPdf($event)" title="Download Pdf">
        <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>
        PDF
      </span>
    </span>

    <span class="export-button top" *ngIf="(exportCSVUrl !== '') && totalRecords > 0">
      <span class="header-action-btn export" (click)="exportGridExcel($event)" title="Download csv">
        <i class="fa fa-fw fa-file-o" aria-hidden="true"></i>
        CSV
      </span>
    </span>
  </div>

  <div class="ui-g-12">
    <p-scrollPanel [style]="{ width: '98%' }" styleClass="custombar1">
      <p-table #dt [ngClass]="isDetailScreen ? 'detail-screen-available' : ''" [lazy]="true" [rows]="paginationPageSize"
        [tableStyle]="{'table-layout': 'unset'}" metaKeySelection="false" [value]="datasource" [paginator]="pagination"
        [totalRecords]="totalRecords" (onLazyLoad)="loadDatasourceLazy($event)" [selectionMode]="rowSelection"
        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectedRecords"
        [resizableColumns]="enableResizer" [first]="first">

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem" *ngIf="selectionCheckbox">
              <p-tableHeaderCheckbox [value]="datasource" #headerCheckbox
                (click)="CheckforCompleted();"></p-tableHeaderCheckbox>
            </th>
            <ng-container *ngFor="let col of columnDefs">
              <th *ngIf="col.resize && enableResizer" [ngClass]="col?.class ? col.class : ''" pResizableColumn
                [pSortableColumn]="(col?.sort) ? col.sortId : ''" [width]="col?.width" title="{{col.header}}">
                <span *ngIf="col?.header !=='Actions'">{{col.header}}</span>
                <span *ngIf="col?.header =='Actions'" class="pl-10">{{col.header}}</span>
                <p-sortIcon *ngIf="col?.sort" [field]="col.sortId"></p-sortIcon>
              </th>

              <th *ngIf="!col.resize" [ngClass]="col?.class ? col.class : ''"
                [pSortableColumn]="(col?.sort) ? col.sortId : ''" title="{{col.header}}">
                <span *ngIf="col?.header !=='Actions'">{{col.header}}</span>
                <span *ngIf="col?.header =='Actions'" class="pl-10">{{col.header}}</span>
                <p-sortIcon *ngIf="col?.sort" [field]="col.sortId"></p-sortIcon>
              </th>
            </ng-container>

            <th class="action-column" *ngIf="actionButtonsDefs.length" title="Action" [width]="actionWidth"
              style="text-align: center;">
              Action
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td *ngIf="actionButtonsDefs.length === 0" class="action-header" [attr.colspan]="columnDefs.length">
              <span class="action-text">
                No records available to show at this moment.
              </span>
            </td>
            <td *ngIf="actionButtonsDefs.length > 0" class="action-header" [attr.colspan]="columnDefs.length + 1">
              <span class="action-text">
                No records available to show at this moment.
              </span>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">
          <tr [pSelectableRowIndex]="rowIndex" [class]="rowIndex%2==0?'even-row':'odd-row'" [class.bg-ol-request-pending]="rowData.highPriority">
            <td *ngIf="selectionCheckbox">
              <p-tableCheckbox [value]="rowData" (click)="checkboxClick($event, rowData);"></p-tableCheckbox>
            </td>
            <td [ngClass]="col?.class ? col.class : ''" *ngFor="let col of columnDefs">
              <span class="div-mi-select" *ngIf=" !col?.isDropDown && col.cellRendererHtml == undefined"
                [title]="rowData[col.field]">
                {{rowData[col.field] ? rowData[col.field]:'--'}} </span>
              <div class="div-mi-select" *ngIf="col.cellRendererHtml != undefined"
                [innerHtml]="rowData[col.field].innerHTML | keepHtml">
              </div>
              <div *ngIf="col?.isDropDown" class="grid-dropdown-section filter-drop-down width-100per">
                <p-dropdown [options]="col.options" appendTo="body" [placeholder]="'label_select' | translate"
                  [style]="{'width':'100%'}" data-action-type="DROP-DOWN">
                </p-dropdown>
                <br />
              </div>
            </td>

            <td class="grid-action-buttons" *ngIf="actionButtonsDefs.length && selectionCheckbox">
              <div class="grid-action">
                <span *ngFor="let btn of actionButtonsDefs"
                  [ngClass]="rowData[btn.field] ? 'show' : 'hide-grid-action'">
                  <div class="render-html" innerHTML="{{rowData[btn.field]}}"
                    (click)="actionBtnClick($event, rowData, btn.class)"></div>
                </span>
              </div>
            </td>

            <td class="grid-action-buttons" *ngIf="actionButtonsDefs.length && !selectionCheckbox">
              <div class="grid-action2">
                <span *ngFor="let btn of actionButtonsDefs"
                  [ngClass]="rowData[btn.field] ? 'show' : 'hide-grid-action'">
                  <div class="render-html" innerHTML="{{rowData[btn.field]}}"
                    (click)="actionBtnClick($event, rowData, btn.class)"></div>
                </span>
              </div>
            </td>

            <!-- <td [ngClass]="col?.class ? col.class : ''" *ngIf="actionButtonsDefs.length">
            <span *ngFor="let btn of actionButtonsDefs">
              <span *ngIf="rowData[btn.field] !== 'deactivated'">
                <button *ngIf="btn.conditionValue === rowData[btn.key]" (click)="actionBtnClick(rowData, btn.class)"
                  [icon]="btn.icon" [title]="btn?.label" [label]="btn?.label" [class]="btn.class" pButton
                  appRipple></button>
              </span>
            </span>
          </td> -->
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state style="position: relative;">
          <span class="total-records-summury" *ngIf="totalRecords > 0">
            Total Records: {{totalRecords}}
          </span>
        </ng-template>
      </p-table>
    </p-scrollPanel>
  </div>
</div>

<p-dialog header="Confirmation" [(visible)]="showConfirmationPopup" [style]="{width: '30vw'}">
  <div class="ui-g-12 ui-g-nopad">
    <div>
      <div class="form-label">
        <label style="font-weight: 500; width: 90%; margin: 10px;" translate>Unchecking a task will reopen this care
          task and
          associated follow-ups. Are you sure to continue with this action?</label>

      </div>
    </div>
    <div class="form-buttons ui-g-12">
      <button pButton (click)="confirmationYes();" style="width: 115px;" type="button"
        [label]="'Yes' | translate"></button>
      <button pButton (click)="confirmationNo();" style="width: 115px;" class="cancel-btn" type="button"
        [label]="'No' | translate"></button>
    </div>

  </div>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="headerConfirmation" [style]="{width: '30vw'}">
  <div class="ui-g-12 ui-g-nopad">
    <div>
      <div class="form-label">
        <label style="font-weight: 500; width: 90%; margin: 10px;" translate>Unchecking a task will reopen this care
          task and
          associated follow-ups. Are you sure to continue with this action?</label>

      </div>
    </div>
    <div class="form-buttons ui-g-12">
      <button pButton (click)="yesHeaderConfirmation();" style="width: 115px;" type="button"
        [label]="'Yes' | translate"></button>
      <button pButton (click)="noHeaderConfirmation();" style="width: 115px;" class="cancel-btn" type="button"
        [label]="'No' | translate"></button>
    </div>

  </div>
</p-dialog>