import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AppSettings } from '../../app.settings';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-booking-cancelation',
  templateUrl: './booking-cancelation.component.html'
})

export class BookingCancelationComponent implements OnInit {
  @Input() bookingID;
  @Output() isPopSave: EventEmitter<any> = new EventEmitter();
  @Output() isPopCancel: EventEmitter<any> = new EventEmitter();
  packageApiError: any = [{
    pickupLocationName: false,
    cancelReason: false
  }];

  bookingCancelform = new FormGroup({
    cancelReason: new FormControl(),
  });
  labelSave;
  labelCancel;
  label_Cancelation;
  dispositionReasonOptions: any[];

  constructor(
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private commonBindingDataService: CommonBindingDataService,
    private router: Router, ) { }

  ngOnInit() {
    this.getReasons();
    this.bookingCancelform = this.formBuilder.group({
      dispositionReasonId: ['', [Validators.required, ]],
      cancelReason: ['', [
        Validators.maxLength(200)
      ]]
    });
    this.labelSave = this.commonBindingDataService.getLabel('label_booking_cancel');
    this.labelCancel = this.commonBindingDataService.getLabel('label_close');
    this.label_Cancelation = this.commonBindingDataService.getLabel('label_Cancelation');

  }

  getReasons() {
    const type = 'PCR';
    this.commonBindingDataService.getReasons(type).subscribe((result) => {
      this.dispositionReasonOptions = [];
      this.dispositionReasonOptions.push({ label: this.commonBindingDataService.getLabel('please_select'), value: '' });
      result.reasonsList.forEach(resonse => {
        this.dispositionReasonOptions.push({
          label: resonse.reason, value: resonse.id
        });
      });
    }, (err) => {
    });
  }

  cancelBooking() {
    const data = {
      cancellationReason: this.bookingCancelform.controls.cancelReason.value,
      dispositionReasonId: this.bookingCancelform.controls.dispositionReasonId.value
    };

    this.commonBindingDataService.cancelBooking(this.bookingID, data).subscribe(results => {
      this.showMessage('success', results);
      this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      this.bookingCancelform.reset();
      const filterdata = JSON.parse(localStorage.getItem('availabilityFilters'));
      if (filterdata != null) {
        this.router.navigate(['provider-availability']);
      }
      localStorage.setItem('sdohFormDataDuringSession', null);
      localStorage.setItem('sdohFormDataDetails', null);
      localStorage.setItem('sdohFormCheckList', null);
      this.commonBindingDataService.updateMessage('update pending count');
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
      setTimeout(() => {
        this.router.navigate(['/appointments']);
      }, 1200);
    });
  }

  showMessage(severity, messageType) {
    setTimeout(() => {
      this.isPopSave.emit(messageType);
    }, 3000);
  }

  reset() {
    this.bookingCancelform.reset();
  }

  cancel() {
    this.isPopCancel.emit();
  }

}
