<div class="form-preview-container">

  <div class="ui-g form-container containar-layout add-patient-form">
    <div class="ui-g-12 ui-g-nopad pt-20 text-center">
      <img class="company-logo-background" src='/assets/images/temp/rainbowcare_logo.png' width="178px">
    </div>
    <div class="ui-g-12 dived-border" *ngIf="referralInfo">
      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-6 ui-sm-12">
          <h3 class="trip-details-header ui-g-12" translate>label_referral_details</h3>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_organization</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.organizationName">
            {{ referralInfo?.referralResourceInfo?.organizationName }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.organizationName"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_service_cat</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.resourceType">
            {{ referralInfo?.referralResourceInfo?.resourceType }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.resourceType"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_service_subcategory</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.resourceSubtype">
            {{ referralInfo?.referralResourceInfo?.resourceSubtype }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.resourceSubtype"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_program_details</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.description">
            {{ referralInfo?.referralResourceInfo?.description }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.description"> -- </div>
        </div>
      </div>
    </div>

    <div class="ui-g-12" *ngIf="referralInfo">
      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-6 ui-sm-12">
          <h3 class="trip-details-header ui-g-12" translate>label_contact_details</h3>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_number</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.phoneNum">
            <a href="tel: {{referralInfo.referralResourceInfo.phoneNum}}">{{ referralInfo.referralResourceInfo.numCountryCode }}
              {{ setPhoneNumberFormate(referralInfo.referralResourceInfo.phoneNum) }}</a>
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.phoneNum"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>address</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.addressLine1">
            {{ setFullAddress(referralInfo.referralResourceInfo) }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.addressLine1"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_email</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.email">
            <a href="mailto:{{referralInfo?.referralResourceInfo?.email}}">{{ referralInfo?.referralResourceInfo?.email }}</a>
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.email"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>fax</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.fax">
            {{ referralInfo?.referralResourceInfo?.fax }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.fax"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>web</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.websiteLink">
            <a href="{{referralInfo.referralResourceInfo.websiteLink}}" target="_blank" class="doc-link">{{ referralInfo.referralResourceInfo.websiteLink }}</a>
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.websiteLink"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_days_of_operation</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.daysOfOperation">
            {{ referralInfo?.referralResourceInfo?.daysOfOperation }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.daysOfOperation"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_hours_of_operation</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.hoursOfOperation">
            {{ referralInfo?.referralResourceInfo?.hoursOfOperation }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.hoursOfOperation"> -- </div>
        </div>
      </div>

      <div class="ui-g-12 ui-sm-12">
        <div class="ui-g-3 ui-sm-6">
          <div class="trip-details-lable" translate>label_point_of_contact</div>
        </div>
        <div class="ui-g-8 ui-sm-6">
          <div class="trip-details-value" *ngIf="referralInfo?.referralResourceInfo?.contactPersonName">
            {{ setContactPersonDetails(referralInfo?.referralResourceInfo) }}
          </div>
          <div class="trip-details-value" *ngIf="!referralInfo?.referralResourceInfo?.contactPersonName"> -- </div>
        </div>
      </div>
    </div>
  </div>
</div>
