import { AppSettings } from './../app.settings';
import { Directive, ElementRef, EventEmitter, Output, Input, OnChanges } from '@angular/core';
declare const google: any;
@Directive({
  selector: '[appSearchLocation]'
})
export class SearchLocationDirective implements OnChanges {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Input() onlyCity: Boolean = false;
  private _el: HTMLElement;
  autocomplete: any;
  constructor(el: ElementRef) {
    this._el = el.nativeElement;
    const input = this._el;
    this.autocomplete = new google.maps.places.Autocomplete(input, {});
    // console.log('INput'+input['value'])
    this.autocomplete.setComponentRestrictions(
      { 'country': AppSettings.SUPPORTED_COUNTRY }
    );
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      // this.autocomplete.setComponentRestrictions({
      //   country: ['us'],
      // });
      try {


        // const placeObj = {
        //   'name': place.name,
        //   'addressObj': place.address_components,
        //   'address': input['value'],
        //   'lat': place.geometry.location.lat(),
        //   'lng': place.geometry.location.lng(),
        //   'id': input.id
        // };

        // const indexNum = place.address_components.findIndex(obj => obj.types[0] === 'locality');
        const indexNum1 = place.address_components.findIndex(obj => obj.types[0] === 'administrative_area_level_2');
        const indexNum2 = place.address_components.findIndex(obj => obj.types[0] === 'administrative_area_level_1');
        const indexNum3 = place.address_components.findIndex(obj => obj.types[0] === 'street_number');
        const indexNum4 = place.address_components.findIndex(obj => obj.types[0] === 'route');
        const indexNum5 = place.address_components.findIndex(obj => obj.types[0] === 'locality');
        const indexNum6 = place.address_components.findIndex(obj => obj.types[0] === 'country');
        const indexNum7 = place.address_components.findIndex(obj => obj.types[0] === 'postal_code');
        const indexNum8 = place.address_components.findIndex(obj => obj.types[0] === 'floor');
        const indexNum9 = place.address_components.findIndex(obj => obj.types[0] === 'neighborhood' || obj.types[0] === 'political');


        // const indexNum3 = place.address_components.findIndex(obj => obj.types[0] === 'sublocality_level_1');
        // const indexNum4 = place.address_components.findIndex(obj => obj.types[0] === 'sublocality_level_2');
        // const indexNum5 = place.address_components.findIndex(obj => obj.types[0] === 'sublocality_level_3');

        // const locality = indexNum >= 0 ? place.address_components[indexNum].long_name + ',' : '';
        const administrative_area_level_2 = indexNum1 >= 0 ? place.address_components[indexNum1].long_name : '';
        const administrative_area_level_1 = indexNum2 >= 0 ? place.address_components[indexNum2].long_name : '';
        const street_number = indexNum3 >= 0 ? place.address_components[indexNum3].long_name : '';
        const route = indexNum4 >= 0 ? place.address_components[indexNum4].long_name : '';
        const locality = indexNum5 >= 0 ? place.address_components[indexNum5].long_name : '';
        const country = indexNum6 >= 0 ? place.address_components[indexNum6].long_name + ',' : '';
        const postal_code = indexNum7 >= 0 ? place.address_components[indexNum7].long_name : '';
        const floor = indexNum8 >= 0 ? place.address_components[indexNum8].long_name + ',' : '';
        const neighborhood = indexNum9 >= 0 ? place.address_components[indexNum9].long_name : '';

        // const sublocality_level_1 = indexNum3 >= 0 ? place.address_components[indexNum3].long_name + ',' : '';
        // const sublocality_level_2 = indexNum4 >= 0 ? place.address_components[indexNum4].long_name + ',' : '';
        // const sublocality_level_3 = indexNum5 >= 0 ? place.address_components[indexNum5].long_name + ',' : '';

        const placeObj = {
          'name': place.name,
          'addressObj': '',
          // 'address': place.address_components[indexNum].long_name,
          'address': street_number,
          'address1': route,
          'address2': neighborhood,
          'city': locality,
          'zip_code': postal_code,
          'county': administrative_area_level_2,
          'state': administrative_area_level_1,
          // 'country':country,
          // 'state':administrative_area_level_1,
          'lat': place.geometry.location.lat(),
          'lng': place.geometry.location.lng(),
          'id': input.id
        };

        this.invokeEvent(placeObj);
      } catch (ex) {
        console.warn(ex);

      }

    });

  }

  ngOnChanges() {
    if (this.onlyCity) {
      this.autocomplete.setOptions({ types: ['(cities)'] });
    }
  }
  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
}
