<div class="signin-box-container ui-g">
  <div class="signin-box-inner-container">
    <div class="signin-box ui-md-4 ui-md-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field ui-g-12 ui-g-nopad">
          <div class="form-field ui-g-12 ui-g-nopad text-center">
            <img class="company-logo-background" src='./assets/images/temp/rainbowcare_logo.png'>
          </div>
          <div class="form-field ui-g-12 switch-btn" style="text-align: center;" translate>label_password_set_successfully
          </div>
          <div class="form-field ui-g-12"></div>
        </div>
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12 app-link">
          <div class="app-link-mobile">Onsite providers and Patients please navigate to 'RainbowCare' App to login</div>
          <div class="app-link-web">Hybrid Providers, Staff users and Remote providers <a href="./#/signin">click here</a> to login</div>
        </div>
        <div class="remove-float"></div>
      </div>

    </div>
  </div>
</div>