import { NavigationEnd, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { AppSettings } from './../../app.settings';
import { UiService } from '../../services/ui.service';
import { MenuItem } from 'primeng/api';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { StorageService } from './../../services/storage.service';
import { UserCommonService } from './../../services/user-common.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { NotificationsService } from './../../services/notifications.service';
import { EditProfileComponent } from './../edit-profile/edit-profile.component';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { MessagingService } from '../../services/messaging.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(EditProfileComponent) editPro: EditProfileComponent;
  notificationInterval;
  userFullName: string;
  items: MenuItem[];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  notifications: any = [];
  notificationCount: any;
  unReadNotificatonsCount = 0;
  unReadNotificatonsGreaterCount = '99+';
  limit = 8;
  offset = 0;
  showMoreOff = true;
  hasShowNotificationBar = false;
  notificationLabel;
  noNotificationFoundLabel;
  secondsCounter: any;
  logoutSession: any;
  headerConfirmDialog = false;
  headerMessage;
  showDualBookingBtn: boolean;
  label_pending_approval_lbl: any;
  pendingCount: string;
  disablePendingApproval: boolean;
  adminUser = false;
  subscriptionToPendingApproval: Subscription;
  saveAppointmentDraft: any;
  fcmSubscription: Subscription;
  isClientStaffRoleUser: boolean;
  defaultMarket: any;
  pendingAlertsCount: any;
  label_pending_alerts_count: any;
  pendingAlertscountLabel: string;
  dispatchRequestAccess: any;

  constructor(
    private uiService: UiService,
    private userCommandService: UserCommonService,
    private router: Router,
    private commonService: CommonBindingDataService,
    private messagingService: MessagingService,
    private storageService: StorageService,
    private notificationsService: NotificationsService) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('/dashboard') || event.url.includes('/patients') ||
          event.url.includes('/hc-providers') || event.url.includes('/appointments') ||
          event.url.includes('/provider-availability')) {
          this.showDualBookingBtn = true;
        } else {
          this.showDualBookingBtn = false;
        }
      });
  }

  ngOnInit() {
    this.defaultMarket = this.storageService.getItemLocalStorage(AppSettings.USER_DEFAULT_MARKET);
    const userInfo: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    this.isClientStaffRoleUser = userInfo.roles[0].id === AppSettings.CLIENT_STAFF_ROLE_ID;
    this.saveAppointmentDraft = localStorage.getItem('saveAppointmentDraft');
    this.dispatchRequestAccess = this.storageService.getItemLocalStorage('dispatchRequestAccess');
    this.subscriptionToPendingApproval = this.commonService.getMessage().subscribe(msg => {
      if (msg === 'update pending count') {
        this.getNotificationUnReadcountCount();
      }
    });
    this.getUserMetaData();
    this.setSessionInterval();
    this.notificationLabel = this.commonService.getLabel('label_notifications');
    this.label_pending_approval_lbl = this.commonService.getLabel('label_pending_approval_lbl');
    this.label_pending_alerts_count = this.commonService.getLabel('label_pending_alerts_count');
    this.noNotificationFoundLabel = this.commonService.getLabel('label_no_notification_found');

    this.items = [
      {
        label: 'Edit Profile', icon: 'fa fa-user-circle-o', command: (event) => {
          this.editProfile();
        }
      },
      {
        label: 'Change Password', icon: 'fa  fa-key', command: (event) => {
          this.changePassword();
        }
      },
      {
        label: 'Support', icon: 'fa fa-cog', command: (event) => {
          this.toSupport();
        }
      },
      {
        label: 'Logout', icon: 'fa fa-sign-out', command: (event) => {
          this.doSignOut();
        }
      }
    ];

    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userFullName = userObj.firstName + ' ' + userObj.lastName;
      if (userObj.userDetails !== undefined && userObj.userDetails !== null
        && userObj.userDetails.profileImage !== null && userObj.userDetails.profileImage !== '') {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
      } else {
        this.profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
      }
    }

    this.subscription = this.uiService.profileUpdatedEvent
      .subscribe((state: string) => {
        this.updateProfileContent();
      });

    this.notificationProcessInitialization();
    this.subscribeFCMNotification();

  }

  getUserMetaData() {
    const interval = +this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
    if (interval && interval > 0) {
      this.userCommandService.getUserMasterMetaData();
    }
  }

  notificationProcessInitialization() {
    this.getNotificationUnReadcountCount();
    this.notificationsService.startFetchingNotifications(() => {
      this.getNotificationUnReadcountCount();
    });
  }

  getNotificationUnReadcountCount() {
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj.roles[0].roleName === 'System Admin' || userObj.roles[0].roleName === 'Admin') {
      this.adminUser = true;
    }
    this.notificationsService.getNotificationCount(userObj.id).subscribe(data => {
      this.commonService.notificationData.next(data);
      this.unReadNotificatonsCount = data.unReadcount;
      this.notificationsService.currentCount.next(data.unreadMessageCount);
      this.disablePendingApproval = true;
      const count = data.pendingApprovalcount;
      this.pendingCount = count + ' ' + this.label_pending_approval_lbl;
      if (count === 0) {
        this.disablePendingApproval = false;
        // setTimeout(() => {
        //   const myHtmlEl = document.getElementsByClassName('pending-btn').item(0) as HTMLElement;
        //   myHtmlEl.style.animation = 'none';
        // }, 300);
      }
      this.pendingAlertsCount = data?.pendingAlertsCount;
      this.pendingAlertscountLabel = this.pendingAlertsCount + ' ' + this.label_pending_alerts_count;
    });
  }

  subscribeFCMNotification() {
    this.fcmSubscription = this.messagingService.getPushMessage().subscribe((response: any) => {
      let messageType = '';
      messageType = response.notification.title.trim();

      if (messageType === 'RainbowCare Messages') {
        // this.createAnimatedElement();
        this.notificationsService.stopFetchingNotifications();
        this.notificationProcessInitialization();
      }
    });
  }

  getNotificationListAndToggle(event) {
    this.unReadNotificatonsCount = 0;
    this.limit = 8;
    this.offset = 0;
    this.notifications = [];
    this.getNotificationList();
    this.hasShowNotificationBar = true;
  }

  getNotificationList() {
    this.notificationsService.getDetails(this.limit, this.offset).subscribe(notification => {
      if (notification.notificationList.length > 0) {
        // this.notificationCount = notification.count;
        this.offset = this.offset + notification.notificationList.length;
        this.notifications = [...this.notifications, ...notification.notificationList];
        this.limitReached(notification);
      } else {
        this.uiService.showMessage(this.noNotificationFoundLabel, AppSettings.MESSAGE_TYPES.ERROR);
      }
    });
  }

  limitReached(notification) {
    const currentcount = this.limit + this.notifications.length;
    if (notification.notificationList.length === 0) {
      this.showMoreOff = true;
    } else {
      this.showMoreOff = false;
    }
  }

  loadMoreNotification() {
    this.getNotificationList();
  }

  onSideMenuClick(event: any) {
    event.preventDefault();
    document.getElementsByTagName('body')[0].classList.toggle('closed');
    this.uiService.sidebarToggled();
  }

  doSignOut() {
    this.logout();
  }

  logout() {
    this.userCommandService.signOut().subscribe(results => {
      this.storageService.removeAllCookies();
      this.storageService.removeAll();
      this.notificationsService.stopFetchingNotifications();
      this.notificationsService.canFetchNotification = false;
      this.headerConfirmDialog = false;
      this.router.navigate(['/signin']);
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  updateProfileContent() {
    this.userCommandService.getUserProfile().subscribe(results => {
      this.storageService.setItem(AppSettings.USER_DETAILS, results);
      AppSettings.USER = results;
      this.userFullName = results.firstName + ' ' + results.lastName;
      if (results.userDetails !== undefined && results.userDetails !== null && results.userDetails.profileImage !== null) {
        this.profileUrl = AppSettings.GET_FILE_THUMB_URL + results.userDetails.profileImage;
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  changePassword() {
    this.changePasswordPopup = true;
  }

  editProfile() {
    this.editProfilePopup = true;
    this.editPro.setData();
  }

  toSupport() {
    const host = window.location.origin;
    const url = `${host}/#/support`;
    window.open(url, '_blank');
  }

  onCancelEditProfile(event: any) {
    this.editProfilePopup = !this.editProfilePopup;
  }

  onCancelChangePassword(event: any) {
    this.changePasswordPopup = !this.changePasswordPopup;
  }

  setSessionInterval() {
    const sessionInterval = timer(10000, 1000);
    this.secondsCounter = sessionInterval.subscribe(n => {
      const idleSessionTime = +this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
      if (idleSessionTime) {
        if (idleSessionTime < new Date().getTime()) {
          this.confirmYouThere();
          this.secondsCounter.unsubscribe();
        } else {
          clearTimeout(this.logoutSession);
        }
      }
    });
  }

  confirmYouThere() {
    this.sessionTimeout();
    this.headerConfirmDialog = true;
    this.headerMessage = this.commonService.getLabel('lbl_confirm_idle_session');
  }

  sessionTimeout() {
    this.logoutSession = setTimeout(() => {
      const idleSessionTime = +this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
      if (idleSessionTime < new Date().getTime()) {
        this.logout();
      } else {
        clearTimeout(this.logoutSession);
      }
    }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
  }

  refreshSession() {
    const idleLogOutTime = this.storageService.getItem('idleTime');
    const idleTime = '' + moment().add((idleLogOutTime), 'm').valueOf();
    this.storageService.setLocalStorage(AppSettings.INTERVAL, idleTime);
    clearTimeout(this.logoutSession);
    this.userCommandService.sesionRefresh().subscribe(() => { });
    this.setSessionInterval();
    this.headerConfirmDialog = false;
  }

  cancel() {
    this.headerConfirmDialog = false;
  }

  ngOnDestroy() {
    this.notificationsService.stopFetchingNotifications();
    this.subscription.unsubscribe();
    this.secondsCounter.unsubscribe();
    clearTimeout(this.logoutSession);
    this.storageService.removeAllCookies();
  }

  gotoBookDualAppt() {
    this.router.navigate(['/appointments/book-dual-appointment']);
  }

  gotoAppointments() {
    if (this.router.url === '/appointments') {
      this.commonService.updatePendingFilter('set pending filter');
    } else {
      localStorage.setItem('isPendingApproval', 'true');
    }
    this.router.navigate(['/appointments']);
  }

  gotoDispatchRequest() {
    this.router.navigate(['/dispatch-requests']);
  }

}
