import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';
import { FileUpload } from 'primeng';
import { LoaderService } from 'app/modules/shared/components/loader/loader.service';
import { HttpHeaders } from '@angular/common/http';
import { StorageService } from 'app/modules/shared/services/storage.service';

@Component({
  selector: 'app-referral-form-scott',
  templateUrl: './referral-form-scott.component.html',
  styleUrls: ['./referral-form-scott.component.scss']
})
export class ReferralFormScottComponent implements OnInit {

  @ViewChild('fileInput') fileInput: FileUpload;
  Form = new FormGroup({});
  genderList = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Choose not to disclose', value: 3 }
  ];
  statelist = [];
  defaultState = '';
  dateFormat: any;
  minDate: Date;
  expiryDateRange: string;
  issueDateRange: string;
  maxDate: Date;
  latitude: any;
  longitude: any;
  countryCodeList = AppSettings.COUNTRY_CODE;
  chronicConditionDataList: any[] = [];
  displayBtn = false;
  submitted = false;
  submittedMsg: any;
  fileUploadUrl: string = AppSettings.PUBLIC_UPLOAD_FILE_URL;
  type: string;
  docFileName: any;
  docPDFId: any;
  maxSizeMessage;
  invalidFileMessage;
  displayUpload = false;
  fileName = '';
  secretKey: string = AppSettings.PRIVATE_KEY_FOR_UPLOAD_FILE;
  trackerId: any;
  httpHeaders: HttpHeaders;
  tenentValidation = [];
  maxNotesCharAllowed = 5000;
  organizationName: any;
  programField: any;
  programsList: any[] = [];
  referralSourceField: any;
  referralSourceList: any[] = [];
  primaryCronicConditionField: any;

  constructor(
    private formBuilder: FormBuilder,
    private commonBindingDataService: CommonBindingDataService,
    private uiService: UiService,
    private commonServices: UserCommonService,
    private loaderService: LoaderService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.getMetadata();
    this.tenentValidation = this.storageService.getItemLocalStorage(AppSettings.TENENT_VALIDATION);
    this.tenentValidation?.forEach(element => {
      if (element.configKey === 'notes_limit') {
        this.maxNotesCharAllowed = parseInt(element.configValue, 10);
      }
    });
    this.dateFormat = this.commonBindingDataService.getDateFormat();
    const prevMonth = (new Date().getMonth() === 0) ? 11 : new Date().getMonth() - 1;
    const prevYear = (prevMonth === 11) ? new Date().getFullYear() - 1 : new Date().getFullYear();
    const nextMonth = ((new Date().getMonth()) === 11) ? 0 : (new Date().getMonth()) + 1;
    const nextYear = (nextMonth === 0) ? (new Date().getFullYear()) + 1 : (new Date().getFullYear());
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
    this.expiryDateRange = prevYear + ':' + (nextYear + 20);
    this.issueDateRange = (prevYear - 150) + ':' + (nextYear);
    this.validateForm();
    // this.setFileUploadKeys();
    setTimeout(() => {
      this.checkFieldVisibility();
    }, 600);
  }


  setFileUploadKeys(guestUserId) {
    const key = guestUserId;
    const k1 = key?.substring(0, 16);
    const k2 = key?.substring(16, 32);
    const finalKey = k2 + k1;
    return finalKey;
  }

  validateForm() {
    this.Form = this.formBuilder.group({
      firstName: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern(AppSettings.PATIENT_NAME_PATTERN)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern(AppSettings.PATIENT_NAME_PATTERN)
      ]],
      gender: ['', [Validators.required]],
      dateOfBirth: ['', []],
      address1: ['', [
        // Validators.required,
        Validators.maxLength(200)
      ]],
      address2: ['', [
        Validators.maxLength(200),
        Validators.pattern(AppSettings.EQUALTO_PATTERN)
      ]],
      city: ['', [
        // Validators.required,
        Validators.pattern(AppSettings.EQUALTO_PATTERN)
      ]],
      state: [this.defaultState, [
        // Validators.required,
        Validators.pattern(AppSettings.EQUALTO_PATTERN)
      ]],
      zipcode: ['', [
        // Validators.required,
        Validators.minLength(1),
        // Validators.maxLength(10),
        Validators.pattern(AppSettings.NUMBER_PATTERN),
        Validators.pattern(AppSettings.ZIP_CODE_US)
      ]],
      phoneNum: ['', [
        // Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
        // Validators.pattern(AppSettings.PHONE_PATTERN)
      ]],
      numCountryCode: ['+1', [
        // Validators.required,
        Validators.pattern(AppSettings.PHONE_CODE)
      ]],
      email: ['', [
        // Validators.required,
        Validators.pattern(AppSettings.EMAIL_PATTERN),
        Validators.maxLength(100),
      ]],
      referralReason: ['', [
        Validators.required,
      ]],
      primaryCronicCondition: ['', []],
      otherCronicCondition: ['', []],
      additionalNotes: ['', [Validators.maxLength(this.maxNotesCharAllowed)]],
      personName: ['', []],
      referralPhoneNum: ['', [
        // Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
        // Validators.pattern(AppSettings.PHONE_PATTERN)
      ]],
      referralNumCountryCode: ['+1', [
        // Validators.required,
        Validators.pattern(AppSettings.PHONE_CODE)
      ]],
      referralPhoneNumExt: ['', []],
      referralEmail: ['', [
        // Validators.required,
        Validators.pattern(AppSettings.EMAIL_PATTERN),
        Validators.maxLength(100),
      ]],
      referralFileName: ['', []],
      referralFileId: ['', []],
      referralFileType: ['', []],
      agencyName: ['', []],
      programId: ['', []],
      referralSourceTypeId: ['', []],
    });
  }

  checkFieldVisibility() {
    if (this.organizationName?.visible) {
      this.Form.controls.agencyName.setValidators([
        Validators.minLength(this.organizationName?.validations?.min_length),
        Validators.maxLength(this.organizationName?.validations?.max_length),
        Validators.pattern(this.organizationName?.validations?.pattern)
      ]);
      if (this.organizationName?.validations?.required) {
        this.Form.controls.agencyName.setValidators([Validators.required]);
      }
      this.Form.controls.agencyName.updateValueAndValidity();
    }

    if (this.programField?.visible) {
      if (this.programField?.validations?.required) {
        this.Form.controls.programId.setValidators([Validators.required]);
      }
      this.Form.controls.programId.updateValueAndValidity();
    }

    if (this.referralSourceField?.visible) {
      if (this.referralSourceField?.validations?.required) {
        this.Form.controls.referralSourceTypeId.setValidators([Validators.required]);
      }
      this.Form.controls.referralSourceTypeId.updateValueAndValidity();
    }

    if (this.primaryCronicConditionField?.visible) {
      if (this.primaryCronicConditionField?.validations?.required) {
        this.Form.controls.primaryCronicCondition.setValidators([Validators.required]);
      }
      this.Form.controls.primaryCronicCondition.updateValueAndValidity();
    }
  }

  setLocationPoint(event) {
    this.Form.controls.address1.setValue(`${event.address} ${event.address1}`);
    this.Form.controls.address2.setValue(event.address2);
    this.Form.controls.zipcode.setValue(event.zip_code);
    this.Form.controls.city.setValue(event.city);
    this.Form.controls.county.setValue(event.county);
    this.Form.controls.state.setValue(event.state);

    if (this.Form.controls.zipcode.status === 'INVALID') {
      this.Form.controls.zipcode.markAsDirty();
    }
    const address1 = this.Form.controls.address1.value;
    this.latitude = event.lat;
    this.longitude = event.lng;
  }



  getMetadata() {
    this.commonServices.getPublicMetadata('online_referral').subscribe(results => {
      this.trackerId = results.trackerId;
      // this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      results.tags.patient_cronic_conditions.forEach(element => {
        this.chronicConditionDataList.push({ label: element.tagValue, value: element.id });
      });

      results.stateList.forEach(element => {
        this.statelist.push({ label: element.stateName.trim(), value: element.stateName.trim() });
        if (element.stateName.trim() === 'Florida') {
          this.defaultState = element.stateName.trim();
        }
      });
      this.statelist.push({ label: 'Please Select', value: '' });
      this.statelist.reverse();

      this.programsList = [];
      results?.tags?.programs?.forEach(element => {
        this.programsList.push({ label: element.tagValue.trim(), value: element.id.trim() });
      });
      this.referralSourceList = [];
      results?.tags?.pt_referral_source_type?.forEach(element => {
        this.referralSourceList.push({ label: element.tagValue.trim(), value: element.id.trim() });
      });
      const inboundReferralFields = JSON.parse(results?.inboundReferralFields);
      this.organizationName = inboundReferralFields.find(ele => ele.name === 'organizationName');
      this.programField = inboundReferralFields.find(ele => ele.name === 'programId');
      this.referralSourceField = inboundReferralFields.find(ele => ele.name === 'referralSourceTypeId');
      this.primaryCronicConditionField = inboundReferralFields.find(ele => ele.name === 'primaryChronicConditionsList');
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });

  }

  setCustomHeaders() {
    const headers = new HttpHeaders();
    headers.set('guestUserId', this.setFileUploadKeys(this.trackerId));
    this.httpHeaders = headers;
  }

  changeChronicCondition(e) {
    this.Form.controls.otherCronicCondition.setValue('');
  }

  submit() {
    if (this.fileName) {
      this.finalUpload();
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    if (this.Form.controls.referralReason.value.trim().length === 0) {
      this.uiService.showMessage('Referral reason is required', AppSettings.MESSAGE_TYPES.ERROR);
      return;
    }
    this.displayBtn = true;
    const dataModel = this.getDataModel();
    this.commonServices.submitReferralForms(dataModel).subscribe(results => {
      // this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      this.displayBtn = false;
      this.submitted = true;
      this.submittedMsg = results.general[0].message;
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
      this.displayBtn = false;
    });

  }

  getDataModel() {
    const primaryChronicConditionsList = [];
    if (this.Form.controls.primaryCronicCondition.value) {
      this.Form.controls.primaryCronicCondition.value?.forEach(element => {
        const obj = {
          'primaryChronicConditionId': element
        };
        primaryChronicConditionsList.push(obj);
      });
    }
    const model: any = {
      // 'memberId': '130',
      'firstName': this.Form.controls.firstName.value,
      // 'middleName': this.Form.controls.mname.value,
      'lastName': this.Form.controls.lastName.value,
      // 'dateOfBirth': new Date(this.Form.controls.dateOfBirth.value).getTime(),
      'dateOfBirth': this.commonBindingDataService.toGMT(new Date(this.Form.controls.dateOfBirth.value)),
      'numCountryCode': this.Form.controls.numCountryCode.value,
      'phoneNum': this.phoneNumberFormat(this.Form.controls.phoneNum.value),
      'email': this.Form.controls.email.value,
      'gender': this.Form.controls.gender.value,
      'guestUserId': this.setFileUploadKeys(this.trackerId),
      'userDetails': {
        'addressLine1': this.Form.controls.address1.value,
        // 'addressLine2': this.Form.controls.address2.value,
        'city': this.Form.controls.city.value,
        // 'addressLine3': this.Form.controls.state.value,
        'state': this.Form.controls.state.value,
        'zipcode': this.Form.controls.zipcode.value,
        'latitude': this.latitude,
        'longitude': this.longitude,
        // 'primaryCronicConditionId': this.Form.controls.primaryCronicCondition.value,
        'otherCronicCondition': this.Form.controls.otherCronicCondition.value,
      },
      'referralInfo': {
        'name': this.Form.controls.personName.value,
        'otherPrimaryReferralReason': this.Form.controls.referralReason.value,
        'additionalNotes': this.Form.controls.additionalNotes.value,
        'referralEmail': this.Form.controls.referralEmail.value,
        'referralNumCountryCode': this.Form.controls.referralNumCountryCode.value,
        'referralPhoneNum': this.Form.controls.referralPhoneNum.value,
        'referralPhoneNumExt': this.Form.controls.referralPhoneNumExt.value,
        'referralFileId': this.Form.get('referralFileId').value,
        'referralFileType': this.Form.get('referralFileType').value
      },
      'primaryChronicConditionsList': primaryChronicConditionsList,
      'programId': this.Form.controls.programId.value,
      'organizationName': this.Form.controls.agencyName.value,
      'referralSourceTypeId': this.Form.controls.referralSourceTypeId.value,
    };

    return model;
  }

  phoneNumberFormat(data) {
    if (data) {
      return data.split('-').join('');
    }
  }

  doValidSave() {
    if (this.Form.valid) {
      return false;
    } else {
      return true;
    }
  }

  onUploadDocumentPDF(response) {
    this.docFileName = response.originalFileName;
    this.docPDFId = response.fileId;
    if (this.docPDFId) {
      this.UploadPDFDoc();
    }
  }

  UploadPDFDoc() {
    if (this.docPDFId.toLowerCase().includes('.pdf')) {
      this.type = 'Pdf';
    } else if (this.docPDFId.toLowerCase().includes('.xls') || this.docPDFId.toLowerCase().includes('.xlsx')) {
      this.type = 'Excel';
    } else if (this.docPDFId.toLowerCase().includes('.doc') || this.docPDFId.toLowerCase().includes('.docx') || this.docPDFId.toLowerCase().includes('.txt')) {
      this.type = 'Document';
    } else if (this.docPDFId.toLowerCase().includes('.ppt') || this.docPDFId.toLowerCase().includes('.pptx')) {
      this.type = 'Ppt';
    } else {
      this.type = 'Image';
    }

    this.Form.controls.referralFileType.setValue(this.type);


  }

  onFileSelected(event) {
    if (event.files[0].name.toString().length >= 45) {
      const fileName = event.files[0].name.toString().substring(0, 45);
      const fileExtension = event.files[0].name.toString().substring(event.files[0].name.toString().length - 4, event.files[0].name.toString().length);
      this.Form.controls.referralFileName.setValue(fileName + fileExtension);
    } else {
      this.Form.controls.referralFileName.setValue(event.files[0].name);
    }
    this.fileName = this.Form.controls.referralFileName.value;
    this.displayUpload = true;
  }

  deleteCurrentDocument() {
    // this.fileuploaded = false;
    this.fileName = '';
    this.Form.controls.referralFileName.setValue('');
    this.displayUpload = false;
  }

  onUpload(event) {
    this.docPDFId = event.originalEvent.body.fileId;
    this.loaderService.showSecond('true');
    const response = event.originalEvent.body;
    if (response.originalFileName.toString().length >= 45) {
      const fileName = response.originalFileName.toString().substring(0, 45);
      const fileExtension = event.files[0].name.toString().substring(response.originalFileName.toString().length - 4, response.originalFileName.toString().length);
      this.docFileName = fileName + fileExtension;
    } else {
      this.docFileName = response.originalFileName;
    }
    this.Form.controls.referralFileId.setValue(response.fileId);
    this.docPDFId = response.fileId;
    if (this.docPDFId) {
      this.UploadPDFDoc();
    }
    this.loaderService.hideSecond();
    // this.hideUploadPopUp();
    this.submitForm();
  }

  onBeforeUpload() {
    if (this.Form.controls.referralFileName.value === '') {
      this.uiService.showMessage('Document Name is Required', AppSettings.MESSAGE_TYPES.ERROR);
    } else {
      this.loaderService.showSecond('true');
    }
  }

  onBeforeSend(event) {
    this.loaderService.showSecond('true');
  }

  getHttpClientHeaders(): HttpHeaders {
    const guestUserId = this.setFileUploadKeys(this.trackerId);
    return new HttpHeaders({
      'guestUserId': guestUserId,
    });
  }

  onUploadError(event: any) {
    this.uiService.showMessage(event.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    this.loaderService.hide();
  }

  finalUpload() {
    this.fileInput.upload();
  }

}
