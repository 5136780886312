import { Component, OnInit, OnDestroy, ElementRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng';
import { Grid2Service } from './grid2.service';
import { Grid2Model } from './grid-2-model';
import { Table } from 'primeng/table';
import { AppSettings } from '../../app.settings';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-mi-grid-v2',
  providers: [Grid2Service],
  templateUrl: './mi-grid-2.component.html'
})

export class MiGrid2Component implements OnInit, OnDestroy {
  subscription: Subscription;
  rowData: any[] = [];
  search = '';
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() totalCount = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() dropdownEvent: EventEmitter<any> = new EventEmitter();
  // @Output() fieldsData: EventEmitter<any> = new EventEmitter();
  @Output() columnVisibility: EventEmitter<any> = new EventEmitter();
  @Output() columnConfigChange: EventEmitter<any> = new EventEmitter();
  @Input() filterType: string;
  @Input() gridData: any[];
  @Input() url: any = '';
  @Input() bottomSearchBar: Boolean;
  @Input() isDetailScreen: Boolean;
  @Input() bottomExportButton = false;
  @Input() actionWidth = 130;
  @Input() rowSelection = 'single';
  @Input() printUrl: any = '';
  @Input() exportUrl: any = '';
  @Input() emailUrl: any = '';
  @Input() exportCSVUrl: any = '';
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = 15;
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = '';
  @Input() divClass = '';
  @Input() placeholderValue = 'Press enter to search';
  @ViewChild('dt') dt: Table;
  @ViewChild('op') overlayPanel;
  @Input() staticGridData = [];
  @Input() isFilterDataCalled: boolean;
  @Input() isFilterSaveScreen = false;
  @Input() isDownloadExcel;
  @Input() isRangeApplicable;
  queryParams: any = '';
  enableResizer = 'false';
  @Input() overlayLoadingTemplate: any = '<span class="ag-overlay-loading-center b-red">' +
    'Please wait while your rows are loading</span>';
  datasource = [];
  model: Grid2Model;
  totalRecords = 0;
  sortF = '';
  first: any = 0;
  sortO = '';
  offset = 0;
  setRowLength = 10;
  searchText = '';
  selectedRecords: any = [];
  gridArray: any = [];
  obj: any;
  draggingIndex: number;
  ref: any;
  isComponentInitialised = false;
  referralStatusList: any[] = [];
  selectedStatus: any[] = [];

  constructor(private elm: ElementRef,
    private uiService: UiService,
    private gridService: Grid2Service,
    private router: Router,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    private confirmationService: ConfirmationService, ) { }

  ngOnInit() {
    this.subscription = this.uiService.sidebarToggledEvent
      .subscribe((state: string) => {
        this.refreshList();
      });
    // To check if the columndefs array is loaded from the column visibility feature from the respective component
    this.ref = this.commonBindingDataService.isGridDataCalled.subscribe((res) => {
      // Conditon to check if the grid is having save filter implementation or not
      if (this.isFilterSaveScreen) {
        this.isFilterDataCalled && this.fetchAndLoadData();
      } else {
        this.fetchAndLoadData();
      }
    });
  }

  onDragStart(fromIndex: number): void {
    this.draggingIndex = fromIndex;
  }

  onDragEnter(toIndex: number): void {
    if (this.draggingIndex !== toIndex) {
      this._reorderItem(this.draggingIndex, toIndex);
    }
  }

  _reorderItem(fromIndex: number, toIndex: number): void {
    const itemToBeReordered = this.columnDefs.splice(fromIndex, 1)[0];
    this.columnDefs.splice(toIndex, 0, itemToBeReordered);
    this.draggingIndex = toIndex;
  }

  onDragEnd(): void {
    this.draggingIndex = undefined;
  }

  onResize(event: any) {
    // this.refreshList();
  }

  refreshList() {
    this.fetchAndLoadData();
  }

  fetchAndLoadData() {
    this.isComponentInitialised = true;
    const that = this;
    this.gridService.loadGridData(that.getUrl(that.url)).subscribe((result: any) => {
      if (result?.data.length && result.data[0]?.status) {
        result.data.forEach((list, i) => {
          this.selectedStatus[i + this.offset] = list.status;
        });
      }
      const model = <Grid2Model>result;
      // this.fieldsData.emit(model);
      if (result !== undefined && this.columnDefs.length > 0) {
        this.totalRecords = model.count;
        that.datasource = [];

        if (this.staticGridData.length > 0) {
          model.data = this.staticGridData;
        }
        model.data.forEach(e => {
          that.columnDefs.forEach(c => {
            if (c && c.cellRenderer) {
              e[c.field] = c.cellRenderer(e[c.field]);
            } else if (c && c.cellRendererHtml) {
              e[c.field] = c.cellRendererHtml(e);
            }

            // that.actionButtonsDefs.forEach(d => {
            //   if (d.show) {
            //     e[d.field] = d.show(e);
            //   }
            // });

            that.actionButtonsDefs.forEach(d => {
              if (d.show) {
                e[d.field] = d.show(e);
              }
              if (d.title) {
                e[d.title] = d.title(e);
              }
            });
          });
          that.datasource.push(e);
        });
      }
      this.totalCount.emit(this.totalRecords);
      this.dt.first = this.offset;
    });
  }

  getUrl(url) {
    const that = this;
    let params = 'searchText=' + this.searchText + '&offset=' + this.offset + '&limit=' + this.setRowLength;
    params += '&sortColumn=';
    params += this.sortF;
    params += '&sortType=';
    params += this.sortO;
    let buildUrl = url;
    if (url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    buildUrl += params;
    return buildUrl;
  }

  public onQuickFilterChanged($event, dt) {
    if ($event.key === 'Enter') {
      this.applySearch(this.search);
      dt.first = 0;
    }
  }

  keyPress($event) {
    const invalidKeys = AppSettings.NOT_ALLOWED_KEYS;
    if (invalidKeys.indexOf($event.key) !== -1) {
      return false;
    }
  }

  updateUrl(url: string) {
    this.url = url;
    this.offset = 0;
    this.first = 0;
    this.setRowLength = this.paginationPageSize;
    const paramArray: any[] = this.url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    } else {
      this.queryParams = '';
    }
    this.refreshList();
  }

  updateData(url: string) {
    this.url = url;
    const paramArray: any[] = this.url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    } else {
      this.queryParams = '';
    }
    this.refreshList();
  }

  updateQueryParams(url: string) {
    const paramArray: any[] = url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    } else {
      this.queryParams = '';
    }
  }

  loadDatasourceLazy(event: LazyLoadEvent) {
    if (!this.isFilterSaveScreen || this.isFilterDataCalled) {
      this.sortF = this.defaultSortField;
      this.offset = event.first;
      this.first = event.first;
      this.setRowLength = event.rows;
      if (event.sortField !== undefined) {
        this.sortF = event.sortField;
      }
      this.sortO = event.sortOrder > 0 ? 'asc' : 'desc';
      this.isComponentInitialised && this.fetchAndLoadData();
    }
  }

  onRowSelect(event) {
    this.rowClickedEvent.emit(event);
  }

  onRowUnselect(event) {
  }

  changeDropDown(event: any, rowData: any, index: any) {
    const payload = {
      status: event.value,
      rowData,
      index
    };
    // this.dropdownEvent.next(payload);
    this.confirmationService.confirm({
      message: 'Are you sure you want to proceed with the status update?',
      accept: () => {
        this.dropdownEvent.next(payload);
      },
      reject: () => {
        this.selectedStatus[index] = rowData.status;
      }
    });

  }

  actionBtnClick(event, data: any, action: string) {
    event.stopPropagation();
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.ref.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  applySearch(searchText: string) {
    this.searchText = searchText;
    this.offset = 0;
    this.first = 0;
    this.fetchAndLoadData();
  }

  exportGridExcel(event: any) {
    this.updateQueryParams(this.url);
    const that = this;
    if(this.isRangeApplicable) {
      if(this.isDownloadExcel) {
        this.gridService.downloadExcelGridData(that.getUrl(that.exportUrl + '?' + this.searchText + '&' + this.queryParams +
          (this.isRangeApplicable ? '&download=' + this.isDownloadExcel : '')));
      } else {
         this.gridService.getXcelReposnse(that.getUrl(that.exportUrl + '?' + this.searchText + '&' + this.queryParams +
          (this.isRangeApplicable ? '&download=' + this.isDownloadExcel : ''))).subscribe((res: any) => {
            this.uiService.showMessage(res.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
          }, (err) => {
            this.uiService.showMessage(err.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
          });
      }
    } else {
      this.gridService.downloadExcelGridData(that.getUrl(that.exportUrl + '?' + this.searchText + '&' + this.queryParams));
    }
    
  }

  exportGridPdf(event: any) {
    this.updateQueryParams(this.url);
    const that = this;
    this.gridService.downloadPdfGridData(that.getUrl(that.printUrl + '?' + this.searchText + '&' + this.queryParams));
  }

  emailGridData(event: any) {
    this.updateQueryParams(this.url);
    this.gridService.sendEmail(this.getUrl(this.emailUrl + '?' + this.searchText + '&' + this.queryParams)).subscribe((result) => {
      this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  getTitle(value) {
    return value.replace('<br>', ', ');
  }

  onFieldChange(col, checkedValue) {
    this.columnVisibility.emit({
      fieldName: col.field,
      visibility: checkedValue,
    });
  }

  onClickSave() {
    this.gridArray = [];
    this.columnDefs.forEach(e => {
      const obj = {
        columnName: e.field,
        visibility: e.visibility,
        isFix: e.isFix
      };
      this.gridArray.push(obj);
    });
    this.columnConfigChange.emit(this.gridArray);
    this.overlayPanel.hide();
  }

  getRowColor(rowData) {
    if (this.router.url === '/online-referrals') {
      if (rowData.status === 10) {
        return 'bg-ol-request-pending';
      } else if (rowData.status === 30) {
        return 'bg-ol-request-more-info';
      } else if (rowData.status === 50) {
        return 'bg-ol-request-accepted';
      } else if (rowData.status === 100) {
        return 'bg-ol-request-rejected';
      } else {
        return '';
      }
    } else if (this.router.url === '/dispatch-requests') {
      if (rowData.status === 10) {
        return 'bg-request-pending';
      } else if (rowData.status === 30) {
        return 'bg-request-more-info';
      } else if (rowData.status === 50) {
        return 'bg-request-accepted';
      } else if (rowData.status === 100) {
        return 'bg-request-rejected';
      } else if (rowData.status === 200) {
        return 'bg-request-dispatch-completed';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getOutboundReferralStatusList() {
    const list = this.storageService.getItemLocalStorage(AppSettings.TAG_DATA)[AppSettings.OUTBOUND_REFERRAL_STATUS];
    this.referralStatusList.push({ label: this.commonBindingDataService.getLabel('All'), value: '' });
    list.forEach((status) => {
      this.referralStatusList.push({ label: status.tagValue, value: status.id });
    });
  }

}
