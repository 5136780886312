
import { UserCommonService } from './../../services/user-common.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../app.settings';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-otp-verifiy',
  templateUrl: './otp-verifiy.component.html'
})

export class OtpVerifiyComponent implements OnInit {
  @Input() email;
  @Input() phone;
  @Output() otpPopClose: EventEmitter<any> = new EventEmitter();
  @Output() otpPopSave: EventEmitter<any> = new EventEmitter();
  otpCounterTimer;
  otpInterval: any;
  otpForm = new FormGroup({
    otpInput: new FormControl()
  });

  otpErrors = [
    {
      otpInput: false
    }
  ];
  otpTimerValue;

  constructor(
    private formBuilder: FormBuilder,
    private commonServices: UserCommonService,
    private router: Router,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.getMetadata();
    this.otpForm = this.formBuilder.group({
      otpInput: ['', [Validators.required]],
    });
    this.resendOtpTimer();
  }

  getMetadata() {
    this.commonServices.getPublicMetadata('forgot_password').subscribe(results => {
      this.otpTimerValue = results?.resendOtpTimeInSeconds ? parseInt(results?.resendOtpTimeInSeconds, 10) : AppSettings.DEFAULT_OTP_TIMER;
      this.otpCounterTimer = this.otpTimerValue;
    }, (error) => {
      this.otpTimerValue = AppSettings.DEFAULT_OTP_TIMER;
      this.otpCounterTimer = this.otpTimerValue;
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  closeEditForgot(event) {
    this.otpForm.reset();
    this.otpPopClose.emit();
  }

  onBackSign(event) {
    this.otpForm.reset();
    this.router.navigate(['signin']);
  }

  changePassword(event) {
    let input;
    const otpInput = this.otpForm.controls.otpInput.value;
    input = {
      otp: otpInput,
      email: this.commonServices.userEmail
    };

    this.commonServices.validateOtp(input).subscribe(results => {
      this.commonServices.setOtpValue(results.otpId);

      setTimeout(() => {
        this.router.navigate(['/set-password']);
        this.otpForm.reset();
      }, 1200);

    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  resendOtpTimer() {
    const self = this;
    this.otpCounterTimer = this.otpTimerValue;
    this.otpInterval = setInterval(function() {
      self.otpCounterTimer = self.otpCounterTimer - 1;
      if (self.otpCounterTimer <= 0) {
        self.otpCounterTimer = 0;
        clearInterval(self.otpInterval);
      }
    }, 1000);
  }

  resendOtp(event) {
    this.otpForm.reset();
    const input = {
      email: this.commonServices.userEmail,
      tenantId: AppSettings.TENANT
    };
    this.commonServices.forgotPassword(input).subscribe(results => {
      this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      this.resendOtpTimer();
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

}
