import { Component, OnInit } from '@angular/core';
import { UiService } from 'app/modules/shared/services/ui.service';
import { AppSettings } from './../../modules/shared/app.settings';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sdoh-patient-form',
  templateUrl: './sdoh-patient-form.component.html',
  styleUrls: ['./sdoh-patient-form.component.scss']
})
export class SdohPatientFormComponent implements OnInit {
  formCategories: any;
  array1: any = [];
  array2: any = [];
  categoryQuestions: any = [];
  offset = 0;
  pageQuestions: any = [];
  subcat: any = [];
  cat: any = [];
  questions: any = [];
  options: any = [];
  formID: any;
  tourId: any;
  patientId: any;
  openSuccessDialog = false;
  isFormSubmitted = false;
  isFormAlreadySubmitted = false;

  constructor(private commonServices: UserCommonService,
    private uiService: UiService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.tourId = this.route.snapshot.queryParams['id'];
    this.patientId = this.route.snapshot.queryParams['patientId'];
    this.commonServices.isSDoHFormSubmitted(this.tourId).subscribe(results => {
      this.getQuestionsData();
    }, (error) => {
      this.isFormAlreadySubmitted = true;
      // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  getQuestionsData() {
    this.commonServices.getQuestionsData('sdoh').subscribe(results => {
      console.log(results);
      this.formID = results.id;
      this.formCategories = results.formCategories;
      for (let i = 0; i < this.formCategories.length; i++) {
        this.array1 = [];
        for (let j = 0; j < this.formCategories[i].formCategorySubcategories.length; j++) {
          console.log(this.formCategories[i].formCategorySubcategories[j].subCategoryQuestions?.length);
          if (this.formCategories[i].formCategorySubcategories[j].subCategoryQuestions?.length > 0) {
            this.array1.push(this.formCategories[i].formCategorySubcategories[j]);
          }
        }
        // console.log(this.array1);
        const obj = {
          categoryId: this.formCategories[i].categoryId,
          categoryName: this.formCategories[i].categoryName,
          userSdohScreeningCategorySubCategories: this.array1
        };
        this.array2[i] = obj;
      }
      console.log(this.array2);
      for (let i = 0; i < this.array2.length; i++) {
        if (this.array2[i].userSdohScreeningCategorySubCategories?.length === 0) {
          this.array2.splice(i, 1);
        }
      }
      this.categoryQuestions = this.array2;
      this.pageQuestions = this.categoryQuestions[this.offset];
      console.log(this.categoryQuestions.length);
      // this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
    }, (error) => {
      // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  onClickNext() {
    this.offset = this.offset + 1;
    this.pageQuestions = this.categoryQuestions[this.offset];
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onClickBack() {
    this.offset = this.offset - 1;
    this.pageQuestions = this.categoryQuestions[this.offset];
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onClickSubmit() {
    console.log(this.categoryQuestions);
    for (let i = 0; i < this.categoryQuestions.length; i++) {
      this.subcat = [];
      for (let j = 0; j < this.categoryQuestions[i].userSdohScreeningCategorySubCategories.length; j++) {
        console.log(this.categoryQuestions[i].userSdohScreeningCategorySubCategories[j].subCategoryQuestions?.length);
        if (this.categoryQuestions[i].userSdohScreeningCategorySubCategories[j].subCategoryQuestions?.length > 0) {
          this.questions = [];
          for (let k = 0; k < this.categoryQuestions[i].userSdohScreeningCategorySubCategories[j].subCategoryQuestions.length; k++) {
            this.options = [];
            let questionId = undefined;
            for (let l = 0; l < this.categoryQuestions[i].userSdohScreeningCategorySubCategories[j].subCategoryQuestions[k].subCategoryQuestionOptions.length; l++) {
              if (this.categoryQuestions[i].userSdohScreeningCategorySubCategories[j].subCategoryQuestions[k].
                subCategoryQuestionOptions[l].checked === true) {
                questionId = this.categoryQuestions[i].userSdohScreeningCategorySubCategories[j].subCategoryQuestions[k].
                  subCategoryQuestionOptions[l].subCategoryQuestionId;
                const opt = {
                  subCategoryQuestionId: questionId,
                  subCategoryQuestionOptionId: this.categoryQuestions[i].userSdohScreeningCategorySubCategories[j].
                    subCategoryQuestions[k].subCategoryQuestionOptions[l].id
                };
                this.options.push(opt);
              }
            }
            console.log(this.options);
            const que = {
              subCategoryQuestionId: questionId,
              userSdohScreeningQuestionOptions: this.options
            };
            if (questionId !== undefined) {
              this.questions[k] = que;
              console.log(this.questions);
            }
            questionId = undefined;
            this.questions = this.questions.filter((a) => a);
          }
          const temp = {
            subCategoryId: this.categoryQuestions[i].userSdohScreeningCategorySubCategories[j].subCategoryId,
            userSdohScreeningQuestions: this.questions
          };
          if (this.questions.length > 0) {
            this.subcat[j] = temp;
          }
        }
      }
      this.subcat = this.subcat.filter((a) => a);
      const obj = {
        categoryId: this.categoryQuestions[i].categoryId,
        categoryName: this.categoryQuestions[i].categoryName,
        userSdohScreeningCategorySubCategories: this.subcat
      };
      this.cat[i] = obj;
    }

    const data = {
      'tenantId': AppSettings.TENANT,
      'formId': this.formID,
      'tourId': this.tourId,
      // "tourStatus": 40,
      'patientId': this.patientId,
      // "submittedBy": "2",
      // "submittedByRoleId": "2",
      'status': 'Completed',
      'sdohScreeningType': '1',
      'formType': 'sdoh',
      'sdohScreeningCategories': this.cat
    };
    this.commonServices.submitPatientSDoHForm(data).subscribe(results => {
      // this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      this.isFormSubmitted = true;
      this.getQuestionsData();
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  onClickOk() {
    this.openSuccessDialog = false;
  }

}
