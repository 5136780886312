import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppSettings } from './../../app.settings';
import { StorageService } from './../../services/storage.service';
import { SidebarService } from './../../services/sidebar.service';
import { Subscription } from 'rxjs';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NotificationsService } from '../../services/notifications.service';
import { MessagingService } from '../../services/messaging.service';
import { Location } from '@angular/common';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})

export class SidebarComponent implements OnInit {
  @ViewChild('menupanel') menupanel: any;
  items: MenuItem[];
  subscription: Subscription;
  fcmSubscription: Subscription;
  isMultipleTabOpen: any = false;
  isMenuCollapsed = false;
  user;
  notificationCount = 0;
  spanEle: HTMLElement;

  constructor(
    private storageService: StorageService,
    private sidebarService: SidebarService,
    private commonBindingDataService: CommonBindingDataService,
    private router: Router,
    private notificationsService: NotificationsService,
    private messagingService: MessagingService,
    private location: Location,
    private uiService: UiService,
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // console.log('prev:', event.url);
        if (event.url.includes('/provider-availability') || event.url.includes('/appointments/appointment-details') ||
          event.url.includes('/appointments/reschedule-appointment') || event.url.includes('/appointments/re-assign-provider') ||
          event.url.includes('/appointments/book-appointment') || event.url.includes('/appointments/create-appointment')) {
          // console.log(true);
        } else {
          localStorage.setItem('availabilityFilters', null);
        }
      });
  }

  ngOnInit() {
    this.initSidebarItems();
    this.subscription = this.sidebarService.sideState
      .subscribe((state: any) => {
        this.updateItems(state);
      });

    this.commonBindingDataService.redrawSidebar.subscribe(() => {
      this.initSidebarItems();
    });
    this.uiService.sidebarToggledEvent.subscribe({
      next: (res) => {
        this.isMenuCollapsed = !this.isMenuCollapsed;
      }
    });
    // this.getNotificationCount();
    // this.createAnimatedElement();
  }

  clickedOnMenu(event) {
    // console.log(event.target.innerText);
    // if (event.target.textContent.includes("Messages") && !(document.getElementById('messagesNotificationIcon') === null)) {
    //   console.log(document.getElementById('messagesNotificationIcon'));
    //   this.spanEle.style.display = 'none';
    //   // this.removeElement();
    // }
  }

  subscribeNotification() {
    this.subscription = this.notificationsService.getCurrentCount().subscribe((response: any) => {
      console.log(response);
      if (response > 0) {
        this.spanEle.style.display = 'flex';
        this.notificationCount = response;
        // this.removeElement();
        // this.createAnimatedElement();
        this.updateCount(this.notificationCount);
      }
      if (response === 0) {
        this.spanEle.style.display = 'none';
      }
    });
  }

  ngAfterViewInit(): void {
    this.createAnimatedElement();
    this.getNotificationCount();
    this.subscribeNotification();
  }

  ngDoCheck(): void {

  }


  removeElement() {
    const element = document.getElementById('messagesNotificationIcon');
    element.remove();
  }

  getNotificationCount() {
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    this.notificationsService.getNotificationCount(userObj.id).subscribe(result => {
      this.notificationCount = result.unreadMessageCount;
      console.log('Notification Count::', result.unreadMessageCount);
      if (this.notificationCount > 0) {
        console.log(this.menupanel);
        if (!(document.getElementById('messagesNotificationIcon') === null)) {
          console.log(document.getElementById('messagesNotificationIcon'));
          this.spanEle.style.display = 'flex';
          this.updateCount(this.notificationCount);
        }
      }
    }, (error) => {

    });

  }

  updateCount(count) {
    if (count) {
      if (count > 99) {
        this.spanEle.innerText = '99+';
      } else {
        this.spanEle.innerText = count;
      }
      this.spanEle.style.width = '20px';
      this.spanEle.style.height = '20px';

    } else {
      this.spanEle.style.width = '4px';
      this.spanEle.style.height = '4px';
      this.spanEle.innerText = '';
    }
  }

  createAnimatedElement(count?) {
    this.spanEle = document.createElement('span');
    this.spanEle.id = 'messagesNotificationIcon';

    this.spanEle.style.borderRadius = '50%';
    this.spanEle.style.backgroundColor = '#4928BC';
    // spanEle.style.border = '1px solid #DE4632';
    if (this.notificationCount) {
      if (this.notificationCount > 99) {
        this.spanEle.innerText = '99+';
      } else {
        this.spanEle.innerText = this.notificationCount.toString();
      }
      this.spanEle.style.width = '20px';
      this.spanEle.style.height = '20px';
    } else {
      this.spanEle.style.width = '4px';
      this.spanEle.style.height = '4px';
      this.spanEle.innerText = '';
    }
    this.spanEle.style.boxShadow = '0.5px 0.5px 0.5px 0.5px #0000003d';
    this.spanEle.style.color = 'rgb(253, 253, 253)';

    this.spanEle.style.position = 'absolute';
    this.spanEle.style.display = 'none';
    this.spanEle.style.fontSize = '11px';
    this.spanEle.style.lineHeight = '1.1';
    this.spanEle.style.minHeight = '1px';
    this.spanEle.style.minWidth = '1px';
    this.spanEle.style.padding = '12px 14.5px';
    this.spanEle.style.marginLeft = '6px';
    this.spanEle.style.top = '4px';
    this.spanEle.style.alignItems = 'center';
    this.spanEle.style.justifyContent = 'center';
    this.spanEle.style.right = '75px';
    // spanEle.style.animation = 'notification-glow 1s infinite alternate';
    if (!(document.getElementsByClassName('ui-menuitem-text') === undefined)) {
      for (let i = 0; i <= document.getElementsByClassName('ui-menuitem-text').length; i++) {
        if (!(document.getElementsByClassName('ui-menuitem-text')[i] === undefined) && document.getElementsByClassName('ui-menuitem-text')[i].innerHTML === 'Messages') {
          console.log(document.getElementsByClassName('ui-menuitem-text')[i].innerHTML);
          document.getElementsByClassName('ui-menuitem-text')[i].appendChild(this.spanEle);
        }
      }
    }

  }

  initSidebarItems() {
    this.items = [];
    this.user = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (this.items.length <= 0) {
      if (this.user !== null && this.user !== undefined) {
        this.items = this.getMenu();
      }
    }
    this.updateItems();
    this.manageActiveStatus();
  }

  updateItems(state?) {
    if (state) {
      this.items = state;
    }
    this.items = this.removeDuplicates(this.items, 'label');
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  private manageActiveStatus() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.routerLink) {
        if (item.routerLink.length >= 1 && document.location.pathname === item.routerLink[0]) {
          item.expanded = true;
        }
      }
    }
  }

  selectedMenuClick(item) {
    this.storageService.setItem('selectedMenu', item.srcElement.textContent.trim());
  }

  getMenu() {
    // console.log(this.sidebarService.sideBarMenu);
    return this.sidebarService.sideBarMenu;
  }

}
