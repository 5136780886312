<p-panelMenu class="sidebar-panel-menu" [multiple]="isMultipleTabOpen" [model]="items" #menupanel (click)="clickedOnMenu($event)"></p-panelMenu>
<div class="rainbowcare-wrapper" *ngIf="!isMenuCollapsed">
    <p class="powered-by-label" >Powered by <a class="site-link" href="https://rainbow.health" target="_blank" >Rainbow.Health</a> </p>
    <p class="version-number">Version 1.0.1</p>
</div>
<div class="rainbowcare-wrapper" *ngIf="isMenuCollapsed">
    <a class="logo-wrapper" href="https://rainbow.health" target="_blank">
        <img class="rainbow-logo" src="/assets/images/temp/rainbowcare_logomark.png" alt="rainbowcare logo">
    </a>
</div>
