<div class="company-logo left-panel">
  <img class="company-logo-background" src="/assets/images/temp/rainbowcare_logo.png">
</div>
<div class="right-panel">
  <div class="app-header ui-g-1 ui-md-1 ui-lg-1 ui-g-nopad">
    <div class="top-bar-item">
      <a (click)="onSideMenuClick($event)" class="hamburger-menu">
        <i class="fa fa-bars"></i>
      </a>
    </div>
  </div>
  <div class="ui-g-11 ui-md-11 ui-lg-11 ui-g-nopad profile-panel">
    <div class="header-client-lbl" *ngIf="isClientStaffRoleUser"><h3>{{ defaultMarket?.marketName }}</h3></div>
    <div class="nav-right-menu">
      <div class="top-bar-item book-appt-button mr-30" *ngIf="dispatchRequestAccess && pendingAlertsCount > 0">
        <button pButton class="pending-btn" type="button" [label]="pendingAlertscountLabel"
          (click)="gotoDispatchRequest();"></button>
      </div>
      <div class="top-bar-item book-appt-button mr-30" *ngIf="adminUser && disablePendingApproval">
        <button pButton class="pending-btn" type="button" [label]="pendingCount"
          (click)="gotoAppointments();"></button>
      </div>
      <!-- <div class="top-bar-item book-appt-button" *ngIf="showDualBookingBtn && saveAppointmentDraft === 'true'">
        <button pButton class="book-btn" type="button" [label]="'label_book_dual_appointment' | translate"
          (click)="gotoBookDualAppt();"></button>
      </div> -->
      <div class="top-bar-item notifications-bell" (click)="getNotificationListAndToggle($event)">
        <a class="nav-notifications-icon">
          <i class="fa fa-bell-o" aria-hidden="true"></i>
          <div class="animated-new-notification-count" *ngIf="unReadNotificatonsCount">
            <span *ngIf="unReadNotificatonsCount > 0"></span>
          </div>
        </a>
      </div>

      <div class="profile-menu-button top-bar-item">
        <p-menu #menu popup="popup" [model]="items"></p-menu>
        <a (click)="menu.toggle($event)">
          <img class="user-photo" src="{{profileUrl}}" onerror="src='/assets/images/temp/default_profile.png'">
          <label class="user-name">{{userFullName}}</label>
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="hasShowNotificationBar" [style]="{width: '400px'}" position="right">
  <div class="notification-container">
    <div class="header">
      <h3 class="header-heading notify-header">{{notificationLabel}}</h3>
    </div>
    <div class="content">
      <app-notification-mg1 [notifications]="notifications" [showMoreOff]="showMoreOff" [notificationCount]="notificationCount"
        (loadMoreNotification)="loadMoreNotification()">
      </app-notification-mg1>
    </div>
  </div>
</p-sidebar>

<p-dialog [blockScroll]="true" modal="true" [showHeader]="false" styleClass="add-terms-popup" [style]="{width: '35%'}"
  [(visible)]="changePasswordPopup" [closable]="false">
  <app-change-password [(changePasswordPopup)]="changePasswordPopup"
    (isShowChangePassword)="onCancelChangePassword($event)"></app-change-password>
</p-dialog>

<p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-profile-popup profile-screen"
  [style]="{width: '35%'}" responsive="true" [(visible)]="editProfilePopup">
  <app-edit-profile [(editProfilePopup)]="editProfilePopup" (showProfilePopup)="onCancelEditProfile($event)">
  </app-edit-profile>
</p-dialog>


<p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-profile-popup"
  [style]="{width: '500px'}" responsive="true" [(visible)]="headerConfirmDialog">
  <div class="ui-g form-container">
    <div class="ui-g-12 header-dialog-heading">
      <p translate>label_confirmation</p>
    </div>
    <div class="ui-g-12 header-dialog-content">
      {{headerMessage}}
    </div>
    <div class="ui-g-12 ui-g-nopad form-buttons">
      <button class="negative-button" pButton type="button" icon="fa fa-close" [label]="'label_no_without_dot' | translate"
        (click)="logout()"></button>
      <button pButton type="button" [label]="'label_yes' | translate" icon="fa fa-check" (click)="refreshSession()"></button>
    </div>
  </div>
</p-dialog>