import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInLayoutComponent } from './pages/sign-in-layout/sign-in-layout.component';
import { AuthenticationValidationService } from './modules/shared/services/authentication-validation.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { ForgotPasswordComponent } from './modules/shared/components/forgot-password/forgot-password.component';
import { OtpVerifiyComponent } from './modules/shared/components/otp-verifiy/otp-verifiy.component';
import { SetPasswordComponent } from './modules/shared/components/set-password/set-password.component';
import { ComplaintComponent } from './pages/complaint/complaint.component';
import { PasswordSetWithOtpVerifyComponent } from './pages/password-set-with-otp-verify/password-set-with-otp-verify.component';
import { RedirectionPageComponent } from './pages/redirection-page/redirection-page.component';
import { SupportComponent } from './pages/support/support.component';
// import { ZoomNewCallComponent } from './modules/shared/components/zoom-new-call/zoom-new-call.component';
import { SdohPatientFormComponent } from './pages/sdoh-patient-form/sdoh-patient-form.component';
import { TwilioVideoComponent } from './pages/twilio-video/twilio-video.component';
import { ConsentFormComponent } from './pages/consent-form/consent-form.component';
import { ReferralPageComponent } from './pages/referral-page/referral-page.component';
import { SatisfactionSurveyFormComponent } from './pages/satisfaction-survey-form/satisfaction-survey-form.component';
import { ReferralFormComponent } from './pages/referral-form/referral-form.component';
import { ReferralFormScottComponent } from './pages/referral-form-scott/referral-form-scott.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  // {
  //   path: 'zoom',
  //   pathMatch: 'full',
  //   component: ZoomNewCallComponent,
  // },
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInLayoutComponent
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent
  },
  {
    path: 'sdoh-patient-form',
    pathMatch: 'full',
    component: SdohPatientFormComponent
  },
  {
    path: 'cp',
    pathMatch: 'full',
    component: ConsentFormComponent
  },
  {
    path: 'rf',
    pathMatch: 'full',
    component: ReferralPageComponent
  },
  {
    path: 'forms/feedback',
    pathMatch: 'full',
    component: SatisfactionSurveyFormComponent
  },
  {
    path: 'referral-form',
    pathMatch: 'full',
    component: ReferralFormComponent
  },
  {
    path: 'twilio-video',
    pathMatch: 'full',
    component: TwilioVideoComponent
  },
  {
    path: 'support',
    pathMatch: 'full',
    component: SupportComponent
  },
  {
    path: 'otp-verify',
    pathMatch: 'full',
    component: OtpVerifiyComponent
  },
  {
    path: 'set-password',
    pathMatch: 'full',
    component: SetPasswordComponent
  },
  {
    path: 'redirect-page',
    pathMatch: 'full',
    component: RedirectionPageComponent
  },
  // {
  //   path: 'trackmylocation',
  //   pathMatch: 'full',
  //   component: TrackMyLocationComponent
  // },

  {
    path: 'password-set',
    component: PasswordSetWithOtpVerifyComponent,
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
  },
  {
    path: 'appointments',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/bookings/bookings.module').then(m => m.BookingsModule)
  },
  // static code start
  // {
  //   path: 'billing',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/billing/billing.module#BillingModule'
  // },

  // {
  //   path: 'complaint-add',
  //   pathMatch: 'full',
  //   component: ComplaintComponent,
  // },
  // static code end
  // {
  //   path: 'emergency',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/emergency/emergency.module#EmergencyModule'
  // },
  // {
  //   path: 'scheduler',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/schedulers/schedulers.module#SchedulersModule'
  // },
  {
    path: 'patients',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/passengers/passengers.module').then(m => m.PassengersModule)
  },
  {
    path: 'online-referrals',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/online-referrals/online-referrals.module').then(m => m.OnlineReferralsModule)
  },
  {
    path: 'dispatch-requests',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/dispatch-request/dispatch-request.module').then(m => m.DispatchRequestModule)
  },
  {
    path: 'orders',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'providers',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/providers/providers.module').then(m => m.ProvidersModule)
  },
  // {
  //   path: 'health-centers',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/health-centers/health-centers.module#HealthCentersModule'
  // },
  // {
  //   path: 'vehicles',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/vehicles/vehicles.module#VehiclesModule'
  // },
  {
    path: 'hc-providers',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/drivers/drivers.module').then(m => m.DriversModule)
  },
  {
    path: 'service-regions',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/manage-cities/manage-cities.module').then(m => m.ManageCitiesModule)
  },
  {
    path: 'marketing',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/marketing/marketing.module').then(m => m.MarketingModule)
  },
  {
    path: 'app-settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/tenant-settings/tenant-settings.module').then(m => m.TenantSettingsModule)
  },
  {
    path: 'masters',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'user-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule)
  },
  // {
  //   path: 'operator-management',
  //   canActivate: [AuthenticationValidationService],
  //   loadChildren: './modules/operator-management/operator-management.module#OperatorManagementModule'
  // },
  {
    path: 'settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'reports',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'provider-availability',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/provider-availability/provider-availability.module').then(m => m.ProviderAvailabilityModule)
  },
  {
    path: 'messages',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/messages/messages.module').then(m => m.MessagesModule)
  },
  {
    path: 'education-materials',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/educational-material/educational-material.module').then(m => m.EducationalMaterialModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
