import { AppSettings } from './../app.settings';
import { Injectable } from '@angular/core';
import { RestApiService } from './rest-api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class NotificationsService {
  canFetchNotification = false;
  currentCount = new Subject<any>();
  constructor(private restApiService: RestApiService) { }


  getDetails(limit, offset): Observable<any> {
    return this.restApiService.get('', '/secure/users/notification?offset=' + offset + '&limit=' + limit, 'page-center');
  }

  getCount(): Observable<any> {
    return this.restApiService.get('public', '/secure/notification/unreadcount', 'page-center');
  }

  getNotificationCount(userId): Observable<any> {
    return this.restApiService.get('public', `/secure/users/${userId}/notifications/unreadcount`, 'page-center');
  }

  getUnreadNotificationCount(): Observable<any> {
    return this.restApiService.get('public', `/secure/users/2/notifications/unreadcount`, 'page-center');
  }

  getTwilioToken(id): Observable<any> {
    return this.restApiService.get('', '/secure/twilio/access-token?identity=' + id, 'page-center');
  }

  getTwilioRoomName(data): Observable<any> {
    return this.restApiService.post('', '/secure/twilio/start-video-call', data, 'page-center');
  }

  distroyTwilioRoom(data): Observable<any> {
    return this.restApiService.put('', '/secure/twilio/end-video-call', data, 'page-center');
  }

  rejectTwilioCall(data): Observable<any> {
    return this.restApiService.put('', '/secure/twilio/reject-call', data, 'page-center');
  }

  startFetchingNotifications(callback) {
    AppSettings.CLEAR_NOTIFICATION_INTERVAL = setInterval(() => {
      if (this.canFetchNotification) {
        callback();
      }
    }, AppSettings.NOTIFICATION_INTERVAL);
  }

  stopFetchingNotifications() {
    if (AppSettings.CLEAR_NOTIFICATION_INTERVAL) {
      clearInterval(AppSettings.CLEAR_NOTIFICATION_INTERVAL);
    }
  }


  getCurrentCount(): Observable<any> {
    return this.currentCount.asObservable();
  }

}
