import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { CommonBindingDataService } from './../services/common-binding-data.service';

@Directive({
  selector: '[appAccessProviderAllMenu]'
})
export class AccessProviderAllMenuDirective implements OnChanges {

  // This directive will check if access code is present in all menu access lists or not

  @Input() accessCode;
  private innerElement: HTMLElement;
  private accessList: any[];

  constructor(refElement: ElementRef, private commonBindingDataService: CommonBindingDataService) {
    this.innerElement = refElement.nativeElement;
  }

  ngOnChanges() {
    this.accessList = this.commonBindingDataService.getAllAccessesList();
    const hasAccess = this.accessList.find((elm) => {
      return elm.accessCode === this.accessCode;
    });
    if (!hasAccess) {
      this.innerElement.style.display = 'none';
    }
  }

}
