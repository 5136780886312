import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppSettings {
  public static BASE_URL = environment.basePath;
  public static IS_IDLE_TIME = environment.idleTimeEnable;
  public static SUPPORTED_COUNTRY = environment.supportedCountry;
  public static IDLE_TIME = 30;
  public static IDLE_TIME_ON_CONFIRM_MSG = 120000;
  public static REPORTS_DATE_RANGE = 60;
  public static DEFAULT_REPORTS_DATE_RANGE = 59;
  public static DEFAULT_OTP_TIMER = 60;
  public static PRIVATE_KEY_FOR_UPLOAD_FILE = 'SBrtNXUlwQ8Lm/EpM6EqQw==';
  public static INTERVAL = 'interval';
  public static TENANT = environment.tenant;
  public static TENANT_INFO = 'tenantInfo';
  public static HEADER_CONTENT_TYPE = 'application/json';
  public static HEADER_AUTHORIZATION = 'Authorization';
  public static HEADER_TIMEZONE = 'Timezone';
  public static HEADER_TIMEZONE_VALUE = '';
  public static HEADER_ACCEPT_LANGUAGE = 'en';
  public static DEFAULT_LANGUAGE = 'en';
  public static HEADER_AUTHORIZATION_VALUE = '';
  public static USER: any = null;
  public static DEFAULT_SERVICE_MODE = 'onlineReferral';
  public static UC = 'dispatch';
  public static CC = 'onlineReferral';
  public static TOKEN_KEY = 'Token';
  public static USER_DETAILS = 'userDetails';
  public static FILE_UPLOAD_ID = '';
  public static FILE_UPLOAD_NAME = '';
  public static PROFILE_UPLOAD_ID = '';
  public static PROFILE_UPLOAD_NAME = '';
  public static DISTANCE_UNIT = 'distance_unit';
  public static REFERRAL_CODE = 'driver_referral';
  public static ADMIN_TRIP_ACTION = 'admin_trip_action';
  public static PASSENGER_WALLET = 'passenger_wallet';
  public static CLINICAL_DOC_SIDEBAR_STATUS = 'clinical_doc_sidebar_status';
  public static TRIP_NOTE = 'trip_note';
  public static DEFAULT_COUNTRY = 'default_country';
  public static DEFAULT_CURRENCY = 'default_currency';
  public static DEFAULT_COUNTRY_CODE = 'num_country_code';
  public static TENANT_CONFIG = 'TENANT_CONFIG';
  public static IS_RIDE_SHARE_APPLICABLE = 'is_ride_share_applicable';
  public static IS_MANAGE_COMPANY = 'is_bidding_applicable';
  public static IS_MULTIPLE_VEHICLE_TO_DRIVER = 'is_multiple_vehicle_to_driver';
  public static IS_LANGUAGE_SUPPORT = 'is_language_support';
  public static SUPPORTED_LANGUAGES = 'supported_languages';
  public static IMPORT_PROVIDER_TEMPLATE = 'addproviderstemplatefileid';
  public static IMPORT_PATIENT_TEMPLATE = 'patientimporttemplate';
  public static DISPATCH_FEATURE_CONFIG = 'feature_configs';
  public static DISPATCH_RUN_NUMBER_CONFIG = 'run_number';
  public static PROVIDER_SCHEDULE_TEMPLATE = 'addprovidersscheduletemplatefileid';
  public static FAQ: any = null;
  public static ACCESS_LIST = 'ACCESS_LIST';
  public static ACCESS_MENU = 'ACCESS_MENU';
  public static SERVICE_AREA = 'SERVICE_AREA';
  public static SERVICE_AREA_ALIAS = 'SERVICE_AREA_ALIAS';
  public static SERVICE_CITIES_MAP = 'SERVICE_CITIES_MAP';
  public static MUTLTI_SELECT_SERVICE_CITIES = 'MUTLTI_SELECT_SERVICE_CITIES';
  public static SERVICE_AREA_ID = 'SERVICE_AREA_ID';
  public static CURRENT_USER_CORPORATE_ID = 'CURRENT_USER_CORPORATE_ID';
  public static TIME_SETTINGS = 'TIME_SETTINGS';
  public static DEFAULT_REGION_LON = 'defalut_region_longitude';
  public static DEFAULT_REGION_LAT = 'defalut_region_latitude';
  public static DEFAULT_SERVICE_TYPE = '1';
  public static USER_MARKETS = 'userMarkets';
  public static USER_DEFAULT_MARKET = 'userDefaultMarket';
  public static DEFAULT_MARKET = 'QuikMedic';
  public static DEFAULT_MARKET_TIMEZONE = 'America/New_York';
  public static DEFAULT_MARKET_TIMEZONE_ABBR = 'EST';
  public static GMT_TIMEZONE_NAME = 'Europe/London';
  public static DEFAULT_RIDE_TYPE = '1';
  public static IS_BIDDING_ENABLED = 'is_bidding_applicable';
  public static DATE_FORMAT = 'date_format';
  public static SETTING_SERVICE_ID = '';
  public static SERVICE_TYPE = 'ServiceType';
  public static TAG_DATA = 'TagData';
  public static GRID_THEME = 'mi';
  public static OUTBOUND_REFERRAL_STATUS = 'outbound_referral_status';
  public static TENENT_VALIDATION = 'tenantValidations';
  public static MEMBER_REQUIREMENT = 'memberRequirementList';
  public static APPOINTMENT_TYPE_LIST = 'appointmentTypeList';
  public static CARE_REQUEST_STATUS = 'careRequestStatusConstants';
  public static WEEK_DAY = 'dayOfWeekList';
  public static PROVIDER_TYPE = 'providerType';
  public static PROVIDER_DASHBOARD = 'providerTypeForDashboard';
  public static EMERGENCY_STATUS_LIST = 'emergencyStatusList';
  public static USER_TYPE_LIST = 'userTypeList';
  public static COLORS_LIST = 'colorsList';
  public static YEARS_LIST = 'yearsList';
  public static MAKE_DETAILS_LIST = 'makeDetailsList';
  public static DRIVER_STATUS_FILTER = 'driverStatusFilter';
  public static SETTING_RENTAL_WITHOUT_SERVICE = '';
  public static SETTING_RENTAL_WITH_SERVICE = '';
  public static SETTING_TAXI_SERVICE = '';
  public static SETTING_CHAUFFEUR_SERVICE = '';
  public static SETTING_RENTAL_WITHOUT_SERVICE_KEY = 'Rental without driver';
  public static SETTING_RENTAL_WITH_SERVICE_KEY = 'Rental with driver';
  public static SERVICE_CODE_RENTAL = 'Rental';
  public static SETTING_TAXI_SERVICE_KEY = 'Taxi';
  public static TAXI = 'Taxi';
  public static SETTING_STAFF_TRANSPORT_SERVICE_KEY = 'StaffTransport';
  public static SETTING_CHAUFFEUR_SERVICE_KEY = 'Chauffeur';
  public static CHAUFFEUR = 'Chauffeur';
  public static TAXI_BOOK_MAX_TIME = 'book_later_maximum_time';
  public static TAXI_BOOK_MIN_TIME = 'book_later_minimum_time';
  public static RENTAL_BOOK_MIN_TIME = 'book_later_minimum_time_rental';
  public static CHAUFFEUR_BOOK_MIN_TIME = 'book_later_minimum_time_chauffeur';
  public static AUTOMATIC = 'AUTO';
  public static MANL = 'MANL';
  public static MANUAL = 'MANUAL';
  public static ONE_DAY_TO_MILLISEC = 86400000;
  public static ONE_MB_TO_BYTES = 1048576;
  public static MIN_DISTANCE = 0.1;
  public static MAX_DISTANCE = 1;
  public static UPLOAD_FILE_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/secure/file';
  public static UPLOAD_MULTIPLE_FILE_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/secure/files/upload';
  public static PUBLIC_UPLOAD_FILE_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/file';
  public static GET_FILE_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/secure/file';
  public static GET_FILE_THUMB_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT + '/secure/file/thumbnail_';
  public static EXPORT_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT;
  public static PRINT_URL = AppSettings.BASE_URL + '/' + AppSettings.TENANT;
  public static DEFAULT_PROFILE_IMAGE_URL = '/assets/images/temp/default_profile.png';
  public static DEFAULT_DOCUMENT_IMAGE_URL = '/assets/images/temp/doc.png';
  public static SDOH_FORM_ID = '292466722168126745';
  public static CLINICAL_DOC_FORM_ID = '292466722168126746';
  public static CLEAR_NOTIFICATION_INTERVAL: any;
  public static WEBSITE_PATTERN = new RegExp(['^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?',
    '[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})',
    '?(\/.*)?$'].join(''));
  public static NAME_PATTERN = new RegExp(/^([a-zA-Z0-9]+\s?)*$/);
  public static EQUALTO_PATTERN = new RegExp(/^[^=].*/);
  public static SSN_ID_PATTERN = new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/);
  public static ROLE_PATTERN = new RegExp(['^[A-Za-z\\d\-_\\s/\\\\\]*$'].join(''));
  public static PHONE_PATTERN: any = '^[0-9][0-9]*$';
  public static NUMBER_PATTERN = /^\+?[0-9\-]+$/;
  public static ALPHA_NUMERIC_PATTERN = /^(?![0-9]*$)[a-zA-Z0-9]+$/;
  public static AMOUNT_PATTERN = new RegExp(['^[0-9\\d\.]*$'].join(''));
  public static WALLET_AMOUNT_PATTERN = new RegExp(['^[-|0-9\\d\.]*$'].join(''));
  public static PHONE_CODE = /([0-9]{4,})|[1-9]/;
  public static EMAIL_PATTERN = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,5})+$/;
  public static PASSWORD_PATTERN = '^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~!**^!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$';
  public static ZIPCODE_PATTERN = new RegExp(['^[0-9]'].join(''));
  public static DIGIT_PATTERN = new RegExp(['^[\\d]*$'].join(''));
  public static RADIUS_DIGIT_PATTERN = /^[0-9|.|0-9]+$/;
  public static NUMBER_NOT_ZERO = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static FEE_PATTERN = new RegExp(['^\\d+(\.\\d{1,2})?$'].join(''));
  public static ALPHA_NUMERIC = new RegExp(['^[A-Za-z0-9]'].join(''));
  public static ALPHA_NUMERIC_SPACE = new RegExp(['^(?![0-9]*$)[A-Za-z0-9]'].join(''));
  public static ALPHA_NUMERIC_NO_SPACE = new RegExp('^[a-zA-Z0-9]*$');
  public static ALPHA_ONLY = new RegExp('^[a-zA-Z_ ]*$');
  public static VALUE_ONE_TO_HUNDRED = new RegExp(['^[1-9][0-9]?$|^100$'].join(''));
  public static NON_ZERO_VALUES = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static HOTEL_PROFILE_UPLOAD_ID = '';
  public static HOTEL_PROOFILE_UPLOAD_NAME = '';
  public static PERSON_NAME_PATTERN = '^[a-zA-Z][a-zA-Z\\s\-\_]+$';
  public static COMPANY_AND_PERSON_NAME_PATTERN = '^[a-zA-Z0-9][a-zA-Z0-9\\s\-\_]+$';
  public static FAX_PATTERN = /^\+?[0-9\-]+$/;
  public static TIME_ZONE_FIRST_STRING = /[(]/gi;
  public static TIME_ZONE_SECOND_STRING = /[)]/gi;
  public static NO_WHITESPACE = '^[A-Z0-9]*$';
  public static PHONE_VALIDATION =
    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})?$/i;
  public static ZIP_CODE_US = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  public static NAME_PATTERN_WITH_SYMBOL = new RegExp(/^([a-zA-Z0-9-]+\s?)*$/);
  public static PATIENT_NAME_PATTERN = '^[a-zA-Z0-9]?[a-zA-Z0-9\\s\-\_]+$';

  public static MRN_FIELD_VALIDATION = new RegExp('^[a-zA-Z0-9-]*$');

  public static MESSAGE_TYPES = { SUCCESS: 'success', ERROR: 'error', WARNING: 'warning', INFO: 'info' };
  public static DAYS_COLLECTION = [
    { label: 'Sun', value: '1' },
    { label: 'Mon', value: '2' },
    { label: 'Tue', value: '3' },
    { label: 'Wed', value: '4' },
    { label: 'Thu', value: '5' },
    { label: 'Fri', value: '6' },
    { label: 'Sat', value: '7' }
  ];

  public static NOT_ALLOWED_KEYS = ['#', '/', '?', '&', '=', '%'];
  public static COUNTRY_CODE = [
    // { label: 'Please Select', value: '' },
    { label: '+1', value: '+1' },
    { label: '+7', value: '+7' },
    { label: '+20', value: '+20' },
    { label: '+27', value: '+27' },
    { label: '+30', value: '+30' },
    { label: '+31', value: '+31' },
    { label: '+32', value: '+32' },
    { label: '+33', value: '+33' },
    { label: '+34', value: '+34' },
    { label: '+36', value: '+36' },
    { label: '+39', value: '+39' },
    { label: '+40', value: '+40' },
    { label: '+41', value: '+41' },
    { label: '+43', value: '+43' },
    { label: '+44', value: '+44' },
    { label: '+45', value: '+45' },
    { label: '+46', value: '+46' },
    { label: '+47', value: '+47' },
    { label: '+48', value: '+48' },
    { label: '+49', value: '+49' },
    { label: '+51', value: '+51' },
    { label: '+52', value: '+52' },
    { label: '+53', value: '+53' },
    { label: '+54', value: '+54' },
    { label: '+55', value: '+55' },
    { label: '+56', value: '+56' },
    { label: '+57', value: '+57' },
    { label: '+58', value: '+58' },
    { label: '+60', value: '+60' },
    { label: '+61', value: '+61' },
    { label: '+62', value: '+62' },
    { label: '+63', value: '+63' },
    { label: '+64', value: '+64' },
    { label: '+65', value: '+65' },
    { label: '+66', value: '+66' },
    { label: '+81', value: '+81' },
    { label: '+82', value: '+82' },
    { label: '+84', value: '+84' },
    { label: '+86', value: '+86' },
    { label: '+90', value: '+90' },
    { label: '+91', value: '+91' },
    { label: '+92', value: '+92' },
    { label: '+93', value: '+93' },
    { label: '+94', value: '+94' },
    { label: '+95', value: '+95' },
    { label: '+98', value: '+98' },
    { label: '+212', value: '+212' },
    { label: '+213', value: '+213' },
    { label: '+216', value: '+216' },
    { label: '+218', value: '+218' },
    { label: '+220', value: '+220' },
    { label: '+221', value: '+221' },
    { label: '+222', value: '+222' },
    { label: '+223', value: '+223' },
    { label: '+224', value: '+224' },
    { label: '+225', value: '+225' },
    { label: '+226', value: '+226' },
    { label: '+227', value: '+227' },
    { label: '+228', value: '+228' },
    { label: '+229', value: '+229' },
    { label: '+230', value: '+230' },
    { label: '+231', value: '+231' },
    { label: '+232', value: '+232' },
    { label: '+233', value: '+233' },
    { label: '+234', value: '+234' },
    { label: '+235', value: '+235' },
    { label: '+236', value: '+236' },
    { label: '+237', value: '+237' },
    { label: '+238', value: '+238' },
    { label: '+239', value: '+239' },
    { label: '+240', value: '+240' },
    { label: '+241', value: '+241' },
    { label: '+242', value: '+242' },
    { label: '+243', value: '+243' },
    { label: '+244', value: '+244' },
    { label: '+245', value: '+245' },
    { label: '+248', value: '+248' },
    { label: '+249', value: '+249' },
    { label: '+250', value: '+250' },
    { label: '+251', value: '+251' },
    { label: '+252', value: '+252' },
    { label: '+253', value: '+253' },
    { label: '+254', value: '+254' },
    { label: '+255', value: '+255' },
    { label: '+256', value: '+256' },
    { label: '+257', value: '+257' },
    { label: '+258', value: '+258' },
    { label: '+260', value: '+260' },
    { label: '+261', value: '+261' },
    { label: '+262', value: '+262' },
    { label: '+263', value: '+263' },
    { label: '+264', value: '+264' },
    { label: '+265', value: '+265' },
    { label: '+266', value: '+266' },
    { label: '+267', value: '+267' },
    { label: '+268', value: '+268' },
    { label: '+269', value: '+269' },
    { label: '+290', value: '+290' },
    { label: '+291', value: '+291' },
    { label: '+297', value: '+297' },
    { label: '+298', value: '+298' },
    { label: '+299', value: '+299' },
    { label: '+350', value: '+350' },
    { label: '+351', value: '+351' },
    { label: '+352', value: '+352' },
    { label: '+353', value: '+353' },
    { label: '+355', value: '+355' },
    { label: '+356', value: '+356' },
    { label: '+357', value: '+357' },
    { label: '+358', value: '+358' },
    { label: '+359', value: '+359' },
    { label: '+370', value: '+370' },
    { label: '+371', value: '+371' },
    { label: '+372', value: '+372' },
    { label: '+373', value: '+373' },
    { label: '+374', value: '+374' },
    { label: '+375', value: '+375' },
    { label: '+376', value: '+376' },
    { label: '+377', value: '+377' },
    { label: '+378', value: '+378' },
    { label: '+380', value: '+380' },
    { label: '+381', value: '+381' },
    { label: '+382', value: '+382' },
    { label: '+385', value: '+385' },
    { label: '+386', value: '+386' },
    { label: '+387', value: '+387' },
    { label: '+389', value: '+389' },
    { label: '+420', value: '+420' },
    { label: '+421', value: '+421' },
    { label: '+423', value: '+423' },
    { label: '+500', value: '+500' },
    { label: '+501', value: '+501' },
    { label: '+502', value: '+502' },
    { label: '+503', value: '+503' },
    { label: '+504', value: '+504' },
    { label: '+505', value: '+505' },
    { label: '+506', value: '+506' },
    { label: '+507', value: '+507' },
    { label: '+508', value: '+508' },
    { label: '+509', value: '+509' },
    { label: '+590', value: '+590' },
    { label: '+591', value: '+591' },
    { label: '+592', value: '+592' },
    { label: '+593', value: '+593' },
    { label: '+595', value: '+595' },
    { label: '+597', value: '+597' },
    { label: '+598', value: '+598' },
    { label: '+670', value: '+670' },
    { label: '+672', value: '+672' },
    { label: '+673', value: '+673' },
    { label: '+674', value: '+674' },
    { label: '+675', value: '+675' },
    { label: '+676', value: '+676' },
    { label: '+677', value: '+677' },
    { label: '+678', value: '+678' },
    { label: '+679', value: '+679' },
    { label: '+680', value: '+680' },
    { label: '+681', value: '+681' },
    { label: '+682', value: '+682' },
    { label: '+683', value: '+683' },
    { label: '+685', value: '+685' },
    { label: '+686', value: '+686' },
    { label: '+687', value: '+687' },
    { label: '+688', value: '+688' },
    { label: '+689', value: '+689' },
    { label: '+690', value: '+690' },
    { label: '+691', value: '+691' },
    { label: '+692', value: '+692' },
    { label: '+850', value: '+850' },
    { label: '+852', value: '+852' },
    { label: '+853', value: '+853' },
    { label: '+855', value: '+855' },
    { label: '+856', value: '+856' },
    { label: '+870', value: '+870' },
    { label: '+880', value: '+880' },
    { label: '+886', value: '+886' },
    { label: '+960', value: '+960' },
    { label: '+961', value: '+961' },
    { label: '+962', value: '+962' },
    { label: '+963', value: '+963' },
    { label: '+964', value: '+964' },
    { label: '+965', value: '+965' },
    { label: '+966', value: '+966' },
    { label: '+967', value: '+967' },
    { label: '+968', value: '+968' },
    { label: '+971', value: '+971' },
    { label: '+972', value: '+972' },
    { label: '+973', value: '+973' },
    { label: '+974', value: '+974' },
    { label: '+975', value: '+975' },
    { label: '+976', value: '+976' },
    { label: '+977', value: '+977' },
    { label: '+992', value: '+992' },
    { label: '+993', value: '+993' },
    { label: '+994', value: '+994' },
    { label: '+995', value: '+995' },
    { label: '+996', value: '+996' },
    { label: '+998', value: '+998' }
  ];

  public static CARS_ICONS = [
    { class: 'car-icon-0', value: '' },
    { class: 'car-icon-1', value: '1' },
    { class: 'car-icon-2', value: '2' },
    { class: 'car-icon-3', value: '3' },
    { class: 'car-icon-4', value: '4' },
    { class: 'car-icon-5', value: '5' },
    { class: 'car-icon-6', value: '6' },
    { class: 'car-icon-7', value: '7' },
    { class: 'car-icon-8', value: '8' },
    { class: 'car-icon-9', value: '9' },
    { class: 'car-icon-10', value: '10' },
    { class: 'car-icon-11', value: '11' },
    { class: 'car-icon-12', value: '12' },
    { class: 'car-icon-13', value: '13' },
    { class: 'car-icon-14', value: '14' },
    { class: 'car-icon-15', value: '15' },
    { class: 'car-icon-16', value: '16' },
    { class: 'car-icon-17', value: '17' },
    { class: 'car-icon-18', value: '18' },
    { class: 'car-icon-19', value: '19' },
    { class: 'car-icon-20', value: '20' },
    { class: 'car-icon-21', value: '21' },
    { class: 'car-icon-22', value: '22' },
    { class: 'car-icon-23', value: '23' },
    { class: 'car-icon-24', value: '24' }
  ];

  public static LOGGED_IN_ROLE = '';
  public static ADMINROLEID = 'Tenant Admin';
  public static DISPATCHERROLEID = 'Dispatcher Admin';
  public static CORPORATEROLEID = 'Corporate Admin';
  public static HOTELBOOKINGROLECODE = 'Hotel Booking';
  public static CORPORATEBOOKINGROLECODE = 'Corporate Booking';
  public static EMPLOYEE_ID = '';
  public static DEPARTMENT_ID = '';

  // Role ID's
  public static SUPER_ADMIN_ROLE_ID = '2';
  public static ADMIN_ROLE_ID = '9';
  public static STAFF_ROLE_ID = '8';
  public static ONSITE_PROVIDER_ROLE_ID = '4';
  public static REMOTE_PROVIDER_ROLE_ID = '6';
  public static HYBRID_PROVIDER_ROLE_ID = '10';
  public static CLIENT_STAFF_ROLE_ID = '11';
  public static PATIENT_ROLE_ID = '3';

  public static SALES_QUOTATION_TRIP_STATUS = [
    {
      label: 'Select',
      value: ''
    }, {
      label: 'New Request',
      value: 'NEW_REQUEST'
    }, {
      label: 'Quote Sent',
      value: 'QUOTE_SENT'
    }, {
      label: 'Quote Rejected',
      value: 'QUOTE_REJECTED'
    }, {
      label: 'Quote Accepted',
      value: 'QUOTE_ACCEPTED'
    }, {
      label: 'Cancelled',
      value: 'CANCELED'
    }
  ];

  public static VEHICLE_STATUS = [
    { label: 'All', value: '' },
    { label: 'Available', value: 'Available' },
    { label: 'Busy', value: 'Busy' }
  ];

  public static DRIVER_AVAILABILTY = [
    {
      label: 'Please Select',
      value: ''
    },
    {
      label: 'Available',
      value: 'Available'
    },
    {
      label: 'Busy',
      value: 'Busy'
    },
    {
      label: 'Idel',
      value: 'Off Duty'
    }
  ];

  public static MEMBER_GENDER = [{
    label: 'Please Select',
    value: ''
  }, {
    label: 'M',
    value: 'M'
  }, {
    label: 'F',
    value: 'F'
  }];

  public static GENDER = [{
    label: 'Please Select',
    value: ''
  }, {
    label: 'Male',
    value: '1'
  }, {
    label: 'Female',
    value: '2'
  }];

  public static MESSAGE_TYPE = [
    {
      value: 'EMAIL',
      label: 'Email'
    },
    // {
    //   value: 'SMS',
    //   label: 'SMS',
    // },
    {
      value: 'PN',
      label: 'Push Notification',
    }
  ];

  public static PROMO_CODE_MODE = [
    {
      label: 'Please Select',
      value: ''
    },
    {
      label: 'Amount',
      value: 'AMOUNT'
    },
    {
      label: 'Percentage',
      value: 'PERCENT'
    }
  ];

  public static PROMO_CODE_USAGE_TYPE = [
    {
      label: 'Please Select',
      value: ''
    },
    {
      label: 'All',
      value: 'ALL'
    },
    {
      label: 'Individual',
      value: 'INDIVIDUAL'
    }
  ];

  public static PROMO_USAGE_TYPE = [
    {
      label: 'Please Select',
      value: ''
    },
    {
      label: 'Limited',
      value: 'Limited'
    },
    {
      label: 'Unlimited',
      value: 'Unlimited'
    }
  ];

  public static DOCEXP = [{
    label: 'Please Select',
    value: ''
  }, {
    label: '7 Days',
    value: true
  }];

  public static STATUS = [{
    label: 'All',
    value: ''
  }, {
    label: 'Active',
    value: true
  }, {
    label: 'Deactivated',
    value: false
  }];

  public static DATA_SOURCE_STATUS = [{
    label: 'All',
    value: ''
  }, {
    label: 'Active',
    value: true
  }, {
    label: 'Deactive',
    value: false
  }];

  public static ACTIVE_DEACTIVE_STATUS = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Deactive', value: 'deactive' }
  ];

  public static RENTAL_SERVICE_TYPE = [
    'Rental with driver with fuel',
    'Rental without driver without fuel',
    'Rental with driver without fuel',
    'Rental without driver with fuel',
    'Chauffeur'
  ];

  public static BOOOKING_QUOTATION_ACCEPTED = [30];
  public static ASSIGN_BOOKING_STATUS_ARRAY = [10];
  public static REASSIGN_DRIVER_ARRAY = [40];
  public static DRIVER_IS_ARRIVED = [90];
  public static REASSIGN_BOOKING_STATUS_ARRAY = [80, 40, 41];
  public static COMPLETE_BOOKING_STATUS_ARRAY = [130, 120, 170, 50];
  public static EDIT_TRIP_STATUS = [10, 40, 41, 80, 90, 130, 140];
  public static NOT_EDIT_FULL_TRIP_STATUS = [41, 80, 90, 130, 140];

  public static BOOKING_DRIVER_ASSIGNED_STATUS = [20, 90, 70, 80, 40, 130, 120, 170, 50, 140, 150, 160, 100];
  public static Max_Rating = ['1', '2', '3', '4', '5'];
  public static DRIVER_ON_THE_WAY = [80];
  public static SECURITY_CHECK = [100];
  public static END_TRIP = [110];
  public static CAR_RETURN_CHECK = [140];
  public static RETURN_SECURITY_CHECK = [150];
  public static ON_TRIP = [130];
  public static ENDED_TRIP = [140];
  public static REASSIGN_DRIVER_STATUS = [90, 40, 41];

  public static BOOKING_DETAILS_STATUS_DISABLE_ARRAY = [
    120, 170, 50, 30
  ];

  public static BOOKING_STATUS = {
    END_TRIP: 140,
  };

  public static TRANSMISSION_TYPE = [
    {
      label: 'Please select',
      value: ''
    },
    {
      label: 'Both (Automatic/Manual)',
      value: 'BOTH'
    },
    {
      label: 'Automatic',
      value: 'AUTO'
    },
    {
      label: 'Manual',
      value: 'MANL'
    }
  ];

  public static RIDE_TYPE_OPTIONS = [
    {
      label: 'All',
      value: ''
    },
    {
      label: 'Ride Now',
      value: '1'
    },
    {
      label: 'Ride Later',
      value: '2'
    },
    {
      label: 'Ride Share',
      value: '3'
    }
  ];

  public static RIDE_TYPE_WITHOUT_RIDE_SHARE_OPTIONS = [
    {
      label: 'All',
      value: ''
    },
    {
      label: 'Ride Now',
      value: '1'
    },
    {
      label: 'Ride Later',
      value: '2'
    }
  ];

  public static RECURRENCE_UNIT = [
    { label: 'DAY', value: '0' },
    { label: 'WEEK', value: '1' }
  ];

  public static FILTER_LIST = [
    { label: 'Please Select', value: '' },
    { label: 'Last Hour', value: '1' },
    { label: 'Last 4 Hours', value: '4' },
    { label: 'Current Day', value: '24' },
    { label: 'Last 7 Days', value: '168' },
    { label: 'Last 15 Days', value: '360' },
    { label: 'Last 30 Days', value: '720' },
    { label: 'Custom Date', value: 'CUSTOM_DATE' },
  ];

  public static WEEKS = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
  ];

  public static APPOINTMENT_TYPE = [
    { label: 'All', value: '' },
    { label: 'Remote', value: 1 },
    { label: 'Dual', value: 2 },
    // { label: 'Market', value: 3 },
    { label: 'At Home', value: 4 },
  ];

  public static APPOINTMENT_TYPE_VALUES = {
    REMOTE: 1,
    DUAL: 2,
    MARKET: 3
  };

  public static GENDER_VALUES = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Choose not to disclose', value: 3 }
  ];

  public static HOTEL_PRICING_LINK = 'pricing-packages-details';
  public static HOTEL_ROUTE_LINK = 'route-pricing-details';
  public static HOTEL_Profile_image = '/assets/images/temp/default_hotel.png';
  public static NOTIFICATION_INTERVAL = 60 * 1000; // 1 min

  public static MAP_OPTION = {
    center: {
      lat: 18.5971, lng: 73.7188
    },
    zoom: 11,
    zoomControl: false
  };

  public static COOKIE_EXPIRY = (1 * 24 * 60 * 60 * 1000);
  public static ONE_DAY_IN_MILISECOND = (24 * 60 * 60 * 1000);
  public static FILE_UPLOAD_MAX_SIZE_IN_BYTE = 20000000; // 20 MB
  public static FILE_UPLOAD_MAX_SIZE_IN_BYTE_FOR_MESSAGES = 20000000; // 20 MB
  public static SESSION_KEY_NAME = 'X-SESSION-KEY';
  public static DATE_TIME_FORMATTER = 'MMM DD, YYYY hh:mm A';
  public static DATE_FORMATTER = 'MMM DD, YYYY';
  public static TIME_FORMATTER = 'hh:mm A';

  public static PASSENGER_TYPES = [
    {
      label: 'Private',
      value: 'Private'
    }, {
      label: 'Corporate',
      value: 'corporate'
    }
  ];

  public static ONDUTY_OFFDUTY_OPTIONS = [{
    label: 'All',
    value: 'ALL'
  }, {
    label: 'ON DUTY',
    value: 'ONDUTY'
  }, {
    label: 'OFF DUTY',
    value: 'OFFDUTY'
  }];

  public static LOGIN_LOGOUT_OPTION = [{
    label: 'All',
    value: 'ALL'
  }, {
    label: 'Logged In',
    value: 'LOGGEDIN'
  }, {
    label: 'Logged Out',
    value: 'LOGGEDOUT'
  }];

  public static PASSENGER_TYPES_MANUAL_BOOKING = [
    { label: 'Please Select', value: '' },
    { label: 'Guest Users', value: 'guestPassenger' },
    { label: 'App Users', value: 'appPassenger' }
  ];

  public static DRIVER_TYPES_MANUAL_BOOKING = [
    { label: 'Please Select', value: '' },
    { label: 'Select Manually', value: 'selectDriver' },
    { label: 'Auto Dispatch', value: 'automatic' },
  ];

  public static REFERRAL_STATUS_PT_DETAILS = [
    { label: 'All', value: '' },
    { label: 'Accepted', value: 50 },
    { label: 'Rejected', value: 100 },
    // { label: 'Dispatched', value: 200 },
  ];

  public static REFERRAL_STATUS = [
    { label: 'All', value: '10,30,50,100' },
    { label: 'Pending', value: 10 },
    { label: 'Accepted', value: 50 },
    { label: 'Rejected', value: 100 },
    // { label: 'Dispatched', value: 200 },
    { label: 'Information Requested', value: 30 },
  ];

  public static IGNORE_LINK = ['edit-corporate', 'corporate-details', 'hotel-details', 'reports', 'settings'];
  public static twentyThreeFiftyNineLapse = 86399000;
  public static SCHEDULER_COLOURS = ['#00CC00', '#0000ff', '#800080', '#89a203', '#ff1493', '#89a203', '#008000',
    '#12999e', '#009999', '#1c1c39'];
  public static BAR_CHART_COLOURS = ['#FF6384', '#36A2EB', '#FFCE56', '#1E88E5', '#9CCC65'];
  public static PIE_CHART_COLOURS = ['#FF6384', '#36A2EB', '#FFCE56', '#7753FF', '#9CCC65'];

  public static SECURITY_CHECK_TYPE = {
    security: 'SECURITY_CHECK',
    delivery: 'DELIVERY_CHECK',
    carReturn: 'CAR_RETURN_CHECK',
    returnSecurity: 'RETURN_SECURITY_CHECK',
  };

  public static PAYMENT_MODE = [{
    label: 'Cash',
    value: 'CASH'
  }];
  public static PAYMENT_TYPES = 'payment_types';
  public static DEFAULT_MAP_ROUTE_COLOR = '#3b7bff';
  public static HEADER_NOT_REQUIRED = ['/signin', '/trackmylocation', '/'];

  public static TRUE = 'true';
  public static MAX = 'max';
  public static THREE = '3';
  public static TWO = '2';
  public static ONE = '1';
  public static DRIVING = 'DRIVING';
  public static COOPERATIVE = 'cooperative';
  public static ROADMAP = 'roadmap';
  public static SUCCESS = 'SUCCESS';
  public static PENDING = 'PENDING';
  public static CARD = 'CARD';
  public static CASH = 'CASH';
  public static RIDE_NOW = 'Ride Now';
  public static RIDE_LATER = 'Ride Later';
  public static TRIP_ENDED = 'Trip Ended';
  public static EXPIRED = 'Expired';
  public static CANCELLED = 'Cancelled';
  public static COMPLETED = 'COMPLETED';
  public static INPROGRESS = 'INPROGRESS';
  public static NEW_REQ = 'NEW_REQUEST';
  public static DIFF = 'diff';
  public static SAME = 'same';
  public static NEW_REQUEST = 'New Request';
  public static DRIVER_ASSIGNED = 'Provider Assigned';
  public static ACTIVATE = 'activate';
  public static ACTIVATED = 'activated';
  public static Activate = 'Activate';
  public static Deactivate = 'Deactivate';
  public static DEACTIVATE = 'inactivate';
  public static MILES = 'miles';
  public static SMS = 'SMS';
  public static PN = 'PN';
  public static EMAIL = 'EMAIL';
  public static PRIVATE = 'Private';
  public static AMOUNT = 'AMOUNT';
  public static LIMITED = 'Limited';
  public static UNLIMITED = 'Unlimited';
  public static limited = 'limited';
  public static unlimited = 'unlimited';
  public static NOT_APPLICABLE = 'NA';
  public static ACKNOWLEDGE = 'Acknowledge';
  public static STRAT = 'Start';
  public static END = 'End';
  public static ON_THE_WAY_DRIVER = 'Provider On The Way';
  public static DRIVER_ARRIVED = 'Provider Arrived';
  public static ALL = 'ALL';

  public static SET_PRO_PIC_LOC = 'setProPicLoc';
  public static SET_PRO_DROP_LOC = 'setProDropLoc';
  public static PICKUP_LOC = 'pickupLocation';
  public static DROP_LOC = 'dropLocation';
  public static PICKUP_LOCATION = 'Pickup Location';
  public static DROP_LOCATION = 'Drop Location';
  public static SET_RESI_PIC_LOC = 'setResiPicLoc';
  public static SET_RESI_DROP_LOC = 'setResiDropLoc';
  public static ACCESS_GROUPNAME = 'accessGroupName';
  public static HEADER = 'Header';
  public static CUSTOM_DATE_KEY = 'CUSTOM_DATE';

  // static entry for custome demo start
  public static MAP_AREA_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Pune', value: 'Pune' },
  ];
  // static entry for custome demo end

  // Messages :: START
  public static MICHAT_BASEURL = 'https://housecallsmichat.rainbow.health/api/';
  public static MESSAGES_TENANT_ID = '2';
  public static SUBSCRIBE_EVENT = 'subscribe';
  public static UNSUBSCRIBE_EVENT = 'unsubscribe';
  public static CREATE_GROUP = 'client-10000';
  public static GROUP_CREATED = 'client-10001';
  public static MESSAGES_POST = 'client-10050';
  public static MESSAGES_RECIEVED_BY_SERVER = 'client-10051';
  public static MESSAGES_RECIEVED_BY_CLIENT = 'client-10052';
  public static MESSAGES_RECIEVED_BY_CLIENT_SUCCESS = 'client-10053';
  public static MESSAGES_READ_BY_CLIENT = 'client-10054';
  public static MESSAGES_READ_BY_CLIENT_SUCCESS = 'client-10055';

  public static MESSAGES_TYPE_TEXT = 1;
  public static MESSAGES_TYPE_IMAGE_ATTACHMENT = 2;
  public static MESSAGES_TYPE_VIDEO_ATTACHMENT = 3;
  public static MESSAGES_TYPE_DOC_ATTACHMENT = 7;
  // Messages :: END
  public static YOUTUBELINK_PATTERN = new RegExp(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/);

  public static BASELINE = 'Baseline';
  public static PATIENT_VISITS = 'Patient Visits';
  public static LABS = 'Labs';
  public static DOCUMENTS = 'Documents';
  public static FEEDBACK_SURVEY = 'Feedback and Survey';
  public static SCREENING_ASSESSMENTS = 'Screenings & Assessments';
  public static REFERRALS = 'Referrals';
  public static REQUESTS = 'Requests';
  public static CARE_TEAMS = 'Care Team';
  public static PATIENT_NOTES = 'Patient Notes';
  public static CARE_TASK = 'Care-tasks';
  public static VITAL_SIGNS = 'Vital Signs';
  public static MEDICATIONS = 'Medications';

  public static INBOUND_REFERRALS = 'Inbound Referrals';
  public static OUTBOUND_REFERRALS = 'Outbound Referrals';
  public static DISPATCH_REQUESTS = 'Dispatch Requests';

  public static INTERNAL_CARE_TEAM = 'Internal Care Team';
  public static EXTERNAL_CARE_TEAM = 'External Care Team';
  public static INSURANCE_GRID = 'Insurance_Grid';
  public static PATIENT_GRID = 'patient_Grid';
  public static APPOINTMENT_ALERT_GRID = 'appointmentAlert_Grid';
  public static GRAPH_DATA_GRID = 'graphDataTable_Grid';
  public static OPEN_VISIT_GRID = 'openVisit_Grid';
  public static PROVIDERS_GRID = 'providers_Grid';
  public static APPOINTMENT_GRID = 'appointment_Grid';
  public static ONLINE_REFERRAL_GRID = 'onlineReferral_Grid';
  public static DISPATCH_REQUEST_GRID = 'dispatchRequest_Grid';
  public static EDU_MATERIAL_GRID = 'eduMaterial_Grid';
  public static EDU_MATERIAL_DETAILS_GRID = 'eduMaterialDetails_Grid';
  public static MANAGE_USER_GRID = 'manageUser_Grid';
  public static MASTER_VIEW_FORM_GRID = 'masterViewForm_Grid';
  public static MASTER_CARE_TASK_GRID = 'masterCareTask_Grid';
  public static MASTER_EXTERNAL_PROVIDER_GRID = 'masterExternalProvider_Grid';
  public static MASTER_REFERRAL_PARTNER_GRID = 'masterReferralPartner_Grid';
  public static MARKETING_NOTIFICATION_GRID = 'marketingNotification_Grid';
  public static MARKETING_SEGMENT_GRID = 'marketingSegment_Grid';
  public static APPTS_REPORT_GRID = 'apptsReport_Grid';
  public static REPORT_PT_VISIT_GRID = 'reportPtVisit_Grid';
  public static REPORT_PT_DEMOGRAPHIC_GRID = 'reportPtDemographic_Grid';
  public static MANAGE_USERS_GRID = 'manageUsers_Grid';
  public static MANAGE_ROLES_GRID = 'manageRoles_Grid';
  public static VITAL_SIGNS_GRID = 'vitalSigns_Grid';
  public static SCREENING_ASSESSMENT_GRID = 'screeningAssessment_Grid';
  public static REFERRAL_GRID = 'referral_Grid';
  public static REFERRAL_INBOUND_GRID = 'referralInbound_Grid';
  public static PATIENT_VISIT_GRID = 'patientVisit_Grid';
  public static PATIENT_DOCUMENT_GRID = 'patientDocument_Grid';
  public static PATIENT_FEEDBACK_SURVEY_GRID = 'patientFeedbackSurvey_Grid';
  public static INTERNAL_CARE_TEAM_GRID = 'internalCareTeam_Grid';
  public static EXTERNAL_CARE_TEAM_GRID = 'externalCareTeam_Grid';
  public static SUBJECTIVE_QUESTIONS_LIST_GRID = 'subjectiveQuestionList_Grid';
  public static MEDICATION_GRID = 'medication_Grid';
  public static MEDICATION_TAB_GRID = 'medication_tab_Grid';
  public static PROGRAM_HISTORY_GRID = 'program_history_Grid';
  public static MASTER_DATA_GRID = 'master_data_grid';
  public static PATIENT_NOTES_GRID = 'patient_notes_grid';
  public static MASTER_SOURCE_TYPE_GRID = 'master_suorce_type_grid';
  public static MASTER_SOURCE_TYPE_DATA_GRID = 'master_suorce_type_data_grid';
  public static VISIT_APPOINTMENT_MAPPING_GRID = 'visit_appointment_mapping_grid';
  public static QUALITY_REPORT_GRID = 'quality_report_Grid';
  public static MANAGE_MARKETS_GRID = 'manage_markets_Grid';


  // Shailesh app-settings
  public static EYE_OPENING_LIST = [{
    label: 'None',
    value: 1
  }, {
    label: 'To pressure',
    value: 2
  }, {
    label: 'To sound',
    value: 3
  }, {
    label: 'Spontaneous',
    value: 4
  }];

  public static VERBAL_RESPONSE_List = [{
    label: 'None',
    value: 1
  }, {
    label: 'Sounds',
    value: 2
  }, {
    label: 'Words',
    value: 3
  }, {
    label: 'Confused',
    value: 4
  }, {
    label: 'Oriented',
    value: 5
  }];

  public static MOTOR_RESPONSE_List = [{
    label: 'None',
    value: 1
  }, {
    label: 'Extension',
    value: 2
  }, {
    label: 'Abnormal flexion',
    value: 3
  }, {
    label: 'Normal flexion',
    value: 4
  }, {
    label: 'Localising',
    value: 5
  }, {
    label: 'Obey commands',
    value: 6
  }];

  public static PROVIDER_TYPE_OPTIONS = [{
    label: 'All',
    value: 'ALL'
  },
  {
    label: 'Onsite Provider',
    value: 'ONSITE_DRIVER'
  },
  {
    label: 'Remote Provider',
    value: 'REMOTE_DRIVER'
  }];

  public static TIMEZONE_OPTIONS = [
    {
      label: 'EST',
      value: 'America/New_York'
    },
    {
      label: 'CST',
      value: 'America/Chicago'
    },
    {
      label: 'PST',
      value: 'America/Los_Angeles'
    },
    {
      label: 'MST',
      value: 'America/Phoenix'
    }
  ];

  public static TIME_SLOTS = [
    { lable: '12:00 AM', value: '12:00 AM' },
    { lable: '1:00 AM', value: '1:00 AM' },
    { lable: '2:00 AM', value: '2:00 AM' },
    { lable: '3:00 AM', value: '3:00 AM' },
    { lable: '4:00 AM', value: '4:00 AM' },
    { lable: '5:00 AM', value: '5:00 AM' },
    { lable: '6:00 AM', value: '6:00 AM' },
    { lable: '7:00 AM', value: '7:00 AM' },
    { lable: '8:00 AM', value: '8:00 AM' },
    { lable: '9:00 AM', value: '9:00 AM' },
    { lable: '10:00 AM', value: '10:00 AM' },
    { lable: '11:00 AM', value: '11:00 AM' },
    { lable: '12:00 PM', value: '11:00 PM' },
    { lable: '1:00 PM', value: '1:00 PM' },
    { lable: '2:00 PM', value: '2:00 PM' },
    { lable: '3:00 PM', value: '3:00 PM' },
    { lable: '4:00 PM', value: '4:00 PM' },
    { lable: '5:00 PM', value: '5:00 PM' },
    { lable: '6:00 PM', value: '6:00 PM' },
    { lable: '7:00 PM', value: '7:00 PM' },
    { lable: '8:00 PM', value: '8:00 PM' },
    { lable: '9:00 PM', value: '9:00 PM' },
    { lable: '10:00 PM', value: '10:00 PM' },
    { lable: '11:00 PM', value: '11:00 PM' },
  ];

  public static APPOINTMENT_TYPE_OPTIONS = [{
    label: 'Remote',
    value: 1
  },
  {
    label: 'Dual',
    value: 2
  },
  {
    label: 'At Home',
    value: 4
  }];

  public static DURATION_LIST_OPTIONS = [{
    label: 'Please select',
    value: ''
  },
  {
    label: '15 min',
    value: '1'
  },
  {
    label: '30 min',
    value: '2'
  },
  {
    label: '45 min',
    value: '3'
  },
  {
    label: '60 min',
    value: '4'
  }];

  public static ASSESMENTS_LIST_OPTIONS = [{
    label: 'Full PPE (use for covid + screens)',
    value: '1',
    info: 'Full PPE (use for covid + screens)'
  },
  {
    label: 'Orthipedic bp',
    value: '2'
  },
  {
    label: 'Urine dip',
    value: '3'
  },
  {
    label: 'Kardia rhythm strip',
    value: '4',
    info: 'Kardia rhythm strip'
  },
  {
    label: 'Pulse ox ambulation',
    value: '5'
  },
  {
    label: 'Blood sugar/glucometer',
    value: '6'
  },
  {
    label: 'Hemoccult',
    value: '7'
  }];

  public static INTERVENTIONS_LIST_OPTIONS = [{
    label: 'Wound care bag',
    value: '11'
  },
  {
    label: 'Med box',
    value: '12',
    info: 'Kardia rhythm strip'
  },
  {
    label: 'Netbulizer',
    value: '13'
  },
  {
    label: 'Urine/catheter supplies',
    value: '14'
  },
  {
    label: 'Phebotomy supplies/VF supplies',
    value: '15'
  }];

  public static SOCIAL_LIST_OPTIONS = [{
    label: 'Caregiver stress (Z63.8)',
    value: '1',
    info: 'Caregiver stress (Z63.8)'
  },
  {
    label: 'Family stress (Z63.8)',
    value: '2'
  },
  {
    label: 'Insufficiant social',
    value: '3'
  },
  {
    label: 'Need assistance with community resources (Z62.5)',
    value: '4',
    info: 'Need assistance with community resources (Z62.5)'
  },
  {
    label: 'Unavailibity or inaccesibility of other helping agencies (275.4)',
    value: '5'
  },
  {
    label: 'Social isolation (Z62.4)',
    value: '6'
  },
  {
    label: 'Problems related to other legal sircumstances (Z62.5)',
    value: '7'
  }];

  public static ECONOMIC_LIST_OPTIONS = [{
    label: 'Financial problem (Z59.9)',
    value: '11',
    info: 'Financial problem (Z59.9)',
  },
  {
    label: 'Food insecurity (Z59.4)',
    value: '12'
  },
  {
    label: 'Homelessness (Z59.0)',
    value: '13',
    info: 'Homelessness (Z59.0)'
  },
  {
    label: 'Housing or economic circumtance (Z59.9)',
    value: '14',
    info: 'Housing or economic circumtance (Z59.9'
  },
  {
    label: 'Inadequate material resources (Z59.9)',
    value: '15'
  },
  {
    label: 'Intentioan underdosing of medication due to financial stressors (Z91.120)',
    value: '16'
  },
  {
    label: 'Unemployment (Z56.0)',
    value: '17'
  },
  {
    label: 'Low income (Z59.6)',
    value: '18'
  }];

  public static ENVIRONMENTAL_LIST_OPTIONS = [{
    label: 'Fall risk (Z91.81)',
    value: '21',
    info: 'Fall risk (Z91.81)',
  },
  {
    label: 'Stressful work schedule (Z56.3)',
    value: '22'
  },
  {
    label: 'Foster care status (Z62.21)',
    value: '23',
    info: 'Foster care status (Z62.21)'
  },
  {
    label: 'Problem related to social environment (Z62.9)',
    value: '24',
    info: 'Problem related to social environment (Z62.9'
  }];

  public static HEALTHEDUCATION_LIST_OPTIONS = [{
    label: 'Dental well-care counseling (Z71.89)',
    value: '31',
    info: 'Dental well-care counseling (Z71.89)',
  },
  {
    label: 'Referance to country mental health agency (Z68.81) E',
    value: '32'
  },
  {
    label: 'Illilteracy and low-level literacy (Z55.0)',
    value: '33',
    info: 'Illilteracy and low-level literacy (Z55.0)'
  },
  {
    label: 'Nutrition and exerice counseling (Z71.3)',
    value: '34',
    info: 'Nutrition and exerice counseling (Z71.3)'
  }];

  // Nirav app-settings
  public static MATERIAL_TYPE_LIST = [{
    label: 'Select',
    value: ''
  },
  {
    label: 'PDF',
    value: 'PDF'
  },
  {
    label: 'YouTube Link',
    value: 'LINK'
  }];

  public static ACCESS_TYPE_LIST = [{
    label: 'Select',
    value: ''
  },
  {
    label: 'Public',
    value: 'Public'
  },
  {
    label: 'Private',
    value: 'Private'
  }
  ];

  public static QUESTION_TYPE_LIST = [
    {
      label: 'Multiple choice',
      value: 1
    },
    {
      label: 'Checkbox',
      value: 2
    },
    {
      label: 'Switcher',
      value: 3
    }
  ];

  public static SOCIAL_LIST = [
    {
      label: 'Caregiver stress (Z63.8)',
      value: '1',
      info: 'Caregiver stress (Z63.8)'
    },
    {
      label: 'Family stress (Z63.8)',
      value: '2'
    },
    {
      label: 'Insufficiant social',
      value: '3'
    },
    {
      label: 'Need assistance with community resources (Z62.5)',
      value: '4',
      info: 'Need assistance with community resources (Z62.5)'
    }];

  public static MIN_SCALE_LIST = [{
    label: 'Select',
    value: ''
  },
  {
    label: '0',
    value: '0'
  },
  {
    label: '1',
    value: '1'
  }];

  public static MAX_SCALE_LIST = [{
    label: 'Select',
    value: ''
  },
  {
    label: '10',
    value: '10'
  },
  {
    label: '20',
    value: '20'
  }];

  public static STATUS_LIST = [{
    label: 'All',
    value: ''
  },
  {
    label: 'Active',
    value: true
  },
  {
    label: 'Inactive',
    value: false
  }];

  public static DECEASED_STATUS_LIST = [{
    label: 'All',
    value: ''
  },
  {
    label: 'Deceased',
    value: true
  },
  {
    label: 'Non-deceased',
    value: false
  }];

  public static VISIT_TYPE_ID = 'visitTypeId';


  public static DATA_SOURCE_DROPDOWN_COLOR = [
    { label: 'NA', value: 'NA', colorCode: '#EEE' },
    { label: 'Red', value: 'Red', colorCode: '#F9CCC9' },
    { label: 'Green', value: 'Green', colorCode: '#E2FACB' },
    // Add more colors as needed
  ];

  public static DATA_SOURCE_COLOR = {
    NA: '#EEE',
    Red: '#F9CCC9',
    Green: '#E2FACB'
  };

}
