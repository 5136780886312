import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { MessageService } from 'primeng/api';
import { UiService } from '../../services/ui.service';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html'
})

export class AssignDriverComponent implements OnInit, OnChanges {
  @Input() bookingID;
  @Output() isPopSave: EventEmitter<any> = new EventEmitter();
  @Output() isPopCancel: EventEmitter<any> = new EventEmitter();
  @Input() vehicleTypeId;

  packageApiError: any = [{
    pickupLocationName: false,
    cancelReason: false
  }];
  activated: Boolean = true;
  drivers: any[] = [];
  tourID: any;
  selectedDrivers: any = [];
  filteredDrivers: any[];
  bookingCancelform = new FormGroup({
    driverName: new FormControl()
  });
  labelSave;
  labelAssign;
  labelCancel;
  label_Cancelation;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private uiService: UiService,
    private commonBindingDataService: CommonBindingDataService) {
  }

  ngOnInit() {
    this.bookingCancelform = this.formBuilder.group({
      driverName: ['', [Validators.required]]
    });

    this.labelAssign = this.commonBindingDataService.getLabel('label_assign');
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
    this.label_Cancelation = this.commonBindingDataService.getLabel('label_Cancelation');
  }

  ngOnChanges() {
    if (this.vehicleTypeId || this.bookingID) {
      this.getDriverList();
    }
  }

  getDriverList() {
    this.commonBindingDataService.getDrivers(this.vehicleTypeId, this.bookingID,
      this.bookingCancelform.controls.driverName.value).subscribe(
        (result) => {
          this.drivers = result.map(elem => ({ name: elem.name + ' ,' + elem.phoneNumber + ' , ' + elem.etaWithUnit, id: elem.userId }));
        }, (error) => {
        });
  }

  search(event) {
    const query = event.query;
    this.filteredDrivers = this.filterDriver(query, this.drivers);

  }

  filterDriver(query, drivers: any[]): any[] {
    const filtered: any[] = [];
    for (let i = 0; i < drivers.length; i++) {
      if (drivers[i].name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push({ driverName: drivers[i].name, id: drivers[i].id });
      }
    }
    return filtered;
  }

  assignDriver() {
    const driverName = this.bookingCancelform.controls.driverName.value.driverName;
    const driverId = this.bookingCancelform.controls.driverName.value.id;
    this.commonBindingDataService.driverAssigned(this.bookingID, driverId).subscribe(results => {
      this.successSave('success', results);
      this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      this.bookingCancelform.reset();
    }, (error) => {
      this.successSave('error', error);
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  cancelBooking() {
    const data = {
      cancellationReason: this.bookingCancelform.controls.cancelReason.value
    };
    this.commonBindingDataService.cancelBooking(this.bookingID, data).subscribe(results => {
      this.messageService.add({
        severity: 'success', summary: this.commonBindingDataService.getLabel('label_service_message'),
        detail: this.commonBindingDataService.getLabel('label_via_messageService')
      });
      this.successSave('success', results);
      this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
      this.bookingCancelform.reset();
    }, (error) => {
      this.successSave('error', error);
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  successSave(messsage, results) {
    setTimeout(() => {
      this.isPopSave.emit(results);
      this.isPopCancel.emit();
    }, 3000);
  }

  reset() {
    this.bookingCancelform.reset();
  }

  cancel() {
    this.isPopCancel.emit();
  }

}
