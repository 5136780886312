<div class="form-preview-container" *ngIf="!submitted && formCategories">

  <div class="ui-g-12 ui-g-nopad pt-20 text-center">
    <img class="company-logo-background" src='/assets/images/temp/rainbowcare_logo.png' width="140px">
  </div>
  <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad" *ngFor="let sectionObj of formCategories;let sectionIndex = index">
    <p-panel>
      <p-header class="panel-header">
        <p>{{sectionObj.categoryName}}</p>
      </p-header>

      <!-- multiple choice format with other option -->
      <!-- <div class="ui-g">
                <div class="form-field ui-g-12 ui-md-12 ui-lg-12 pl-0">
                    <div class="form-label">
                        <label class="question-txt">Q1. What is your gender?</label>
                    </div>
                </div>
                <div class="form-field ui-g-12 ui-md-6 ui-lg-6 pl-0">       
                    <div class="form-control-c">
                        <div class="p-grid">
                            <div class="p-col-12 radio-label"><p-radioButton name="gender" label="MALE"></p-radioButton></div>
                            <div class="p-col-12 radio-label"><p-radioButton name="gender" label="FEMALE"></p-radioButton></div>
                            <div class="p-col-12 radio-label"><p-radioButton name="gender" label="TRANSGENDER"></p-radioButton></div>
                            <div class="p-col-12 radio-label"><p-radioButton class="padding-0" name="gender"></p-radioButton>
                                <label class="margin-top-0"><input  type="text" class="forminput"  pInputText /></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     -->
      <!-- multiple choice format with other option -->
    </p-panel>

    <div class="pt-20" *ngFor="let questionObj of sectionObj.formCategorySubcategories;let questionIndex = index">
      <div class="ui-g preview-heading-card"
        *ngFor="let subQuestionObj of questionObj.subCategoryQuestions;let subQuestionIndex = index">
        <!-- <div class="form-field ui-g-12 ui-md-12 ui-lg-12 pl-0">
          <div class="form-label">
            <label class="question-txt">{{ subQuestionIndex + 1}}. {{ subQuestionObj.question }}</label>
          </div>
        </div> -->

        <!-- multiple choice format -->
        <div [ngClass]="subQuestionObj.questionType == '1001' ? 'form-field ui-g-12 ui-md-12 ui-lg-12 pl-0 pb-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1001'">
            <div class="p-grid">
              <div class="p-col-12 radio-label margin-label"
                *ngFor="let QuestionOptions of subQuestionObj.subCategoryQuestionOptions;let QuestionOptionsIndex = index">

                <p-radioButton class="padding-0" name="mcq{{subQuestionObj.id}}" [value]="true"
                  [label]="QuestionOptions.otherOptionAdded !== true ? QuestionOptions.option : ''"
                  [(ngModel)]="QuestionOptions.selectedOption" [ngModelOptions]="{standalone: true}" binary="true"
                  (onClick)="radioChange(QuestionOptions,subQuestionObj)" [disabled]="viewFormFlag">
                </p-radioButton>
                <div class="form-field ui-g-12 ui-md-12 ui-lg-12 pl-0 otherinputAlign"
                  *ngIf="QuestionOptions.otherOptionAdded == true">
                  <input autocomplete="off" type="text" class="otherinput ml-6" [(ngModel)]="QuestionOptions.option"
                    [disabled]="viewFormFlag" pInputText />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- multiple choice format -->

        <!-- multiple choice grid format -->
        <div [ngClass]="subQuestionObj.questionType == '1002' ? 'form-field ui-g-12 ui-md-12 ui-lg-12 pl-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1002'">
            <table class="grid-table">
              <tr>
                <th></th>
                <th
                  *ngFor="let columnQuestionOptions of subQuestionObj.subCategoryQuestionOptions;let columnQuestionOptionsIndex = index">
                  {{ columnQuestionOptions.option }}</th>

              </tr>

              <tr
                *ngFor="let rowQuestionOptions of subQuestionObj.associateQuestions;let rowQuestionOptionsIndex = index">
                <td class="row-name">{{ rowQuestionOptions.question }}</td>
                <td
                  *ngFor="let columnRadioQuestionOptions of subQuestionObj.subCategoryQuestionOptions;let columnRadioQuestionOptionsIndex = index">
                  <p-radioButton
                    name="category{{ sectionIndex + 1 }}{{ subQuestionIndex + 1}}{{rowQuestionOptionsIndex}}">
                  </p-radioButton>
                </td>
              </tr>

            </table>

          </div>

        </div>
        <!-- multiple choice grid format -->

        <!-- short answer format -->
        <div [ngClass]="subQuestionObj.questionType == '1003' ? 'form-field ui-g-12 ui-md-4 ui-lg-4 pl-0 pb-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1003'">
            <div class="form-control-c"
              *ngFor="let QuestionOptions of subQuestionObj?.subCategoryQuestionOptions;let QuestionOptionsIndex = index">
              <input autocomplete="off" type="text" class="forminput" [(ngModel)]="QuestionOptions.option"
                [ngModelOptions]="{standalone: true}" binary="true" pInputText [disabled]="viewFormFlag"
                placeholder="Enter {{subQuestionObj.question}}" />
            </div>
          </div>
        </div>
        <!-- short answer format -->

        <!-- paragraph format -->
        <div [ngClass]="subQuestionObj.questionType == '1004' ? 'form-field ui-g-12 ui-md-6 ui-lg-6 pl-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1004'">
            <textarea class="no-resize forminput" autocomplete="off" [rows]="5" [cols]="25" pInputTextarea></textarea>
          </div>
        </div>
        <!-- paragraph format -->

        <!-- Linear Scale Format -->
        <div [ngClass]="subQuestionObj.questionType == '1005' ? 'form-field ui-g-12 ui-md-8 ui-lg-8 pl-0' : '' ">
          <div *ngIf="subQuestionObj.questionType == '1005'">
            <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
              <div class="form-field ui-g-12 ui-md-1 ui-lg-1 linear-center">
                <p>{{ subQuestionObj.subCategoryQuestionOptions[0].option }}</p>
                <!-- <p>{{ subQuestionObj.subCategoryQuestionOptions[0].title }}</p> -->
              </div>
              <div class="form-control-c ui-g-12 ui-md-10 ui-lg-10 mt-8">
                <p class="linear-center"><b>Value:{{subQuestionObj.sliderValue}}</b></p>
                <input autocomplete="off" type="range" value="0" [(ngModel)]="subQuestionObj.sliderValue"
                  [min]="subQuestionObj.subCategoryQuestionOptions[0].option"
                  [max]="subQuestionObj.subCategoryQuestionOptions[1].option" id="Range{{subQuestionIndex}}">
              </div>
              <div class="form-field ui-g-12 ui-md-1 ui-lg-1 linear-center">
                <p>{{ subQuestionObj.subCategoryQuestionOptions[1].option }}</p>
                <!-- <p>{{ subQuestionObj.subCategoryQuestionOptions[1].title }}</p> -->
              </div>
            </div>
            <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
              <div class="form-field ui-g-12 ui-md-5 ui-lg-5 ui-g-nopad">
                <p>{{ subQuestionObj.subCategoryQuestionOptions[0].title }}</p>
              </div>
              <div class="form-field ui-g-12 ui-md-2 ui-lg-2">
              </div>
              <div class="form-field ui-g-12 ui-md-5 ui-lg-5 ui-g-nopad txt-align-right">
                <p>{{ subQuestionObj.subCategoryQuestionOptions[1].title }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Linear Scale Format -->

        <!-- Date format -->
        <div [ngClass]="subQuestionObj.questionType == '1006' ? 'form-field ui-g-12 ui-md-4 ui-lg-4 pl-0 pb-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1006'">
            <div class="form-control-c"
              *ngFor="let QuestionOptions of subQuestionObj?.subCategoryQuestionOptions;let QuestionOptionsIndex = index">
              <p-calendar [showIcon]="true" [(ngModel)]="QuestionOptions.option" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="cDateRange"
                [placeholder]="'label_select_date' | translate" [defaultDate]="today" [inline]="false"
                [style]="{'width':'100%'}" class="cus-date" [disabled]="true">
              </p-calendar>
            </div>
          </div>
        </div>
        <!-- Date format -->

        <!-- Switcher Format -->
        <div [ngClass]="subQuestionObj.questionType == '1007' ? 'form-field ui-g-12 ui-md-6 ui-lg-6 pl-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1007'">
            <span>{{ subQuestionObj.subCategoryQuestionOptions[0].option }}</span>
            <p-inputSwitch class="switch-space"></p-inputSwitch>
            <span>{{ subQuestionObj.subCategoryQuestionOptions[1].option }}</span>
          </div>
        </div>
        <!-- Switcher Format -->

        <!-- Checkbox format -->
        <div [ngClass]="subQuestionObj.questionType == '1008' ? 'form-field ui-g-12 ui-md-12 ui-lg-12 pl-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1008'">
            <div class="p-grid">
              <div class="p-col-12 radio-label"
                *ngFor="let checkboxQuestionOptions of subQuestionObj.subCategoryQuestionOptions;let checkboxQuestionOptionsIndex = index">
                <p-checkbox class="padding-0" name="checkboxgroup{{subQuestionIndex}}"
                  [label]="checkboxQuestionOptions.option"></p-checkbox>
              </div>
            </div>
          </div>
        </div>
        <!-- Checkbox format -->

        <!-- HTML format -->
        <div [ngClass]="subQuestionObj.questionType == '1009' ? 'form-field ui-g-12 ui-md-12 ui-lg-12 pl-0 pb-0' : '' ">
          <div class="form-control-c" *ngIf="subQuestionObj.questionType == '1009'"
            [innerHtml]="subQuestionObj?.subCategoryQuestionOptions[0]?.option">
            <!-- {{ subQuestionObj.subCategoryQuestionOptions[0].option }} -->
          </div>
        </div>
        <!-- HTML format -->

      </div>
    </div>
  </div>

  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-10">
      <div class="download-link" *ngIf="downloadFileId">
        <a (click)="downloadConsentTemplate()" class="cp">
          <img _ngcontent-vgi-c129="" src="/assets/images/temp/pdffile.png" class="uploaded-img">
          Download MIH Consent for Care form for manual submission</a>
       </div>
    </div>
    <div class="form-buttons ui-g-2">
      <button pButton class="save-btn" type="button" [label]="'label_submit' | translate" [disabled]="displayBtn" (click)="saveForm()"></button>
      <!-- <button class="cancel-btn" pButton type="button" [label]="'label_cancel' | translate" (click)="goBack()"></button> -->
    </div>
  </div>
</div>

<div class="form-preview-container" *ngIf="submitted">
  <div class="ui-g-12 ui-md-12 ui-lg-12" style="margin-top: 8%;">
    <div class="card">
      <div class="card-container">
        <div class="submitted-lable">
          <h3>{{submittedMsg}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-preview-container" *ngIf="alreadtSubmitted">
  <div class="ui-g-12 ui-md-12 ui-lg-12" style="margin-top: 8%;">
    <div class="card">
      <div class="card-container">
        <div class="submitted-lable">
          <h3>{{alreadtSubmittedMsg}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
