<div class="email-verification signin-box-container">
  <div class="ui-g box-container">
    <div class="ui-g set-password-box">
      <div class="email-verified">
        <!-- <img src="assets/images/temp/email-verified.png">
        <p>{{verifyMessage}}</p> -->

        <div class="ui-g-12">
          <div style="text-align: center;"><img class="company-logo-background" src="/assets/images/temp/rainbowcare_logo.png"></div>
          <h2 class="popup-header" translate>label_set_password</h2>
        </div>

        <form [formGroup]="passwordForm">
          <div class="form-field ui-g-12">
            <div class="form-label">
              <label for="note" translate>label_new_password</label>
            </div>
            <div class="form-control-c password-field-with-eye">
              <input [type]="inputType" autocomplete="off" formControlName="newPassword" [(ngModel)]="newPassword" pInputText />
              <span class="eye-icon sign-in-eye" (click)="tooglePasswordType()">
                <i *ngIf="inputType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                <i *ngIf="inputType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
              </span>
            </div>
            <div class="form-errors"
              *ngIf="passwordForm.controls.newPassword.errors  && (passwordForm.controls.newPassword.dirty || passwordForm.controls.newPassword.touched)">
              <div [hidden]="!passwordForm.controls.newPassword.errors.required" translate>
                error_new_password_required
              </div>
              <div [hidden]="(!passwordForm.controls.newPassword.errors.minlength)
                 && (!passwordForm.controls.newPassword.errors.pattern)" translate>
                error_password_pattern
              </div>
              <div [hidden]="!passwordForm.controls.newPassword.errors.maxlength" translate>
                exceed_max_length
              </div>
            </div>
          </div>

          <div class="form-field ui-g-12">
            <div class="form-label">
              <label for="note" translate>label_new_confirm_password</label>
            </div>
            <div class="form-control-c password-field-with-eye">
              <input [type]="cnfinputType" autocomplete="off" formControlName="confirmPassword" [(ngModel)]="confirmPassword" pInputText />
              <span class="eye-icon sign-in-eye" (click)="toogleCnfPasswordType()">
                <i *ngIf="cnfinputType === 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                <i *ngIf="cnfinputType !== 'password'" class="fa fa-eye" aria-hidden="true"></i>
              </span>
            </div>
            <div class="form-errors"
              *ngIf="passwordForm.controls.confirmPassword.errors  && (passwordForm.controls.confirmPassword.dirty || passwordForm.controls.confirmPassword.touched)">
              <div [hidden]="!passwordForm.controls.confirmPassword.errors.required" translate>
                error_new_confirm_password_required
              </div>
              <div [hidden]="(!passwordForm.controls.confirmPassword.errors.minlength)
                 && (!passwordForm.controls.confirmPassword.errors.pattern)" translate>
                error_password_pattern
              </div>
              <div [hidden]="!passwordForm.controls.confirmPassword.errors.maxlength" translate>
                exceed_max_length
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="ui-g-12" style="text-align:center" *ngIf="!passwordSetSucessFully">
        <button pButton [label]="'label_set_password' | translate" (click)="sendOTP()"
          [disabled]="!passwordForm.valid || (confirmPassword !== newPassword)"></button>
      </div>
    </div>
  </div>

  <p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-popup otp-popup"
    position="center center" responsive="true" [(visible)]="setPasswordOtp" [closable]="false">
    <div class="ui-g-12 ui-g-nopad" style="text-align: center;">
      <div style="text-align: center; padding-top: 15px;margin-bottom: 10px;"><img class="company-logo-background" src="/assets/images/temp/rainbowcare_logo.png"></div>
      <div class="heater-title">Verify OTP</div>
    </div>

    <div class="ui-g-12">
      <p style="margin-bottom: 16px; font-weight: 400;" translate>label_sent_otp_msg</p>
    </div>
    <div class="ui-g-12">
      <input style="width: 100%;" autocomplete="off" placeholder="Enter OTP here..." type="text" [(ngModel)]="otpValue" pInputText />
    </div>
    <div class="form-buttons ui-g-12" style="margin-bottom: 15px;">
      <p [hidden]="otpCounterTimer == 0" style="margin: 15px 0;">
        OTP is valid for the next 5 minutes. In case you have not received an OTP, then please click Resend OTP button after {{otpCounterTimer}} seconds to re-generate a new OTP. Please do not share with anyone.
      </p>

      <button pButton type="button" [label]="'Verify' | translate" [disabled]="otpValue == ''"
        (click)="setPassword()"></button>

      <button pButton type="button" [label]="'Resend OTP' | translate" [disabled]="otpCounterTimer !== 0"
        (click)="sendOTP()"></button>

      <button class="negative-button" pButton type="button" [label]="'Cancel' | translate"
        (click)="closeWalletPopup()"></button>
    </div>
  </p-dialog>