import { Component, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { connect, ConnectOptions, createLocalTracks, createLocalVideoTrack, LocalTrack, LocalVideoTrack, Room } from 'twilio-video';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'app/modules/shared/services/notifications.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-twilio-video',
  templateUrl: './twilio-video.component.html',
  styleUrls: ['./twilio-video.component.scss']
})
export class TwilioVideoComponent implements OnInit, OnDestroy {

  connected = false;
  room;
  chat;
  conv;
  screenTrack;
  root: HTMLElement;
  usernameInput;
  button;
  shareScreen;
  toggleChat;
  container;
  count;
  chatScroll;
  chatContent;
  chatInput;
  isAudioMuted = true;
  isVideoMuted = true;
  muteUnmuteAudioButton: any;
  muteUnmuteVideoButton: any;
  roomName: any;
  accessToken: any;
  userInfo: any;
  roomSid: any;
  // private roomBroadcast = new ReplaySubject<boolean>();
  subscription: Subscription;
  incommingCall: any;
  callNotAnswered = false;
  callerName: string;
  callConnected = false;

  constructor(private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private messagingService: MessagingService,
    private pathLocation: Location, ) { }

  ngOnInit(): void {
    this.incommingCall = this.route.snapshot.queryParams['incommingCall'];
    if (this.incommingCall) {
      this.onIncommingCall();
    }
    // this.joinOrCreateRoom('my-room');
    this.addLocalVideo();
    this.root = document.getElementById('root');
    this.usernameInput = document.getElementById('username');
    this.button = document.getElementById('join_leave');
    this.shareScreen = document.getElementById('share_screen');
    this.toggleChat = document.getElementById('toggle_chat');
    this.container = document.getElementById('container');
    this.count = document.getElementById('count');
    this.chatScroll = document.getElementById('chat-scroll');
    this.chatContent = document.getElementById('chat-content');
    this.chatInput = document.getElementById('chat-input');
    this.muteUnmuteAudioButton = document.querySelector('#mute-unmute-audio-button');
    this.muteUnmuteVideoButton = document.querySelector('#mute-unmute-video-button');

    const token = JSON.parse(localStorage.getItem('twilioToken'));
    if (token !== null) {
      this.connectButtonHandler(event);
    }

    this.subscription = this.messagingService.getPushMessage().subscribe(msg => {
      //   console.log(msg);
      if (msg.notification.body.includes('is might be busy at the moment')) {
        this.connected = true;
        this.connectButtonHandler(event);
      }
    });
  }

  ngOnDestroy() {
    localStorage.setItem('twilioToken', null);
    this.checkIdleTime(AppSettings.IDLE_TIME);
  }

  checkIdleTime(time) {
    const idleLogOutTime = this.storageService.getItem('idleTime');
    const idleTime = '' + moment().add((time), 'm').valueOf();
    this.storageService.setLocalStorage(AppSettings.INTERVAL, idleTime);
  }

  addLocalVideo() {
    createLocalVideoTrack().then(track => {
      const video = document.getElementById('local').firstChild;
      const trackElement = track.attach();
      // trackElement.addEventListener('click', () => { this.zoomTrack(trackElement); });
      video.appendChild(trackElement);
      // trackElement.style.width = "100%";
    });
  }

  onIncommingCall() {
    // this.notificationsService.getDetails(10, 0).subscribe(notification => {
    //     if (notification.notificationList.length > 0) {
    //         console.log(notification.notificationList[0].extraParams);
    //         const extraParams = notification.notificationList[0].extraParams;
    //         const myArray = extraParams.split("~");
    //         const notification_type = myArray[0];
    //         const initatorID = myArray[1];
    //         const receivedID = myArray[2];
    //         this.roomName = myArray[4];
    //         const initiatorName = myArray[5];
    //         console.log(myArray);

    //         this.notificationsService.getTwilioToken(receivedID).subscribe(results => {
    //             console.log(results);
    //             this.accessToken = results.accessToken;
    //             this.connectButtonHandler(event);
    //         }, (error) => {
    //             // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    //         });
    //     } else {
    //     //   this.uiService.showMessage(this.noNotificationFoundLabel, AppSettings.MESSAGE_TYPES.ERROR);
    //     }
    // });
    this.userInfo = this.storageService.getItem(AppSettings.USER_DETAILS);
    const receivedID = this.userInfo.id;
    this.notificationsService.getTwilioToken(receivedID).subscribe(results => {
      console.log(results);
      this.accessToken = results.accessToken;
      this.roomName = results.roomName;
      this.roomSid = results.roomSid;
      // this.callerName = results.callParticipantsList[0].firstName + ' ' + results.callParticipantsList[0].lastName;
      results.callParticipantsList.forEach(ele => {
        if (ele.userId !== receivedID) {
          this.callerName = ele.firstName + ' ' + ele.lastName;
        }
      });
      this.connectButtonHandler(event);
    }, (error) => {
      // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  connectButtonHandler(event) {
    // event.preventDefault();
    if (!this.connected) {
      // let username = this.usernameInput.value;
      const username = 'test';
      if (!username) {
        alert('Enter your name before connecting');
        return;
      }
      // this.button.disabled = true;
      // this.button.innerHTML = 'Connecting...';
      const token = JSON.parse(localStorage.getItem('twilioToken'));
      console.log(token);
      if (token !== null) {
        this.roomName = token.roomName;
        this.accessToken = token.accessToken;
        this.roomSid = token.roomSid;
        this.callerName = token.providerName;
      }
      console.log(this.roomName);
      connect(this.accessToken, { name: this.roomName }).then(room => {
        this.checkIdleTime(60);
        this.room = room;
        this.connected = true;
        // this.button.innerHTML = 'Leave call';
        // this.button.disabled = false;
        console.log(`Successfully joined a Room: ${room}`);
        console.log(this.room);
        room.on('participantConnected', participant => {
          console.log(`A remote Participant connected: ${participant}`);
          if (this.room.participants.size === 1) {
            this.participantConnected(participant);
          }
          // this.room.participants.forEach(this.participantConnected);
          // this.room.on('participantConnected', this.participantConnected(participant));
          // this.room.on('participantDisconnected', this.participantDisconnected(participant));
        });

        room.on('participantDisconnected', participant => {
          console.log(`Participant disconnected: ${participant.identity}`);
          this.participantDisconnected(participant);
          // console.log(this.room.participants.size);
          if (this.room.participants.size === 0) {
            // setTimeout(() => {
            //     const data = {
            //         'roomSid' : this.roomSid,
            //         // "tourId" : "466750529218833112"
            //     };
            //     this.notificationsService.distroyTwilioRoom(data).subscribe(results => {
            //         // console.log(results);
            //         this.connectButtonHandler(event);
            //     }, (error) => {
            //         // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
            //     });
            // }, 1000);
            this.connectButtonHandler(event);
          }
        });

        // this.room.participants.forEach(this.participantConnected);
        // this.room.on('participantConnected', this.participantConnected);
        // this.room.on('participantDisconnected', this.participantDisconnected);
        // Log your Client's LocalParticipant   in the Room
        // const localParticipant = room.localParticipant;
        // console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);

        // Log any Participants already connected to the Room
        room.participants.forEach(participant => {
          console.log(`Participant "${participant.identity}" is connected to the Room`);
          this.participantConnected(participant);
        });

        setTimeout(() => {
          if (this.room.participants.size === 0) {
            const token = JSON.parse(localStorage.getItem('twilioToken'));
            const data = {
              'initiatorId': token?.initiatorId,
              'receiverId': token?.receiverId,
              'roomSid': this.roomSid,
              'rejectType': 'CALL_NOT_ANSWERED'
            };

            this.notificationsService.rejectTwilioCall(data).subscribe(results => {
              // console.log(results);
            }, (error) => {
              // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
            });

            this.callNotAnswered = true;

            const data1 = {
              'roomSid': this.roomSid,
              // "tourId" : "466750529218833112"
            };
            this.notificationsService.distroyTwilioRoom(data1).subscribe(results => {
              // console.log(results);
              this.connectButtonHandler(event);
            }, (error) => {
              // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
            });
          }
        }, 1000 * 3600);

        // this.button.innerHTML = 'Leave call';
        // this.button.disabled = false;
        // this.shareScreen.disabled = false;
        // this.updateParticipantCount();
      }).catch(() => {
        // alert('Connection failed. Is the backend running?');
        // this.button.innerHTML = 'Join call';
        // this.button.disabled = false;
      });
    } else {
      if (this.room.participants.size === 0 && !this.callNotAnswered) {
        const token = JSON.parse(localStorage.getItem('twilioToken'));
        const data = {
          'initiatorId': token?.initiatorId,
          'receiverId': token?.receiverId,
          'roomSid': this.roomSid,
          'rejectType': 'CALL_CANCELLED_BY_CALLER'
        };

        this.notificationsService.rejectTwilioCall(data).subscribe(results => {
          // console.log(results);
        }, (error) => {
          // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
        });
      }
      if (this.callConnected) {
        const data = {
          'roomSid': this.roomSid,
        };
        this.notificationsService.distroyTwilioRoom(data).subscribe(results => {
          // console.log(results);
        }, (error) => {
          // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
        });
      }
      // this.room.localParticipant.videoTracks.forEach(
      //     publication => publication.track.stop()
      // );
      localStorage.setItem('twilioVideo', JSON.stringify({ video: true }));
      this.disconnect();
      // this.button.innerHTML = 'Join call';
      this.connected = false;
      setTimeout(() => {
        if (this.incommingCall) {
          this.router.navigate(['appointments']);
        } else {
          this.pathLocation.back();
        }
      }, 1000);
      // this.shareScreen.innerHTML = 'Share screen';
      // this.shareScreen.disabled = true;
    }
  }

  connect(username) {
    // let promise = new Promise<void>((resolve, reject) => {
    //     // get a token from the back end
    //     let data;
    //     fetch('/login', {
    //         method: 'POST',
    //         body: JSON.stringify({'username': username})
    //     }).then(res => res.json()).then(_data => {
    //         // join video call
    //         data = _data;
    //         return connect(data.token);
    //     }).then(_room => {
    //         this.room = _room;
    // this.room.participants.forEach(this.participantConnected);
    // this.room.on('participantConnected', this.participantConnected);
    // this.room.on('participantDisconnected', this.participantDisconnected);
    //         this.connected = true;
    //         this.updateParticipantCount();
    //         // this.connectChat(data.token, data.conversation_sid);
    //         resolve();
    //     }).catch(e => {
    //         console.log(e);
    //         reject();
    //     });
    // });
    // return promise;
    const token = this.storageService.getItem('twilioToken').toString();
    console.log(token);
    connect(token, { name: 'my-new-room' }).then(room => {
      this.room = room;
      console.log(`Successfully joined a Room: ${room}`);
      room.on('participantConnected', participant => {
        console.log(`A remote Participant connected: ${participant}`);
      });

      // Log your Client's LocalParticipant in the Room
      const localParticipant = room.localParticipant;
      console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);

      // Log any Participants already connected to the Room
      room.participants.forEach(participant => {
        console.log(`Participant "${participant.identity}" is connected to the Room`);
      });

    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });

    // let promise = new Promise<void>((resolve, reject) => {
    //     const token = this.storageService.getItem('twilioToken').toString();
    //     connect(token).then(_room => {
    //       this.room = _room;
    //       this.room.participants.forEach(this.participantConnected);
    //       this.room.on('participantConnected', this.participantConnected);
    //       this.room.on('participantDisconnected', this.participantDisconnected);
    //       this.connected = true;
    //       this.updateParticipantCount();
    //       // this.connectChat(data.token, data.conversation_sid);
    //     });
    // });
    // return promise;
  }

  updateParticipantCount() {
    if (!this.connected) {
      this.count.innerHTML = 'Disconnected.';
    } else {
      this.count.innerHTML = (this.room.participants.size + 1) + ' participants online.';
    }
  }

  participantConnected(participant) {
    this.callConnected = true;
    console.log(this.callConnected);
    const participantDiv = document.createElement('div');
    participantDiv.setAttribute('id', participant.sid);
    participantDiv.setAttribute('class', 'participant');

    const tracksDiv = document.createElement('div');
    participantDiv.appendChild(tracksDiv);
    // tracksDiv.style.height = "480px";

    const labelDiv = document.createElement('div');
    // labelDiv.setAttribute('class', 'label');
    // labelDiv.setAttribute('id', 'participant-name');
    labelDiv.style.textAlign = 'center';
    labelDiv.style.background = '#ddd';
    labelDiv.style.color = '#000';
    labelDiv.style.padding = '2px';

    // labelDiv.innerHTML = participant.identity;
    labelDiv.innerHTML = this.callerName;
    participantDiv.appendChild(labelDiv);

    this.container.appendChild(participantDiv);

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        this.trackSubscribed(tracksDiv, publication.track);
      }
    });
    participant.on('trackSubscribed', track => this.trackSubscribed(tracksDiv, track));
    participant.on('trackUnsubscribed', track => this.trackUnsubscribed(track));

    // this.updateParticipantCount();
  }

  participantDisconnected(participant) {
    document.getElementById(participant.sid).remove();
    // this.updateParticipantCount();
  }

  trackSubscribed(div, track) {
    const trackElement = track.attach();
    // trackElement.addEventListener('click', () => { this.zoomTrack(trackElement); });
    div.appendChild(trackElement);
    // Participent height
    trackElement.style.height = '480px';
  }

  trackUnsubscribed(track) {
    track.detach().forEach(element => {
      if (element.classList.contains('participantZoomed')) {
        this.zoomTrack(element);
      }
      element.remove();
    });
  }

  disconnect() {
    this.room.disconnect();
    if (this.chat) {
      this.chat.shutdown().then(() => {
        this.conv = null;
        this.chat = null;
      });
    }
    while (this.container.lastChild.id !== 'local') {
      this.container.removeChild(this.container.lastChild);
    }
    // this.button.innerHTML = 'Join call';
    if (this.root.classList.contains('withChat')) {
      this.root.classList.remove('withChat');
    }
    // this.toggleChat.disabled = true;
    this.connected = false;
    // this.updateParticipantCount();
  }

  // shareScreenHandler() {
  //     event.preventDefault();
  //     if (!this.screenTrack) {
  //         navigator.mediaDevices.getDisplayMedia().then(stream => {
  //             this.screenTrack = new LocalVideoTrack(stream.getTracks()[0]);
  //             this.room.localParticipant.publishTrack(this.screenTrack);
  //             this.screenTrack.mediaStreamTrack.onended = () => { this.shareScreenHandler() };
  //             console.log(this.screenTrack);
  //             this.shareScreen.innerHTML = 'Stop sharing';
  //         }).catch(() => {
  //             alert('Could not share the screen.')
  //         });
  //     }
  //     else {
  //         this.room.localParticipant.unpublishTrack(this.screenTrack);
  //         this.screenTrack.stop();
  //         this.screenTrack = null;
  //         this.shareScreen.innerHTML = 'Share screen';
  //     }
  // };

  zoomTrack(trackElement) {
    if (!trackElement.classList.contains('trackZoomed')) {
      // zoom in
      this.container.childNodes.forEach(participant => {
        if (participant.classList && participant.classList.contains('participant')) {
          let zoomed = false;
          participant.childNodes[0].childNodes.forEach(track => {
            if (track === trackElement) {
              track.classList.add('trackZoomed');
              zoomed = true;
            }
          });
          if (zoomed) {
            participant.classList.add('participantZoomed');
          } else {
            participant.classList.add('participantHidden');
          }
        }
      });
    } else {
      // zoom out
      this.container.childNodes.forEach(participant => {
        if (participant.classList && participant.classList.contains('participant')) {
          participant.childNodes[0].childNodes.forEach(track => {
            if (track === trackElement) {
              track.classList.remove('trackZoomed');
            }
          });
          participant.classList.remove('participantZoomed');
          participant.classList.remove('participantHidden');
        }
      });
    }
  }

  // connectChat(token, conversationSid) {
  //     return Twilio.Conversations.Client.create(token).then(_chat => {
  //         this.chat = _chat;
  //         return this.chat.getConversationBySid(conversationSid).then((_conv) => {
  //             this.conv = _conv;
  //             this.conv.on('messageAdded', (message) => {
  //                 this.addMessageToChat(message.author, message.body);
  //             });
  //             return this.conv.getMessages().then((messages) => {
  //                 this.chatContent.innerHTML = '';
  //                 for (let i = 0; i < messages.items.length; i++) {
  //                     this.addMessageToChat(messages.items[i].author, messages.items[i].body);
  //                 }
  //                 this.toggleChat.disabled = false;
  //             });
  //         });
  //     }).catch(e => {
  //         console.log(e);
  //     });
  // };

  addMessageToChat(user, message) {
    this.chatContent.innerHTML += `<p><b>${user}</b>: ${message}`;
    this.chatScroll.scrollTop = this.chatScroll.scrollHeight;
  }

  toggleChatHandler() {
    event.preventDefault();
    if (this.root.classList.contains('withChat')) {
      this.root.classList.remove('withChat');
    } else {
      this.root.classList.add('withChat');
      this.chatScroll.scrollTop = this.chatScroll.scrollHeight;
    }
  }

  onChatInputKey(ev) {
    if (ev.keyCode === 13) {
      this.conv.sendMessage(this.chatInput.value);
      this.chatInput.value = '';
    }
  }

  // addLocalVideo();
  // button.addEventListener('click', connectButtonHandler);
  // shareScreen.addEventListener('click', shareScreenHandler);
  // toggleChat.addEventListener('click', toggleChatHandler);
  // chatInput.addEventListener('keyup', onChatInputKey);


  muteUnmuteAudio() {

    if (!this.room || !this.room.localParticipant) {
      throw new Error('You must be connected to a room to mute tracks.');
    }

    this.isAudioMuted = !this.isAudioMuted;

    if (!this.isAudioMuted) {
      this.room.localParticipant.audioTracks.forEach(
        publication => publication.track.disable()
      );
      this.muteUnmuteAudioButton.textContent = 'Unmute Audio';
    }

    if (this.isAudioMuted) {
      this.room.localParticipant.audioTracks.forEach(
        publication => publication.track.enable()
      );
      this.muteUnmuteAudioButton.textContent = 'Mute Audio';
    }
  }


  muteUnmuteVideo() {

    if (!this.room || !this.room.localParticipant) {
      throw new Error('You must be connected to a room to mute tracks.');
    }

    this.isVideoMuted = !this.isVideoMuted;

    if (!this.isVideoMuted) {
      this.room.localParticipant.videoTracks.forEach(
        publication => publication.track.disable()
      );
      this.muteUnmuteVideoButton.textContent = 'On Video';
    }

    if (this.isVideoMuted) {
      this.room.localParticipant.videoTracks.forEach(
        publication => publication.track.enable()
      );
      this.muteUnmuteVideoButton.textContent = 'Off Video';
    }
  }

}
